import { LabelType } from '../../components/taskboard/Taskboard.types';
import { removeEmojisFrom } from '../../shared/stringUtils';

export const sortLabelsAlphabetically = (labels: LabelType[]) => {
  labels.sort((a: LabelType, b: LabelType) => {
    const nameA = removeEmojisFrom(a.name);
    const nameB = removeEmojisFrom(b.name);
    return nameA.localeCompare(nameB);
  });
  return labels;
};
