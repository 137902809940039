import React from 'react';
import { ToggleButton } from 'react-bootstrap';
import BloomAvatar from '../bloomAvatar/BloomAvatar';
import { FilterOption, TaskboardFilterKeys } from './Filter.types';

type FilterOptionItemProps = {
  filterId: TaskboardFilterKeys;
  option: FilterOption;
  selected: boolean;
  onOptionClick: (_optionId: string, _filterId: TaskboardFilterKeys) => void;
};

const FilterOptionItem: React.FC<FilterOptionItemProps> = ({ filterId, option, selected, onOptionClick }) => (
  <ToggleButton
    onClick={() => onOptionClick(option.id, filterId)}
    checked={selected}
    value={`${filterId}-${option}`}
    type="checkbox"
    variant="light"
    className="d-flex bg-white flex-row justify-content-between rounded-2 py-2 px-3 my-1"
  >
    <span className="d-flex text-start">
      {option.img && (
        <BloomAvatar name={option.text} imagePath={option.img} size="xxs" rounded containerClassName="me-2" />
      )}
      {option.text}
    </span>
    <div>{selected && <i className="bi bi-check2" />}</div>
  </ToggleButton>
);

export default FilterOptionItem;
