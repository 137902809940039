import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { DDInstance } from './DDInstances.types';
import { PageSettingsType } from '../../App.types';
import { SelectOption } from '../../components/select/Select.types';

export type DDInstancesState = {
  ddInstances: Array<
    DDInstance & {
      submittedAt: Date;
      categoriesText: string;
    }
  >;
  pageSettings: {
    [workspaceId: string]: PageSettingsType & { selectedDD?: SelectOption | null };
  };
};

const initialState: DDInstancesState = {
  ddInstances: [],
  pageSettings: {},
};

const slice = createSlice({
  name: 'ddInstances',
  initialState,
  reducers: {
    setDDInstances(state: DDInstancesState, action: PayloadAction<{ ddInstances: DDInstancesState['ddInstances'] }>) {
      const clonedPageSettings = cloneDeep(state.pageSettings);
      return {
        ddInstances: action.payload.ddInstances,
        pageSettings: clonedPageSettings,
      };
    },
    updateSelectedCategories(
      state: DDInstancesState,
      action: PayloadAction<{
        instanceId: string;
        selectedCategories: DDInstance['selectedCategories'];
      }>
    ) {
      const clonedState = cloneDeep(state);
      const recordIndex = clonedState.ddInstances.findIndex((r) => r._id === action.payload.instanceId);
      if (recordIndex !== -1) {
        clonedState.ddInstances[recordIndex].selectedCategories = action.payload.selectedCategories;
        return clonedState;
      }
      return state;
    },
    resetDDInstances(state: DDInstancesState, _action: PayloadAction) {
      const clonedPageSettings = cloneDeep(state.pageSettings);

      return {
        ddInstances: [],
        pageSettings: clonedPageSettings,
      };
    },
    setSearchString(
      state: DDInstancesState,
      action: PayloadAction<{ workspaceId: string; searchString: PageSettingsType['searchString'] }>
    ) {
      const { workspaceId, searchString } = action.payload;

      const clonedState = cloneDeep(state);

      const workspaceSettings = clonedState.pageSettings[workspaceId];

      return {
        ddInstances: clonedState.ddInstances,
        pageSettings: {
          ...clonedState.pageSettings,
          [workspaceId]: {
            ...workspaceSettings,
            searchString,
          },
        },
      };
    },
    setSortBy(
      state: DDInstancesState,
      action: PayloadAction<{ workspaceId: string; sortBy: PageSettingsType['sortBy'] }>
    ) {
      const { workspaceId, sortBy } = action.payload;

      const clonedState = cloneDeep(state);

      const workspaceSettings = clonedState.pageSettings[workspaceId];

      return {
        ddInstances: clonedState.ddInstances,
        pageSettings: {
          ...clonedState.pageSettings,
          [workspaceId]: {
            ...workspaceSettings,
            sortBy,
          },
        },
      };
    },
    setSelectedDD(
      state: DDInstancesState,
      action: PayloadAction<{ workspaceId: string; selectedDD: SelectOption | null }>
    ) {
      const { workspaceId, selectedDD } = action.payload;

      const clonedState = cloneDeep(state);

      const workspaceSettings = clonedState.pageSettings[workspaceId];

      return {
        ddInstances: clonedState.ddInstances,
        pageSettings: {
          ...clonedState.pageSettings,
          [workspaceId]: {
            ...workspaceSettings,
            selectedDD,
          },
        },
      };
    },
  },
});

export const { setDDInstances, updateSelectedCategories, resetDDInstances, setSearchString, setSortBy, setSelectedDD } =
  slice.actions;

export default persistReducer({ storage, key: 'growth', whitelist: ['pageSettings'] }, slice.reducer);
