export default {
  labelsColors: [
    '#397FBB',
    '#3F51B5',
    '#2196F3',
    '#009688',
    '#FFB300',
    '#EF5350',
    '#7E57C2',
    '#FF6F00',
    '#FA6698',
    '#8BC34A',
    '#00ACC1',
    '#607D8B',
    '#795548',
  ],
};
