import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { breakPointOrderList, WindowSizeType } from './dimensions.types';

const useScreenSize = () => {
  const screenSize = useSelector<RootState, RootState['dimensions']['screenSize']>(
    ({ dimensions }) => dimensions.screenSize
  );

  const isScreenSmallerThanOrEqual = useCallback(
    (givenScreenSize: WindowSizeType) =>
      breakPointOrderList.indexOf(givenScreenSize) >= breakPointOrderList.indexOf(screenSize),
    [screenSize]
  );

  return {
    isScreenSmallerThanOrEqual,
  };
};

export default useScreenSize;
