import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../../../setup';
import constants from '../../../shared/consts';
import { HeaderUserMenu } from './headerMenus/HeaderUserMenu';
import OrgNotificationsMenu from './headerMenus/OrgNotificationsMenu';

const HeaderControls: React.FC = () => {
  const { header } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const user = useSelector<RootState, RootState['user']>((state) => state.user, shallowEqual);

  const intl = useIntl();

  const communityMessagesPath = useMemo(
    () => (user.discourseUser ? `u/${user.discourseUser}/messages` : ''),
    [user.discourseUser]
  );

  return (
    <div className="d-flex">
      {/* Community private messages Link */}
      <div className="d-flex align-items-center ms-3">
        {header.headerControls.messages.isVisible && (
          <Link to={`${constants.navTypes.COMMUNITY}#/${communityMessagesPath}`}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 200, hide: 200 }}
              overlay={(props) => <Tooltip {...props}>{intl.formatMessage({ id: 'Community Messages' })}</Tooltip>}
            >
              <FontAwesomeIcon size="xl" icon={faEnvelope} className="text-primary" />
            </OverlayTrigger>
          </Link>
        )}
      </div>

      <div className="d-flex align-items-center ms-3">
        {header.headerControls.orgNotificationsBell.isVisible && <OrgNotificationsMenu />}
      </div>

      {user._id && header.headerControls.userMenu.isVisible && (
        <div className="d-flex align-items-center ms-3">
          {header.headerControls.welcomeTitle.isVisible && (
            <div className="d-none d-sm-block me-2">
              {intl.formatMessage({ id: 'TOPBAR.HI' })} <b>{user.firstname}</b>
            </div>
          )}
          <HeaderUserMenu />
        </div>
      )}
    </div>
  );
};

export default HeaderControls;
