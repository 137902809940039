import { useDispatch } from 'react-redux';
import { RootState } from '../../../../setup';
import { TaskType, TaskboardFilterKeys } from '../Taskboard.types';
import taskboardService from '../Taskboard.service';
import {
  setTaskboardData,
  removeCard,
  editCard,
  addCardsToList,
  moveCardAcrossLists,
  resetTaskboard,
  setSearchString,
  setFilters,
  applyFilters,
  resetFilters,
  modifyCommentsCount,
} from '../taskboard.slice';

const useTaskboardDispatcher = () => {
  const dispatch = useDispatch();

  return {
    dispatchSetTaskboard: (taskboard: Partial<RootState['taskboard']>) => {
      dispatch(setTaskboardData(taskboard));
    },
    dispatchAddCards: (listId: string, cards: TaskType[]) => {
      dispatch(addCardsToList({ listId, cards }));
    },
    dispatchEditCard: (listId: string, card: TaskType) => {
      dispatch(editCard({ listId, card }));
    },
    dispatchRemoveCard: (listId: string, cardId: string) => {
      dispatch(removeCard({ listId, cardId }));
    },
    dispatchArchiveTask: (listId: string, cardId: string) => {
      dispatch(removeCard({ listId, cardId }));
      taskboardService.toggleArchiveTask(cardId);
    },
    dispatchMoveCardAcrossLists: (
      cardId: string,
      sourceListId: string,
      targetListId: string,
      position: number,
      updateBE: boolean = true,
      reviewerId: string | undefined = undefined
    ) => {
      dispatch(moveCardAcrossLists({ cardId, sourceListId, targetListId, position }));
      if (updateBE) {
        taskboardService.updateTaskList({
          taskId: cardId,
          destinationListId: targetListId,
          indexInDestinationList: position,
          reviewerId,
        });
      }
    },
    dispatchResetTaskboard: () => {
      dispatch(resetTaskboard());
    },
    dispatchSetFilters: (optionId: string, filterId: TaskboardFilterKeys) => {
      dispatch(setFilters({ optionId, filterId }));
    },
    dispatchSetSearchString: (searchString: string) => {
      dispatch(setSearchString({ searchString }));
    },
    dispatchApplyFilters: () => {
      dispatch(applyFilters());
    },
    dispatchResetFilters: () => {
      dispatch(resetFilters());
    },
    dispatchModifyCommentsCount: (listId: string, taskId: string, commentModification: TaskType['commentsCount']) => {
      dispatch(modifyCommentsCount({ listId, taskId, commentModification }));
    },
  };
};

export default useTaskboardDispatcher;
