import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

const useProgramNotificationsAttentionMessage = () => {
  const [showProgramNotificationsAlert, setShowProgramNotificationsAlert] = useState(false);

  const { workspaceUnreadNotificationCountsMap } = useSelector<RootState, RootState['notifications']>(
    ({ notifications }) => notifications
  );
  const { type: orgType, childOrgs } = useSelector<RootState, RootState['currentOrg']>(({ currentOrg }) => currentOrg);

  useEffect(() => {
    if (orgType && orgType === 'TEAM') {
      setShowProgramNotificationsAlert(
        childOrgs.some((childOrg) => childOrg.workspaces.some((ws) => workspaceUnreadNotificationCountsMap[ws] > 0))
      );
    }
  }, [childOrgs, orgType, workspaceUnreadNotificationCountsMap]);

  return {
    showProgramNotificationsAlert,
    setShowProgramNotificationsAlert,
  };
};

export default useProgramNotificationsAttentionMessage;
