import { combineReducers } from '@reduxjs/toolkit';

import userReducer from '../../app/modules/user/user.slice';
import taskboardReducer from '../../app/components/taskboard/taskboard.slice';
import commentsReducer from '../../app/components/commentList/comments.slice';
import ddInstancesReducer from '../../app/pages/Growth/DDInstances.slice';
import currentWorkspaceReducer from '../../app/modules/workspace/currentWorkspace.slice';
import currentOrgReducer from '../../app/modules/org/currentOrg.slice';
import notifications from '../../app/modules/notifications/notifications.slice';
import pubSubReducer from '../../app/modules/pubsub/pubSub.slice';
import ddSummaryReducer from '../../app/components/DDSummary/DDSummary.slice';
import dimensionsReducer from '../../app/modules/dimensions/dimensions.slice';
import layoutReducer from '../../app/layout/Layout.slice';
import libraryReducer from '../../app/pages/library/Library.slice';

const appReducers = {
  taskboard: taskboardReducer,
  ddInstances: ddInstancesReducer,
  entityComments: commentsReducer,
  user: userReducer,
  notifications,
  currentWorkspace: currentWorkspaceReducer,
  currentOrg: currentOrgReducer,
  pubSub: pubSubReducer,
  ddSummary: ddSummaryReducer,
  dimensions: dimensionsReducer,
  layout: layoutReducer,
  library: libraryReducer,
};

export const createRootReducer = () =>
  combineReducers({
    ...appReducers,
  });
