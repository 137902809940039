/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LabelsObject, LabelType } from '../../components/taskboard/Taskboard.types';
import { OrgMember, OrgModel, WorkspaceType } from '../org/org.types';
import { UserModel } from '../auth/models/UserModel';
import { ParentNotificationType } from '../notifications/notifications.types';

const getLabelsObjectFromLabels = (labels: LabelType[]) => {
  const labelsObject: { [key: string]: LabelType } = {};
  for (let i = 0; i < labels.length; i += 1) {
    labelsObject[labels[i]._id] = labels[i];
  }
  return labelsObject;
};

export type UsersMap = { [key: UserModel['_id']]: UserModel & { isActiveMember: boolean } };

interface CurrentWorkspace extends WorkspaceType {
  orgs: Array<OrgModel>;
  members: Array<OrgMember>;
  users: Array<UserModel>;
  usersMap: UsersMap;
  labels: Array<LabelType>;
  labelsObject: LabelsObject;
  parentUnreadNotifications: Array<Pick<ParentNotificationType, '_id' | 'createdForEntity'>>;
}

const initialState: CurrentWorkspace = {
  _id: '',
  ownerOrgs: [],
  sharedWithOrgs: [],
  title: '',
  computedTitle: '',
  description: '',
  orgs: [],
  members: [],
  usersMap: {},
  labels: [],
  labelsObject: {},
  users: [],
  latestTeamAction: {},
  todayTeamActionsCount: 0,
  unreadNotificationsCount: 0,
  parentUnreadNotifications: [],
};

const slice = createSlice({
  name: 'currentWorkspace',
  initialState,
  reducers: {
    setCurrentWorkspace(state: CurrentWorkspace, action: PayloadAction<{ currentWorkspace: CurrentWorkspace }>) {
      return {
        ...action.payload.currentWorkspace,
        labelsObject: getLabelsObjectFromLabels(action.payload.currentWorkspace?.labels!),
      };
    },
    markNotificationAsRead(state, action: PayloadAction<string>) {
      state.parentUnreadNotifications = state.parentUnreadNotifications.filter(
        (notification) => notification.createdForEntity.id !== action.payload
      );
    },
    setLabels(state: CurrentWorkspace, action: PayloadAction<{ labels: LabelType[] }>) {
      state.labels = action.payload.labels;
      state.labelsObject = getLabelsObjectFromLabels(state.labels);
    },
    addLabel(state: CurrentWorkspace, action: PayloadAction<{ label: LabelType }>) {
      state.labels.push(action.payload.label);
      state.labelsObject = getLabelsObjectFromLabels(state.labels);
    },
    editLabel(state: CurrentWorkspace, action: PayloadAction<{ label: LabelType }>) {
      const labelIndex = state.labels.findIndex((l) => l._id === action.payload.label._id);
      state.labels[labelIndex] = action.payload.label;
      state.labelsObject[action.payload.label._id] = action.payload.label;
    },
    deleteLabel(state: CurrentWorkspace, action: PayloadAction<{ labelId: string }>) {
      state.labels = state.labels.filter((l) => l._id !== action.payload.labelId);
      delete state.labelsObject[action.payload.labelId];
    },
  },
});

export const { setCurrentWorkspace, setLabels, addLabel, editLabel, deleteLabel, markNotificationAsRead } =
  slice.actions;

export default slice.reducer;
