import { useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export function useParamsFromPath<T>(path: string | string[]): T {
  const { pathname } = useLocation();
  const match = matchPath(pathname, { path });
  if (match) {
    return match?.params as T;
  }
  return {
    navType: 'taskboard',
  } as T;
}

type stepperOptions = { startIndex?: number; numberOfSteps: number };

export function useStepper({ startIndex = 1, numberOfSteps }: stepperOptions) {
  const [activeStep, setActiveStep] = useState(startIndex);

  const isFirstStep = activeStep === 1;
  const isLastStep = activeStep === numberOfSteps;

  const nextStep = () => !isLastStep && setActiveStep((prevState) => prevState + 1);
  const prevStep = () => !isFirstStep && setActiveStep((prevState) => prevState - 1);

  return {
    activeStep,
    nextStep,
    prevStep,
    isFirstStep,
    isLastStep,
  };
}

type paginationOptions = { numberOfPages: number; defaultPageSize?: number };

export function usePagination({ numberOfPages = 1, defaultPageSize = 10 }: paginationOptions) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const isFirstPage = page === 1;
  const isLastPage = page === numberOfPages;

  const nextPage = () => !isLastPage && setPage((prevState) => prevState + 1);
  const previousPage = () => !isFirstPage && setPage((prevState) => prevState - 1);

  const goFirstPage = () => setPage(1);
  const goLastPage = () => setPage(numberOfPages);

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    nextPage,
    previousPage,
    goFirstPage,
    goLastPage,
    isFirstPage,
    isLastPage,
  };
}
