import { useEffect, useState } from 'react';
import keyBy from 'lodash/keyBy';
import { OrgModel, OrgPermissions } from '../../modules/org/org.types';

const useOrgPermissions = (orgSubscription: OrgModel['subscription']) => {
  const [permissions, setPermissions] = useState<OrgPermissions>({
    canCreateWorkspaces: false,
    canCreatePrograms: false,
    canAddTaskAttachments: false,
    canDownloadData: false,
  });

  useEffect(() => {
    const subscriptionStatus = orgSubscription?.stripeSubscription?.status;
    const actions = keyBy(orgSubscription?.plan.features, 'action.shortCode');

    if (subscriptionStatus === 'active') {
      setPermissions({
        canCreateWorkspaces: 'create_workspace' in actions,
        canCreatePrograms: 'create_program' in actions,
        canAddTaskAttachments: 'add_task_attachments' in actions,
        canDownloadData: 'download_data' in actions,
      });
    } else {
      setPermissions({
        canCreateWorkspaces: false,
        canCreatePrograms: false,
        canAddTaskAttachments: false,
        canDownloadData: false,
      });
    }
  }, [orgSubscription]);

  return permissions;
};

export default useOrgPermissions;
