import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import uniq from 'lodash/uniq';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DeltaStatic } from 'quill';
import { MentionType } from './richTextEditor.type';

const UseMention = (usersAsMentions?: Array<MentionType>) => {
  if (!usersAsMentions) return {};
  return {
    modules: {
      mention: {
        allowedChars: /.+/,
        mentionDenotationChars: ['@'],
        source(searchTerm: string, renderList: (_mentions: MentionType[], _searchTerm: string) => void) {
          if (searchTerm.length === 0) {
            renderList(usersAsMentions, searchTerm);
          } else {
            const matches = usersAsMentions.filter((u) => u.value.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
            renderList(matches, searchTerm);
          }
        },
      },
    },
  };
};

export const getMentionIds = (editorContent: DeltaStatic) => {
  const mentions = editorContent.ops?.map((op) => op.insert?.mention?.id).filter((m: string) => Boolean(m));
  return uniq(mentions);
};

export default UseMention;
