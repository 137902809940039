import React from 'react';
import BloomAvatar from './BloomAvatar';
import { BloomAvatarProps } from './BloomAvatar.types';

type BloomAvatarGroupProps = {
  avatarList: BloomAvatarProps[];
};

const BloomAvatarGroup: React.FC<BloomAvatarGroupProps> = ({ avatarList }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (avatarList.length === 0) return <></>;

  if (avatarList.length === 1) return <BloomAvatar {...avatarList[0]} />;

  return (
    <div className="d-flex flex-row-reverse">
      <BloomAvatar {...avatarList[0]} containerClassName="avatar-group-item-1" />
      <BloomAvatar {...avatarList[1]} containerClassName="avatar-group-item-2" />
    </div>
  );
};

export default BloomAvatarGroup;
