import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { faCircleUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../IconButton';

type IProps = {
  orgId: string;
  title?: string;
  hideTitle?: boolean;
};

const UpgradeButton: React.FC<IProps> = ({ orgId, title, hideTitle = false }) => {
  const intl = useIntl();

  return (
    <Link to={`/teams/${orgId}/settings#billing`}>
      <IconButton
        icon={faCircleUp}
        iconPosition="right"
        title={title || intl.formatMessage({ id: 'Upgrade Now' })}
        variant="success"
        size="sm"
        className="text-white"
        style={{ minWidth: hideTitle ? 0 : 120 }}
      >
        {!hideTitle ? title || intl.formatMessage({ id: 'Upgrade Now' }) : ''}
      </IconButton>
    </Link>
  );
};

export default UpgradeButton;
