import { captureException } from '@sentry/react';
import React, { FC } from 'react';
import orgService from '../../modules/org/OrgService';
import { BloomSpace, CreateFormValues } from '../CreateModal/createModal.types';
import CreateModalWrapper from '../CreateModal/CreateModalWrapper';
import constants from '../../shared/consts';

type Props = Pick<React.ComponentProps<typeof CreateModalWrapper>, 'show' | 'onClose'> & {
  orgId: string;
};

const CreateOrgModal: FC<Props> = ({ orgId, show, onClose }) => {
  const TYPE = constants.orgTypes.PROGRAM as BloomSpace;

  const handleFormSubmit = async (programFields: CreateFormValues) => {
    const program = await orgService.createOrg({
      ...programFields,
      type: TYPE,
      parentOrgId: orgId,
    });

    try {
      if (programFields.img) {
        const orgWithImg = await orgService.updateOrgImg(program._id, programFields.img);
        return orgWithImg;
      }
    } catch (error) {
      captureException(error);
    }
    return program;
  };
  return (
    <CreateModalWrapper
      show={show}
      onClose={onClose}
      type={TYPE}
      imageAlt="Organization Avatar"
      onFormSubmit={handleFormSubmit}
    />
  );
};

export default CreateOrgModal;
