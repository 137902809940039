import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import KTSVG from '../../../components/KTSVG/KTSVG';

type Props = {
  pathKey: string;
  title: string;
  pathKeySuffix?: string;
  icon?: string;
  fontIcon?: string;
  hasArrow?: boolean;
  hasBullet?: boolean;
  name?: string;
  isActive?: boolean;
  isSubMenu?: boolean;
};

const MenuItem: React.FC<Props> = ({
  pathKey,
  pathKeySuffix = '',
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  name,
  isActive = false,
  isSubMenu = false,
}) => {
  return (
    <div data-name={name} className={isSubMenu ? 'menu-sub-item' : 'menu-item'}>
      <Link
        className={clsx('menu-link p-3 w-100', {
          active: isActive,
        })}
        to={pathKey + pathKeySuffix}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        )}

        {icon && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}

        {fontIcon && (
          <span className="menu-icon">
            <i className={clsx('bi fs-3', fontIcon)} />
          </span>
        )}

        <span className="menu-title w-max-content">{title}</span>

        {hasArrow && <span className="menu-arrow" />}
      </Link>
    </div>
  );
};

export default MenuItem;
