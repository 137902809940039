import React from 'react';
import Avatar from 'react-avatar';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import BloomAvatarService from './BloomAvatar.service';
import { BloomAvatarProps, avatarStatusPropsMap } from './BloomAvatar.types';

export const avatarSizeMap = {
  xxs: '20',
  xs: '28',
  sm: '40',
  md: '60',
  lg: '80',
  xl: '120',
  xxl: '160',
};

const BloomAvatar: React.FC<BloomAvatarProps> = ({
  name,
  tooltipTrigger,
  tooltipHeader,
  tooltipBody,
  avatarStatus,
  imagePath,
  imageUrl,
  size,
  rounded,
  color,
  textColor,
  containerClassName,
  className,
  shouldAbbreviateName = true,
  disableTooltip = false,
  badge,
  onClick,
}) => {
  const intl = useIntl();

  const imageLink = imagePath ? BloomAvatarService.getImageLink(imagePath) : imageUrl;
  const avatarText = shouldAbbreviateName ? { name } : { value: name };
  const clickable = Boolean(onClick);
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 400, hide: 200 }}
      trigger={tooltipTrigger}
      rootClose
      overlay={
        <Popover className="min-w-50px">
          <Popover.Header className="d-flex p-2 rounded-bottom border-0">
            {typeof tooltipHeader === 'object' ? (
              tooltipHeader
            ) : (
              <p className="m-0 font-weight-600-important">{tooltipHeader || name}</p>
            )}
            {avatarStatus && (
              <Badge bg={avatarStatusPropsMap[avatarStatus].badgeBgColor} text="dark" className="ms-2 align-self-start">
                {intl.formatMessage({ id: avatarStatusPropsMap[avatarStatus].intlId })}
              </Badge>
            )}
          </Popover.Header>
          {tooltipBody && <Popover.Body className="overflow-auto mh-300px border-top">{tooltipBody}</Popover.Body>}
        </Popover>
      }
      {...(disableTooltip ? { show: false } : {})}
    >
      <div
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && onClick && onClick()}
        className={`position-relative ${containerClassName || ''} ${clickable ? 'cursor-pointer' : 'cursor-default'} ${
          avatarStatus ? `rounded-circle border border-2 ${avatarStatusPropsMap[avatarStatus].borderColor}` : ''
        }`}
        style={{
          width: 'fit-content',
        }}
      >
        {Boolean(badge) && (
          <div className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fs-9">
            {badge}
          </div>
        )}
        <Avatar
          {...avatarText}
          round={rounded || '4px'}
          src={imageLink}
          size={avatarSizeMap[size]}
          color={color}
          fgColor={textColor}
          alt={name}
          className={`avatar m-0 pe-none ${avatarStatus ? 'inactive' : ''} ${className || ''}`}
        />
      </div>
    </OverlayTrigger>
  );
};

export default BloomAvatar;
