import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUnreadNotificationsCount } from '../notifications.slice';
import useNotificationsSubscriber from './useNotificationsSubscriber';
import notificationService from '../notifications.service';

const useNotificationsInitializer = (userId: string) => {
  const dispatch = useDispatch();

  useNotificationsSubscriber(userId);

  useEffect(() => {
    const controller = new AbortController();
    const load = async () => {
      const workspaceUnreadNotificationCountsMap = await notificationService.getWorkspaceNotificationCountsMap(
        controller.signal
      );
      dispatch(setUnreadNotificationsCount({ workspaceUnreadNotificationCountsMap }));
    };
    load();
    return () => controller.abort();
  }, [dispatch]);
};

export default useNotificationsInitializer;
