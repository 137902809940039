import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ButtonProps, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { shallowEqual, useSelector } from 'react-redux';
import CreateProgramDropdownItem from '../../../components/createProgramDropdownItem/CreateProgramDropdownItem';
import DeleteOrg from '../../../components/DeleteOrg';
import CreateWorkspaceDropdownItem from '../../../components/createWorkspaceDropdownItem/CreateWorkspaceDropdownItem';
import { OrgModel } from '../../../modules/org/org.types';
import useLayoutDispatcher from '../../hooks/useLayoutDispatcher';
import { RootState } from '../../../../setup';
import CreateWorkspace from '../../../components/createWorkspace/CreateWorkspace';
import CreateOrgModal from '../../../components/createOrgModal/CreateOrgModal';

type Props = {
  orgId: OrgModel['_id'];
  orgName: OrgModel['name'];
  isOrgOwner: boolean;
  canCreatePrograms?: boolean;
  canCreateWorkspaces?: boolean;

  variant?: ButtonProps['variant'];
  dataName?: string;
  className?: string;
};

const TeamsSettingsDropdownMenu: React.FC<Props> = ({
  orgId,
  orgName,
  isOrgOwner,
  canCreatePrograms = false,
  canCreateWorkspaces = false,
  variant = 'light',
  dataName,
  className,
}) => {
  const intl = useIntl();

  const { toggleSidebarState } = useLayoutDispatcher();

  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] = useState(false);
  const [showCreateOrgModal, setShowCreateOrgModal] = useState(false);

  const handleCloseCreateWorkspaceModal = () => setShowCreateWorkspaceModal(false);
  const handleShowCreateWorkspaceModal = () => setShowCreateWorkspaceModal(true);

  const handleCloseCreateOrgModal = () => setShowCreateOrgModal(false);
  const handleShowCreateOrgModal = () => setShowCreateOrgModal(true);

  const { sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const onMenuItemClick = () => {
    if (sidebar.isDrawer && sidebar.isSidebarOpen) toggleSidebarState();
  };

  return (
    <>
      <Dropdown
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        drop="up"
      >
        <Dropdown.Toggle data-name={dataName} size="sm" variant={variant} className={`${className || ''} p-3`}>
          <FontAwesomeIcon size="xl" icon={faEllipsis} className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <CreateWorkspaceDropdownItem
            onClick={handleShowCreateWorkspaceModal}
            orgId={orgId}
            canCreateWorkspaces={canCreateWorkspaces}
          />
          <CreateProgramDropdownItem
            onClick={handleShowCreateOrgModal}
            orgId={orgId}
            canCreatePrograms={canCreatePrograms}
          />
          <Dropdown.Item as={Link} to={`/teams/${orgId}/settings#members`} onClick={onMenuItemClick}>
            {intl.formatMessage({
              id: 'INVITE_MEMBERS',
            })}
          </Dropdown.Item>
          {isOrgOwner && <DeleteOrg renderAs="menu item" orgId={orgId} orgName={orgName} orgType="TEAM" />}
        </Dropdown.Menu>
      </Dropdown>
      {showCreateWorkspaceModal && (
        <CreateWorkspace show={showCreateWorkspaceModal} onClose={handleCloseCreateWorkspaceModal} orgId={orgId} />
      )}
      {showCreateOrgModal && (
        <CreateOrgModal show={showCreateOrgModal} onClose={handleCloseCreateOrgModal} orgId={orgId} />
      )}
    </>
  );
};

export default TeamsSettingsDropdownMenu;
