import React from 'react';
import { SingleValueProps, components } from 'react-select';
import BloomAvatar from '../bloomAvatar/BloomAvatar';
import { MemberOption } from '../assignee/Assignee';

const SingleValueWithAvatar = ({ children, ...props }: SingleValueProps<MemberOption>) => {
  const {
    data: { label, imageLink, user },
  } = props;

  return (
    <components.SingleValue {...props} className="d-flex align-items-center">
      <BloomAvatar
        name={label}
        size="xs"
        imagePath={imageLink}
        avatarStatus={user?.isClosed ? 'closed' : undefined}
        rounded
        containerClassName="me-1"
      />
      {children}
    </components.SingleValue>
  );
};

export default SingleValueWithAvatar;
