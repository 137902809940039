import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
import Routes from './routing/Routes';
import useLiveChatSupportPopup from './components/useLiveChatSupportPopup/useLiveChatSupportPopup';
import ProductTour from './components/productTour/ProductTour';
import { setWidth } from './modules/dimensions/dimensions.slice';
import Loading from './components/Loading/Loading';

ReactGA.pageview(window.location.pathname + window.location.search);

type Props = {
  basename: string;
};

const App: React.FC<Props> = () => {
  useLiveChatSupportPopup();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleWindowResize = () => {
      dispatch(setWidth(window.innerWidth));
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <ProductTour>
          <Routes />
        </ProductTour>
      </Router>
    </Suspense>
  );
};

export default App;
