import React, { useEffect } from 'react';
import clsx from 'clsx';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../setup';
import ScrollTop from './components/ScrollTop';
import Toolbar from './components/Toolbar/Toolbar';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Content from './components/Content';
import useScreenSize from '../modules/dimensions/useScreenSize';
import useLayoutDispatcher from './hooks/useLayoutDispatcher';

type IProps = {
  children: React.ReactNode;
};

const MasterLayout: React.FC<IProps> = ({ children }) => {
  const { header, sidebar, toolbar } = useSelector<RootState, RootState['layout']>(
    (state) => state.layout,
    shallowEqual
  );

  const { toggleSidebarState, setSidebarIsDrawer } = useLayoutDispatcher();

  const { isScreenSmallerThanOrEqual } = useScreenSize();

  const isSmallScreen = isScreenSmallerThanOrEqual('md');

  useEffect(() => {
    if (isSmallScreen) {
      if (sidebar.isSidebarOpen) toggleSidebarState();
      setSidebarIsDrawer(true);
    } else {
      if (!sidebar.isSidebarOpen) toggleSidebarState();
      setSidebarIsDrawer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen]);

  return (
    <div
      className={clsx(
        'mh-100 d-flex flex-row flex-column-fluid',
        {
          'sidebar-opened': sidebar.isVisible && !sidebar.isDrawer && sidebar.isSidebarOpen,
        },
        { 'sidebar-closed': sidebar.isVisible && !sidebar.isDrawer && !sidebar.isSidebarOpen },
        {
          'side-drawer-opened': sidebar.isVisible && sidebar.isDrawer && sidebar.isSidebarOpen,
        },
        { 'side-drawer-closed': sidebar.isVisible && sidebar.isDrawer && !sidebar.isSidebarOpen }
      )}
    >
      {sidebar.isVisible && <Sidebar />}
      {header.isVisible && <Header />}
      {toolbar.isVisible && <Toolbar />}
      <Content>{children}</Content>
      <ScrollTop />
    </div>
  );
};

export default MasterLayout;
