import { useEffect, useState } from 'react';
import notificationsService from '../notifications.service';
import { ChildNotificationType } from '../notifications.types';

type Props = {
  parentNotificationId: string;
  highlightedOnly?: boolean;
};

const useChildNotificationsLoader = ({ parentNotificationId, highlightedOnly }: Props) => {
  const [childNotifications, setChildNotifications] = useState<Array<ChildNotificationType>>([]);
  const [childNotificationsCount, setChildNotificationsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [generalError, setGeneralError] = useState('');

  const getNotifications = async () => {
    try {
      setIsLoading(true);
      const res = await notificationsService.getChildNotificationsByParentId(
        parentNotificationId,
        page,
        5,
        highlightedOnly
      );
      setChildNotifications((prevState) => [...prevState, ...res.records]);
      setChildNotificationsCount(res.totalRecordCount);
    } catch (error: any) {
      setGeneralError(error.message);
    }
    setIsLoading(false);
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (page > 0) {
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return {
    childNotifications,
    childNotificationsCount,
    loadMore,
    isLoading,
    generalError,
  };
};

export default useChildNotificationsLoader;
