import { FC } from 'react';
import clsx from 'clsx';
import KTSVG from '../../components/KTSVG/KTSVG';
import useLayoutDispatcher from '../hooks/useLayoutDispatcher';

type IProps = {
  isMenuOpen: boolean;
  className?: string;
};

const SidebarToggleButton: FC<IProps> = ({ isMenuOpen, className }) => {
  const { toggleSidebarState } = useLayoutDispatcher();

  return (
    <button
      type="button"
      onClick={toggleSidebarState}
      className={clsx(
        `btn btn-icon w-30px h-30px bg-light border-0 p-0 rotate btn-active-light-primary ${className}`,
        {
          active: isMenuOpen,
        },
        {
          className,
        }
      )}
      data-name="sidebar_mobile_toggle"
    >
      <KTSVG
        path="/media/icons/duotune/arrows/arr024.svg"
        className="svg-icon m-0 svg-icon-2x rotate-180 svg-icon-color-bloom-dark-blue"
      />
    </button>
  );
};

export default SidebarToggleButton;
