import React from 'react';
import { useIntl } from 'react-intl';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../IconButton';

type Props = {
  value: string;
  onChange: (_value: string) => void;
  debounce?: number;
  isClearable?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

const DebouncedInput: React.FC<Props> = ({
  value: initialValue,
  onChange,
  debounce = 500,
  isClearable = false,
  ...props
}) => {
  const [value, setValue] = React.useState(initialValue);

  const intl = useIntl();

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(String(value));
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="position-relative">
      <input value={value} onChange={(e) => setValue(e.target.value)} {...props} />
      {isClearable && value.length > 0 && (
        <IconButton
          size="sm"
          variant="light"
          className="position-absolute"
          style={{
            top: 7,
            right: 15,
          }}
          icon={faTimes}
          iconClassName="text-muted"
          title={intl.formatMessage({ id: 'Clear' })}
          onClick={() => setValue('')}
        />
      )}
    </div>
  );
};

export default DebouncedInput;
