/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import usePubSub from '../modules/pubsub/usePubSub';
import Layout from '../layout';
import PrivateRoutes from './PrivateRoutes';
import { RootState } from '../../setup';
import { useUserLoader } from '../modules/user/user.hooks';
import constants from '../shared/consts';
import EditorPage from '../pages/editor/EditorPage';

const Community = React.lazy(() => import('../components/community/community'));

const AuthPage = React.lazy(() => import('../modules/auth/AuthPage'));

const Logout = React.lazy(() => import('../modules/auth/Logout'));

const Routes: FC = () => {
  const { isLoggedIn: isAuthorized } = useSelector<RootState, RootState['user']>((state) => state.user);

  const isUserLoading = useUserLoader();

  usePubSub({ isAuthorized });

  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname, search]);

  if (isUserLoading) {
    return null;
  }

  return (
    <Switch>
      <Route path="/auth" component={AuthPage} />
      <Route path="/logout" component={Logout} />
      <Route
        path={constants.path.COMMUNITY}
        render={() => (
          <Layout>
            <Community />
          </Layout>
        )}
      />
      <Route
        path={constants.path.EDITOR}
        render={() => (
          <Layout>
            <EditorPage />
          </Layout>
        )}
      />
      {!isAuthorized ? (
        /* Redirect to `/auth` when user is not authorized */
        <Redirect
          to={{
            pathname: '/auth/login',
            state: { redirectTo: window.location.href },
          }}
        />
      ) : (
        <Layout>
          <PrivateRoutes />
        </Layout>
      )}
    </Switch>
  );
};

export default Routes;
