import { Quill } from 'react-quill';
import MagicUrl from 'quill-magic-url';

const useMagicUrl = () => {
  Quill.register('modules/magicUrl', MagicUrl, true);

  return {
    modules: {
      magicUrl: true,
    },
  };
};

export default useMagicUrl;
