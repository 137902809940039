import React, { useMemo, useRef, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { shallowEqual, useSelector } from 'react-redux';
import BloomAvatar from '../../components/bloomAvatar/BloomAvatar';
import { RootState } from '../../../setup';

type Props = {
  orgs: RootState['user']['orgs'];
  selectedOrg?: RootState['user']['orgs'][0];
  onSelect: (_org: RootState['user']['orgs'][0]) => void;
};

const TeamSelect: React.FC<Props> = ({ orgs, selectedOrg, onSelect }) => {
  const [openTeamSelectOverlay, setOpenTeamSelectOverlay] = useState(false);

  const { sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const workspaceUnreadNotificationCountsMap = useSelector<
    RootState,
    RootState['notifications']['workspaceUnreadNotificationCountsMap']
  >((state) => state.notifications.workspaceUnreadNotificationCountsMap);

  const teamSelectRef = useRef(null);

  const { orgsWithNotifications, allNotifications } = useMemo(() => {
    let allNotifications = 0;
    const orgsWithNotifications = orgs.map((o) => {
      const workspaceIds = [
        o.workspaces.map((w) => w._id),
        ...o.childOrgs.map((co) => co.workspaces as string[]),
      ].flat();
      const orgNotifications = workspaceIds.reduce(
        (acc, wId) => acc + (workspaceUnreadNotificationCountsMap[wId] || 0),
        0
      );
      allNotifications += orgNotifications;
      return {
        ...o,
        unreadNotificationsCount: orgNotifications > 99 ? '+99' : orgNotifications,
      };
    });

    return { orgsWithNotifications, allNotifications: allNotifications > 99 ? '+99' : allNotifications };
  }, [orgs, workspaceUnreadNotificationCountsMap]);

  return (
    <>
      <Button
        ref={teamSelectRef}
        onClick={() => setOpenTeamSelectOverlay((prevState) => !prevState)}
        variant="light"
        className={`ms-3 mt-1 p-2 smooth-hide ${sidebar.isSidebarOpen ? 'w-250px' : 'w-65px'}`}
      >
        <div className="d-flex align-items-center justify-content-center p-1">
          {Boolean(allNotifications) && (
            <div className="text-white h-25px w-30px p-2 rounded-pill bg-danger fs-9 me-2">{allNotifications}</div>
          )}
          <BloomAvatar
            imagePath={selectedOrg?.logo?.link}
            name={selectedOrg?.name || '?'}
            tooltipHeader="Select Team"
            size="xs"
          />
          <p
            className={`text-bloom-dark-blue fs-7 fw-bolder mb-0 text-truncate text-start w-200px smooth-hide ${
              sidebar.isSidebarOpen ? 'opacity-1 ms-4' : 'opacity-0 ms-0'
            }`}
          >
            {selectedOrg ? selectedOrg?.name : 'Select Team'}
          </p>
          <FontAwesomeIcon size="lg" icon={faChevronDown} className="text-muted ms-1" />
        </div>
      </Button>
      <Overlay
        target={teamSelectRef.current}
        placement="bottom"
        show={openTeamSelectOverlay}
        rootClose
        onHide={() => setOpenTeamSelectOverlay(false)}
      >
        <Popover>
          <Popover.Body className="d-flex flex-column p-2 mh-300px overflow-auto">
            {orgsWithNotifications.map((org) => (
              <Button
                key={org._id}
                variant={org._id === selectedOrg?._id ? 'light' : 'white'}
                className="flex-grow-1 py-1 px-2 my-1"
                onClick={() => {
                  onSelect(org);
                  setOpenTeamSelectOverlay(false);
                }}
              >
                <div className="d-flex align-items-center w-200px">
                  <BloomAvatar
                    badge={org.unreadNotificationsCount}
                    imagePath={org.logo?.link}
                    name={org.name}
                    size="xs"
                  />
                  <p className="text-bloom-dark-blue fs-7 fw-bolder ms-5 mb-0 text-start">{org.name}</p>
                </div>
              </Button>
            ))}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default TeamSelect;
