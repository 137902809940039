import { FC, ReactNode } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';

type IProps = {
  children: ReactNode;
};

const Content: FC<IProps> = ({ children }) => {
  const { header, toolbar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  let contentPaddingTop = '';

  if (header.isVisible && toolbar.isVisible) {
    contentPaddingTop = 'content-padding-top';
  } else if (header.isVisible) {
    contentPaddingTop = 'content-padding-top-header';
  }

  return (
    <div className="mh-100 d-flex flex-column flex-row-fluid">
      <div className={`page-content ${contentPaddingTop} mh-100 d-flex flex-column flex-column-fluid px-0`}>
        {children}
      </div>
    </div>
  );
};

export default Content;
