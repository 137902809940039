import { useEffect } from 'react';
import { ToolbarActionButtons, IHeader, ToolbarMenuItems } from '../Layout.types';
import useLayoutDispatcher from './useLayoutDispatcher';
import { initialLayoutState } from '../Layout.slice';

type Props = {
  headerIsVisible?: boolean;
  pageTitleIsLoading?: boolean;
  pageTitleIsVisible?: boolean;
  headerAvatars?: IHeader['pageTitle']['avatars'];
  pageTitle?: string;
  userMenuIsVisible?: boolean;
  welcomeTitleIsVisible?: boolean;
  notificationsBellIsVisible?: boolean;
  messagesIsVisible?: boolean;
  toolbarIsVisible?: boolean;
  toolbarMenuItems?: ToolbarMenuItems;
  toolbarActionButtons?: ToolbarActionButtons;
  sidebarIsVisible?: boolean;
};
const useLayout = ({
  headerIsVisible,
  pageTitleIsLoading,
  pageTitleIsVisible,
  headerAvatars,
  pageTitle,
  userMenuIsVisible,
  welcomeTitleIsVisible,
  notificationsBellIsVisible,
  messagesIsVisible,
  toolbarIsVisible,
  toolbarMenuItems,
  toolbarActionButtons,
  sidebarIsVisible,
}: Props) => {
  const {
    setHeaderVisibility,
    setHeaderTitleVisibility,
    setHeaderTitleLoading,
    setHeaderTitle,
    setHeaderAvatars,
    setUserMenuVisibility,
    setWelcomeTitleVisibility,
    setOrgNotificationsBellVisibility,
    setMessagesVisibility,
    setToolBarVisibility,
    setToolBarMenuItems,
    setToolBarActions,
    setSidebarVisibility,
  } = useLayoutDispatcher();

  useEffect(() => {
    if (headerIsVisible !== undefined) setHeaderVisibility(headerIsVisible);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerIsVisible]);

  useEffect(() => {
    if (pageTitleIsVisible !== undefined) setHeaderTitleVisibility(pageTitleIsVisible);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitleIsVisible]);

  useEffect(() => {
    if (pageTitleIsLoading !== undefined) setHeaderTitleLoading(pageTitleIsLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitleIsLoading]);

  useEffect(() => {
    if (pageTitle !== undefined) setHeaderTitle(pageTitle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle]);

  useEffect(() => {
    if (headerAvatars !== undefined) setHeaderAvatars(headerAvatars);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerAvatars]);

  useEffect(() => {
    if (userMenuIsVisible !== undefined) setUserMenuVisibility(userMenuIsVisible);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMenuIsVisible]);

  useEffect(() => {
    if (welcomeTitleIsVisible !== undefined) setWelcomeTitleVisibility(welcomeTitleIsVisible);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeTitleIsVisible]);

  useEffect(() => {
    if (notificationsBellIsVisible !== undefined) setOrgNotificationsBellVisibility(notificationsBellIsVisible);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsBellIsVisible]);

  useEffect(() => {
    if (messagesIsVisible !== undefined) setMessagesVisibility(messagesIsVisible);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesIsVisible]);

  useEffect(() => {
    if (toolbarIsVisible !== undefined) setToolBarVisibility(toolbarIsVisible);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarIsVisible]);

  useEffect(() => {
    if (toolbarMenuItems !== undefined) setToolBarMenuItems(toolbarMenuItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarMenuItems]);

  useEffect(() => {
    if (toolbarActionButtons !== undefined) setToolBarActions(toolbarActionButtons);

    return () => {
      if (toolbarActionButtons !== undefined) setToolBarActions(initialLayoutState.toolbar.actionButtons);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarActionButtons]);

  useEffect(() => {
    if (sidebarIsVisible !== undefined) setSidebarVisibility(sidebarIsVisible);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarIsVisible]);
};

export default useLayout;
