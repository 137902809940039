import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import useSubscription from '../../../modules/pubsub/useSubscription';
import useTaskboardDispatcher from './useTaskboardDispatcher';

const useTaskboardSubscriber = (workspaceId: string) => {
  const { dispatchMoveCardAcrossLists, dispatchAddCards, dispatchEditCard, dispatchModifyCommentsCount } =
    useTaskboardDispatcher();

  const { message } = useSubscription(workspaceId);

  useEffect(() => {
    const onMessageReceive = (topic: string, data: any) => {
      try {
        const parseData = JSON.parse(data);
        if (topic === workspaceId) {
          switch (parseData.type.toString()) {
            case 'create/list/tasks': {
              const { newTasks, listId } = parseData.data;
              dispatchAddCards(listId, newTasks);
              break;
            }
            case 'edit/task': {
              dispatchEditCard(parseData.data.list, parseData.data);
              break;
            }
            case 'update/task/list': {
              const { task, sourceListId, indexInDestinationList } = parseData.data;
              const destinationListId = task.list;
              const updateBE = false;
              dispatchMoveCardAcrossLists(task._id, sourceListId, destinationListId, indexInDestinationList, updateBE);
              break;
            }
            case 'update/task/modifyCommentsCount': {
              const { listId, taskId, commentModification } = parseData.data;
              dispatchModifyCommentsCount(listId, taskId, commentModification);
              break;
            }
            // TODO: handle MQTT for archiving/unarchiving a task, you need to do that from the BE also
            default:
              break;
          }
        }
      } catch (err: any) {
        captureException(err);
      }
    };

    if (!message) return;

    onMessageReceive(message.topic, message.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
};

export default useTaskboardSubscriber;
