import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PageSettingsType } from '../../App.types';
import { DDTaskAssignment, LearnSummariesResponse } from '../../pages/Growth/DDInstances.types';

interface DDSummaryReducerState {
  ddSummaries: LearnSummariesResponse;
  pageSettings: {
    [orgId: string]: PageSettingsType & { selectedDDs: { [ddId: string]: boolean } };
  };
}

const initialState: DDSummaryReducerState = {
  ddSummaries: {
    dds: [],
    categorySummaries: [],
    workspaceUsersMap: {},
    workspaces: [],
  },
  pageSettings: {},
};

const slice = createSlice({
  name: 'ddSummary',
  initialState,
  reducers: {
    setDDSummaries(state: DDSummaryReducerState, action: PayloadAction<DDSummaryReducerState['ddSummaries']>) {
      const clonedState = cloneDeep(state);
      return {
        ...clonedState,
        ddSummaries: action.payload,
      };
    },
    setSelectedDDs(state: DDSummaryReducerState, action: PayloadAction<{ orgId: string; ddId: string }>) {
      const { orgId, ddId } = action.payload;

      const clonedState = cloneDeep(state);

      if (clonedState.pageSettings[orgId].selectedDDs) {
        if (clonedState.pageSettings[orgId].selectedDDs[ddId]) {
          delete clonedState.pageSettings[orgId].selectedDDs[ddId];
        } else {
          clonedState.pageSettings[orgId].selectedDDs[ddId] = true;
        }
      } else {
        clonedState.pageSettings[orgId] = {
          selectedDDs: {
            [ddId]: true,
          },
        };
      }

      return clonedState;
    },
    setSortBy(
      state: DDSummaryReducerState,
      action: PayloadAction<{ orgId: string; sortBy: PageSettingsType['sortBy'] }>
    ) {
      const { orgId, sortBy } = action.payload;

      const clonedState = cloneDeep(state);

      return {
        ddSummaries: clonedState.ddSummaries,
        pageSettings: {
          ...clonedState.pageSettings,
          [orgId]: {
            ...clonedState.pageSettings[orgId],
            sortBy,
          },
        },
      };
    },
    addDDTaskAssignment(state, action: PayloadAction<{ categoryId: string; ddTaskAssignment: DDTaskAssignment }>) {
      const { categoryId, ddTaskAssignment } = action.payload;
      const clonedState = cloneDeep(state);

      clonedState.ddSummaries.categorySummaries
        .find((cat) => cat.categoryId === categoryId)
        ?.assignedActivities.push(ddTaskAssignment);

      return clonedState;
    },

    addUserToExistDDTaskAssignment(
      state,
      action: PayloadAction<{
        categoryId: string;
        ddTaskAssignmentId: DDTaskAssignment['_id'];
        assignments: DDTaskAssignment['assignments'];
      }>
    ) {
      const { categoryId, ddTaskAssignmentId, assignments } = action.payload;
      const clonedState = cloneDeep(state);

      const ddTaskAssignment = clonedState.ddSummaries.categorySummaries
        .find((cat) => cat.categoryId === categoryId)
        ?.assignedActivities.find((a) => a._id === ddTaskAssignmentId);

      ddTaskAssignment?.assignments.push(...assignments);
      ddTaskAssignment?.undoneTasks.push(...assignments.map((a) => ({ user: a.user, task: a.task })));

      return clonedState;
    },

    removeUserFromExistDDTaskAssignment(
      state,
      action: PayloadAction<{
        categoryId: string;
        ddTaskAssignmentId: DDTaskAssignment['_id'];
        taskId: string;
      }>
    ) {
      const { categoryId, ddTaskAssignmentId, taskId } = action.payload;
      const clonedState = cloneDeep(state);

      const ddTaskAssignment = clonedState.ddSummaries.categorySummaries
        .find((cat) => cat.categoryId === categoryId)
        ?.assignedActivities.find((a) => a._id === ddTaskAssignmentId);

      if (ddTaskAssignment) {
        ddTaskAssignment.assignments = ddTaskAssignment.assignments.filter((a) => a.task !== taskId);
        ddTaskAssignment.doneTasks = ddTaskAssignment.doneTasks.filter((a) => a.task !== taskId);
        ddTaskAssignment.undoneTasks = ddTaskAssignment.undoneTasks.filter((a) => a.task !== taskId);
      }

      return clonedState;
    },
  },
});

export const {
  setDDSummaries,
  setSortBy,
  setSelectedDDs,
  addDDTaskAssignment,
  addUserToExistDDTaskAssignment,
  removeUserFromExistDDTaskAssignment,
} = slice.actions;

export default persistReducer({ key: 'ddSummary', storage, whitelist: ['pageSettings'] }, slice.reducer);
