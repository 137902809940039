import { useEffect } from 'react';

export const useLiveChatSupportPopup = () => {
  const { REACT_APP_HUB_SPOT_LIVE_CHAT_SCRIPT_SRC } = process.env;
  useEffect(() => {
    const script = document.createElement('script');

    if (REACT_APP_HUB_SPOT_LIVE_CHAT_SCRIPT_SRC) {
      script.src = REACT_APP_HUB_SPOT_LIVE_CHAT_SCRIPT_SRC;
    }
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [REACT_APP_HUB_SPOT_LIVE_CHAT_SCRIPT_SRC]);
};

export default useLiveChatSupportPopup;
