import React, { useEffect } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Alert } from 'react-bootstrap';
import { MentionType } from './richTextEditor.type';
import useEditorConfig from './useEditorConfig';
import RichTextEditorContent, { RichTextEditorContentable } from './RichTextEditorContent';

type Props = {
  mentionOptions?: Array<MentionType>;
  value: string;
  onChange: (_c: RichTextEditorContentable) => void;
  placeholder: string;
  className?: string;
  isLoading?: boolean;
  isMinimalEditor?: boolean;
  tabIndex?: number;
};

const RichTextEditor: React.FC<Props> = ({
  mentionOptions,
  value,
  onChange,
  placeholder,
  className = '',
  isLoading,
  isMinimalEditor = false,
  tabIndex,
}) => {
  const { modules, editorRef, imageUploadError, isUploading } = useEditorConfig({
    mentionOptions,
    isMinimalEditor,
  });

  useEffect(() => {
    onChange(new RichTextEditorContent(editorRef.current, isUploading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploading]);

  const handleChange: ReactQuillProps['onChange'] = (_content, _delta, source) => {
    if (source === 'user') onChange(new RichTextEditorContent(editorRef.current, isUploading));
  };

  return (
    <>
      <Alert variant="danger" show={Boolean(imageUploadError)}>
        {imageUploadError}
      </Alert>
      <ReactQuill
        theme="snow"
        ref={editorRef}
        value={value}
        onChange={handleChange}
        modules={modules}
        placeholder={placeholder}
        className={`flex-column-reverse d-flex ${className} bloom-ql-editor`}
        readOnly={isLoading}
        tabIndex={tabIndex}
      />
    </>
  );
};

export default RichTextEditor;
