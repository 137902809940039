export const STATUS_CONNECT = 'connect';
export const STATUS_RECONNECT = 'reconnect';
export const STATUS_CONNECTING = 'connecting';
export const STATUS_CONNECTED = 'connected';
export const STATUS_RECONNECTING = 'reconnecting';
export const STATUS_ERROR = 'error';
export const STATUS_OFFLINE = 'offline';
export const STATUS_END = 'end';
export const STATUS_ENDED = 'ended';
export const STATUS_CLOSE = 'close';
