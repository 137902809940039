import React from 'react';
import { Accordion, ToggleButtonGroup } from 'react-bootstrap';
import FilterOptionItem from './FilterOptionItem';
import { FilterOption, TaskboardFilterKeys } from './Filter.types';

type FilterItemProps = {
  id: TaskboardFilterKeys;
  title: string;
  appliedFilters: { [key: string]: string };
  allowMultiSelect: boolean;
  options: FilterOption[];
  onOptionClick: (_optionId: string, _filterId: TaskboardFilterKeys) => void;
};

const FilterItem: React.FC<FilterItemProps> = ({
  id,
  title,
  appliedFilters,
  allowMultiSelect,
  options,
  onOptionClick,
}) => (
  <Accordion.Item eventKey={id}>
    <Accordion.Header>{title}</Accordion.Header>
    <Accordion.Body bsPrefix="mh-200px overflow-scroll">
      <ToggleButtonGroup type={allowMultiSelect ? 'checkbox' : 'radio'} name={id} className="d-flex flex-column">
        {options.map((opt) => (
          <FilterOptionItem
            key={`${id}-${opt.id}`}
            filterId={id}
            option={opt}
            selected={appliedFilters && opt.id in appliedFilters}
            onOptionClick={onOptionClick}
          />
        ))}
      </ToggleButtonGroup>
    </Accordion.Body>
  </Accordion.Item>
);

export default FilterItem;
