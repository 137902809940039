import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleValue } from 'react-select';
import { MinimalUserModel } from '../../modules/auth/models/UserModel';
import OptionWithAvatar from '../select/OptionWithAvatar';
import Select from '../select/Select';
import SingleValueWithAvatar from '../select/SingleValueWithAvatar';

export type MemberOption = {
  value: MinimalUserModel['_id'];
  label: string;
  imageLink?: string;
  user: MinimalUserModel;
};

type Props = {
  disabled?: boolean;
  assignee?: MinimalUserModel;
  members: MinimalUserModel[];
  onChange: (_user: MinimalUserModel) => void;
  onAssignToMe: () => void;
  isProgramAssignee?: boolean;
  programName?: string;
  tabIndex?: number;
};

const Assignee: React.FC<Props> = ({
  disabled,
  assignee,
  members,
  onChange,
  onAssignToMe,
  isProgramAssignee,
  programName,
  tabIndex,
}) => {
  const intl = useIntl();

  const handleChange = (value: SingleValue<MemberOption>) => onChange(value?.user!);

  const membersAsOptions: MemberOption[] = React.useMemo(
    () =>
      members.map((m) => ({
        value: m._id,
        label: m.firstname!,
        imageLink: m.images?.profile?.link,
        user: m,
      })),
    [members]
  );

  return (
    <>
      <Row>
        <h3 className="fw-bold mt-1">{intl.formatMessage({ id: 'Assigned To' })}</h3>
      </Row>
      <Row>
        {isProgramAssignee ? (
          <div>
            <p className="mb-0 mt-1">
              <FormattedMessage
                id="ASSIGN_TO_ALL_TEAMS"
                values={{
                  b: (...chunks: any) => <b>{chunks}</b>,
                  programName,
                }}
              />
            </p>
          </div>
        ) : (
          <div className="d-flex">
            <Select<MemberOption>
              isDisabled={disabled}
              components={{
                // @ts-ignore react-select is using generics everywhere and it making it hard to use
                Option: OptionWithAvatar,
                // @ts-ignore react-select is using generics everywhere and it making it hard to use
                SingleValue: SingleValueWithAvatar,
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              isMulti={false}
              onChange={handleChange}
              isClearable
              tabIndex={tabIndex}
              value={
                assignee && {
                  value: assignee._id,
                  label: assignee.firstname,
                  imageLink: assignee.images?.profile?.link,
                  user: assignee,
                }
              }
              options={membersAsOptions}
              styles={{
                menu: (provided: any) => ({
                  ...provided,
                  width: '15rem',
                }),
                container: (provided: any) => ({
                  ...provided,
                  width: '100%',
                }),
              }}
            />
            {assignee ? null : (
              <Button onClick={onAssignToMe} size="sm" className="ms-1">
                {intl.formatMessage({ id: 'Me' })}
              </Button>
            )}
          </div>
        )}
      </Row>
    </>
  );
};

export default Assignee;
