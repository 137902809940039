/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Overlay, Popover } from 'react-bootstrap';
import KTSVG from '../../../components/KTSVG/KTSVG';

type Props = {
  title?: string;
  icon?: string;
  fontIcon?: string;
  hasArrow?: boolean;
  hasBullet?: boolean;
  isActive?: boolean;
  children?: React.ReactNode;
};

const MenuInnerWithSub: React.FC<Props> = ({
  children,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  isActive = false,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItemRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        role="button"
        ref={menuItemRef}
        className="menu-item"
        data-name="more-menu-item"
        onClick={() => setIsMenuOpen((prevState) => !prevState)}
      >
        <span
          className={clsx('menu-link py-3', {
            active: isActive,
          })}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot" />
            </span>
          )}

          {icon && (
            <span className="menu-icon">
              <KTSVG path={icon} className="svg-icon-2" />
            </span>
          )}

          {fontIcon && (
            <span className="menu-icon">
              <i className={clsx('bi fs-3', fontIcon)} />
            </span>
          )}

          {title && <span className="menu-title">{title}</span>}

          {hasArrow && <span className={`menu-arrow ${!title ? 'ms-0' : ''}`} />}
        </span>
      </div>
      <Overlay
        target={menuItemRef.current}
        placement="bottom-start"
        show={isMenuOpen}
        rootClose
        onHide={() => setIsMenuOpen(false)}
      >
        <Popover>
          <Popover.Body className="p-2 w-175px">{children}</Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export { MenuInnerWithSub };
