import { ValueOf } from '../../shared/types';
import { UserModel } from '../auth/models/UserModel';
import { MinimalWorkspaceType } from '../org/org.types';

const schemaTypes = {
  TASK: 'Task',
  COMMENT: 'Comment',
  INVITATION: 'Invitation',
} as const;

export type CreatedForEntity = {
  id: string;
  schemaType: ValueOf<typeof schemaTypes>;
  throughAction?: string;
};

export type NotificationSubscriber = {
  _id?: string;
  appearsInBell?: boolean;
  parentId?: string;
  pinned?: boolean;
  subscribedOn?: Date;
  subscriberId?: string;
  unseen?: boolean;
};

export type BellMessageParams = {
  acceptInviteToTeam?: number;
  archive?: number;
  comment?: number;
  commentEdit?: number;
  create?: number;
  delete?: number;
  edit?: number;
  inviteToProgram?: number;
  inviteToTeam?: number;
  mention?: number;
  resolve?: number;
  unarchive?: number;
  upload?: number;
};

export type SingleChildNotification = {
  _id: string;
  title: string;
  author: string;
  createdAt: Date;
  createdForEntity: CreatedForEntity;
  message: string;
  subscribers: Array<NotificationSubscriber>;
  updatedAt: Date;
  subscriberId: string;
};

export type MinimalParentNotificationType<WSType extends string | MinimalWorkspaceType = string> = Pick<
  ParentNotificationType<WSType>,
  | '_id'
  | 'notifications'
  | 'displayMessage'
  | 'usersInvolved'
  | 'unread'
  | 'title'
  | 'lastChildCreatedAt'
  | 'workspace'
  | 'createdForEntity'
>;

export interface ParentNotificationType<WSType extends string | MinimalWorkspaceType = string> {
  _id: string;
  bellMessageParams: BellMessageParams;
  createdForEntity: CreatedForEntity;
  displayMessage: string;
  highlightedChildren: Array<string>;
  highlightedChildrenCount: number;
  lastChildCreatedAt: Date;
  messageParams: any;
  notifications: Array<string>;
  pinnedState: string;
  pins: Array<string>;
  priority: string;
  priorityChildren: {
    high: Array<string>;
    normal: Array<string>;
  };
  singleChildNotification: SingleChildNotification;
  subscriberId: string;
  title: string;
  unread: Array<string>;
  unseen: Array<string>;
  unseenAndPins: Array<string>;
  updatedAt: Date;
  createdAt: Date;
  usersInvolved: Array<UserModel>;
  usersInvolvedInBell: Array<string>;
  usersShownInBell: Array<string>;
  workspace: WSType;
}

export interface ChildNotificationType {
  _id: string;
  createdForEntity: CreatedForEntity;
  author: UserModel;
  updatedAt: Date;
  createdAt: Date;
  message: string;
  pinned: false;
  unread: true;
  unseen: false;
}

export interface DiscourseNotificationType {
  id: number;
  user_id: number;
  external_id: string;
  notification_type: number;
  read: boolean;
  high_priority: boolean;
  created_at: Date;
  post_number: number;
  topic_id: number;
  fancy_title: string;
  slug: string;
  data: {
    display_username: string;
    original_post_id: number;
    original_post_type: number;
    original_username: string;
    revision_number: number;
    topic_title: string;
  };
}

export interface NotificationsResponseType {
  highlightedChildrenCount: number;
  notifications: Array<MinimalParentNotificationType & DiscourseNotificationType>;
  parentNotificationsCount: number;
  total: number;
  unreadNotifications: number;
  unseenAndPinsCount: number;
  unseenCount: number;
}

export interface ChildNotificationsResponseType {
  records: Array<ChildNotificationType>;
  totalRecordCount: number;
}

export interface WorkspaceUnreadNotificationCountsMapType {
  [workspaceId: string]: number;
}

export function isParentNotification(
  notification: MinimalParentNotificationType | DiscourseNotificationType
): notification is MinimalParentNotificationType {
  return (notification as MinimalParentNotificationType)._id !== undefined;
}
