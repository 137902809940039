import React, { useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { Button, Modal } from 'react-bootstrap';

const ASPECT = 1 / 1;

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

type IProps = {
  show: boolean;
  title: string;
  discardText: string;
  submitText: string;
  src: string;
  onCancel: () => void;
  onSubmit: (_c: any) => void;
};

const BloomReactCropModal: React.FC<IProps> = ({ show, src, title, discardText, submitText, onCancel, onSubmit }) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const imgRef = useRef<HTMLImageElement>(null);

  const getCroppedImg = (image: any, crop: PixelCrop, fileName: string) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx!.imageSmoothingQuality = 'high';

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise<{ dataURL: string; previewURL: string }>((resolve, reject) => {
      const dataURL = canvas.toDataURL();
      canvas.toBlob((blob) => {
        if (!blob) {
          return reject(new Error('Canvas is empty'));
        }
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        blob.name = fileName; // I got this line from the documentations, so I have to disable the rules to get it work
        return resolve({
          dataURL,
          previewURL: window.URL.createObjectURL(blob),
        });
      }, 'image/jpeg');
    });
  };

  const handleSubmitCrop = async () => {
    if (imgRef && completedCrop) {
      const newImage = await getCroppedImg(imgRef.current, completedCrop, 'name.png');
      onSubmit(newImage);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, ASPECT));
  };

  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactCrop
          crop={crop}
          onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={ASPECT}
        >
          <img src={src} alt="Crop me" onLoad={onImageLoad} ref={imgRef} />
        </ReactCrop>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {discardText}
        </Button>
        <Button variant="primary" onClick={handleSubmitCrop}>
          {submitText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BloomReactCropModal;
