import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { shallowEqual, useSelector } from 'react-redux';
import useLayoutDispatcher from '../../../layout/hooks/useLayoutDispatcher';
import { RootState } from '../../../../setup';

type Props = {
  workspaceId: string;
  teamId?: string;
  onDropDownToggle?: (_nextShow: boolean) => void;
};

const WorkspaceDropdownMenu: FC<Props> = ({ workspaceId, teamId, onDropDownToggle }) => {
  const intl = useIntl();

  const { toggleSidebarState } = useLayoutDispatcher();

  const { sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const onMenuItemClick = () => {
    if (sidebar.isDrawer && sidebar.isSidebarOpen) toggleSidebarState();
  };

  return (
    <Dropdown onToggle={onDropDownToggle}>
      <Dropdown.Toggle size="sm" variant="transparent" className="py-0 ps-1 pe-1">
        <FontAwesomeIcon size="xl" icon={faEllipsis} className="text-muted" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/teams/${teamId}/workspaces/${workspaceId}/settings`} onClick={onMenuItemClick}>
          {intl.formatMessage({
            id: 'Workspace Settings',
          })}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default WorkspaceDropdownMenu;
