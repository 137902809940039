import { AxiosRequestConfig } from 'axios';
import { MinimalWorkspaceType, OrgModel } from '../org/org.types';
import { request } from '../../shared/http.service';
import {
  ChildNotificationsResponseType,
  NotificationsResponseType,
  MinimalParentNotificationType,
  WorkspaceUnreadNotificationCountsMapType,
} from './notifications.types';

const notificationService = {
  getParentNotifications: async (
    workspaceId: string,
    {
      page = 1,
      perPage = 10,
      isShowOnlyUnread = false,
    }: {
      page: number;
      perPage: number;
      isShowOnlyUnread: boolean;
    }
  ) => {
    const res = await request({
      url: '/v2/notifications/all-notifications',
      method: 'get',
      params: {
        page,
        perPage,
        workspaceId,
        isShowOnlyUnread: isShowOnlyUnread ? 1 : 0,
      },
    });
    return res.data.data as NotificationsResponseType;
  },
  getChildNotificationsByParentId: async (parentId: string, page = 1, perPage = 5, highlightedOnly = false) => {
    const res = await request({
      url: `/v2/notifications/user/${parentId}`,
      method: 'get',
      params: {
        page,
        perPage,
        highlightedOnly,
      },
    });
    return res.data.data as ChildNotificationsResponseType;
  },
  markAllChildNotificationsAsRead: (parentId: string) =>
    request({
      url: `/v2/notifications/read/${parentId}`,
      method: 'put',
    }),
  markChildNotificationAsRead: (parentNotificationId: string, childNotificationId: string) =>
    request({
      url: `/v2/notifications/read/${parentNotificationId}/${childNotificationId}`,
      method: 'put',
    }),

  markChildNotificationAsUnread: (parentNotificationId: string, childNotificationId: string) =>
    request({
      url: `/v2/notifications/unread/${parentNotificationId}/${childNotificationId}`,
      method: 'put',
    }),
  markDiscourseNotificationAsRead: (notificationId: number) =>
    request({
      url: `/v2/notifications/read-discourse-notification/${notificationId}`,
      method: 'put',
    }),

  getOrgParentNotifications: async (
    orgId: OrgModel['_id'],
    options: { isOnlyUnread: boolean },
    signal: AxiosRequestConfig['signal']
  ) => {
    const res = await request({ url: `/orgs/${orgId}/notifications`, params: options, signal });
    return res?.data?.data?.notifications as MinimalParentNotificationType<MinimalWorkspaceType>[];
  },
  getWorkspaceNotificationCountsMap: async (signal: AxiosRequestConfig['signal']) => {
    const res = await request({ url: `/v2/users/me/workspace-notification-counts-map`, signal });
    return res?.data?.data?.workspaceUnreadNotificationCountsMap as WorkspaceUnreadNotificationCountsMapType;
  },
};

export default notificationService;
