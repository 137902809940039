import React from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import BloomAvatar from '../../../components/bloomAvatar/BloomAvatar';
import BloomAvatarGroup from '../../../components/bloomAvatar/BloomAvatarGroup';
import { BloomAvatarProps } from '../../../components/bloomAvatar/BloomAvatar.types';
import { checkIsMenuActive } from '../../../shared/helperFunctions';
import KTSVG from '../../../components/KTSVG/KTSVG';
import { RootState } from '../../../../setup';
import './styles.scss';
import useLayoutDispatcher from '../../hooks/useLayoutDispatcher';

type Props = {
  to: string;
  title: string;
  icon?: string;
  isActive?: boolean;
  isSubItem?: boolean;
  avatars?: [BloomAvatarProps, BloomAvatarProps] | [BloomAvatarProps];
  dropdownMenu?: React.ReactNode;
  isDropDownOpen?: boolean;
};

const AsideMenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  isActive: forceIsActive,
  isSubItem = false,
  avatars,
  dropdownMenu,
  isDropDownOpen,
}) => {
  const { pathname } = useLocation();

  const { sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const { toggleSidebarState } = useLayoutDispatcher();

  const isActive = forceIsActive ?? checkIsMenuActive(pathname, to);

  return (
    <div
      className={clsx(
        `d-flex align-items-center ${isSubItem ? 'ms-7' : 'ms-3'} mt-2 py-3 rounded-2 bg-white ${
          sidebar.isSidebarOpen ? 'w-240px' : 'w-65px'
        }`,
        {
          'show-hide-dropdown': !isDropDownOpen,
        }
      )}
    >
      <Link
        className="d-flex align-items-center ps-3 pe-2 flex-grow-1"
        to={to}
        onClick={() => {
          if (sidebar.isDrawer) toggleSidebarState();
        }}
      >
        {icon && !avatars && (
          <KTSVG
            path={icon}
            className={`svg-icon svg-icon-1 ${isActive ? 'svg-icon-primary' : 'svg-icon-bloom-dark-blue'} ms-3 me-0`}
          />
        )}
        {avatars && (
          <div className="me-2">
            {avatars.length === 1 ? <BloomAvatar {...avatars[0]} /> : <BloomAvatarGroup avatarList={avatars} />}
          </div>
        )}
        <p
          className={`fs-5 ${isSubItem ? 'fw-bold' : 'fw-bolder'} mb-0 text-truncate text-start smooth-hide ${
            sidebar.isSidebarOpen ? 'opacity-1 ms-4' : 'opacity-0 ms-0'
          } ${isActive ? 'text-primary' : 'text-bloom-dark-blue'}`}
          style={{ width: 147 }}
        >
          {title}
        </p>
      </Link>
      {dropdownMenu}
    </div>
  );
};

export default AsideMenuItem;
