import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';

type Props = {};

const Loading: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">{intl.formatMessage({ id: 'LOADING_MESSAGE' })}</span>
      </Spinner>
    </div>
  );
};

export default Loading;
