import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../setup';
import { sortLabelsAlphabetically } from '../../../../../../modules/label/label.service';
import { FilterObject, FilterOption } from '../../../../../../components/filter/Filter.types';
import { UserModel } from '../../../../../../modules/auth/models/UserModel';
import { LabelType } from '../../../../../../components/taskboard/Taskboard.types';

const useTaskboardFilters = () => {
  const intl = useIntl();
  const { members } = useSelector<RootState, RootState['currentWorkspace']>(({ currentWorkspace }) => currentWorkspace);
  const { labels } = useSelector<RootState, RootState['currentWorkspace']>(({ currentWorkspace }) => currentWorkspace);

  const sortedLabels = sortLabelsAlphabetically([...labels]);

  const { appliedFilters, _id: taskboardId } = useSelector<RootState, RootState['taskboard']>(
    ({ taskboard }) => taskboard
  );

  const assignedToOptions = useMemo<FilterOption[]>(() => {
    const filterOptions = members.map(({ user }: { user: UserModel }) => ({
      id: user._id as string,
      text: user.firstname,
      img: user.images?.profile?.link || user.firstname,
    }));
    filterOptions.push({
      id: 'UNASSIGNED',
      text: intl.formatMessage({ id: 'TASK_FILTERS.UNASSIGNED' }),
      img: 'unonymous',
    });
    return filterOptions;
  }, [members, intl]);

  const labelOptions = useMemo<FilterOption[]>(
    () =>
      sortedLabels.map((label: LabelType) => ({
        id: label._id,
        text: label.name,
      })),
    [sortedLabels]
  );

  const dueDateOptions: FilterOption[] = [
    {
      id: 'today',
      text: 'Today',
    },
    {
      id: 'tomorrow',
      text: 'Tomorrow',
    },
    {
      id: 'next_7_days',
      text: 'Next 7 days',
    },
    {
      id: 'next_30_days',
      text: 'Next 30 days',
    },
    {
      id: 'overdue',
      text: 'Overdue',
    },
    {
      id: 'no_due_date',
      text: 'No due date',
    },
  ];

  const filters = useMemo<FilterObject[]>(
    () => [
      {
        id: 'assignedTo',
        title: intl.formatMessage({ id: 'TASK_FILTERS.ASSIGNED_TO' }),
        allowMultiSelect: true,
        options: assignedToOptions,
      },
      {
        id: 'labels',
        title: intl.formatMessage({ id: 'TASK_FILTERS.LABELS' }),
        allowMultiSelect: true,
        options: labelOptions,
      },
      {
        id: 'dueDate',
        title: intl.formatMessage({ id: 'TASK_FILTERS.DUE_DATE' }),
        allowMultiSelect: true,
        options: dueDateOptions,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignedToOptions, labelOptions, intl]
  );

  return { filters, appliedFilters: appliedFilters[taskboardId!] || {} };
};

export default useTaskboardFilters;
