import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { TaskType, TaskboardFilterKeys } from '../Taskboard.types';
import useTaskboardDispatcher from './useTaskboardDispatcher';
import constants from '../../../shared/consts';

type booleanSetterFunction = (_flag: boolean) => void;

const useTaskboardHandlers = (setShowCelebration?: booleanSetterFunction) => {
  const history = useHistory();
  const {
    dispatchMoveCardAcrossLists,
    dispatchAddCards,
    dispatchEditCard,
    dispatchRemoveCard,
    dispatchArchiveTask,
    dispatchSetSearchString,
    dispatchSetFilters,
    dispatchApplyFilters,
    dispatchResetFilters,
  } = useTaskboardDispatcher();

  const { displayListsMap, listsMap } = useSelector<RootState, RootState['taskboard']>(({ taskboard }) => taskboard);

  const firstListId = useSelector<RootState, string | undefined>(
    ({ taskboard }) => taskboard.listsOrder?.[0],
    shallowEqual
  );

  const getTaskPositionInOriginList = (position: number, targetListId: string) => {
    if (position === 0) return position;

    const displayedList = displayListsMap[targetListId]; // This is the filtered list
    const previousTaskInOrder = displayedList.tasks[position - 1];

    const list = listsMap[targetListId];
    const originPositionForPreviousTask = list.tasks.findIndex((t) => t._id === previousTaskInOrder._id);
    return originPositionForPreviousTask + 1;
  };

  const moveCardAcrossLists = (sourceListId: string, targetListId: string, cardId: string, position: number) => {
    const targetList = displayListsMap[targetListId];
    if (setShowCelebration && targetList.name === constants.taskboardListKeys.DONE) {
      setShowCelebration(true);
    }
    const originTaskPosition = getTaskPositionInOriginList(position, targetListId);
    dispatchMoveCardAcrossLists(cardId, sourceListId, targetListId, originTaskPosition);
  };

  return {
    handleDragEnd: (result: {
      source: { droppableId: string; index: number };
      destination?: { droppableId: string; index: number };
    }) => {
      if (!result.destination) {
        return;
      }

      const sourceColumn = displayListsMap[result.source.droppableId];
      const item = sourceColumn.tasks[result.source.index];

      moveCardAcrossLists(
        result.source.droppableId,
        result.destination.droppableId,
        item._id,
        result.destination.index
      );
    },

    handleMoveCardAcrossLists: moveCardAcrossLists,

    handleCardClick: (taskId: string) => {
      history.push({
        pathname: `taskboard/task/${taskId}`,
      });
    },

    handleCreateTasks: (tasks: TaskType[]) => {
      if (!firstListId) {
        return;
      }
      // Always add new tasks to the first list
      dispatchAddCards(firstListId, tasks);
    },

    handleEditTask: (task: TaskType) => {
      dispatchEditCard(task.list, task);
    },

    handleCardDelete: (cardId: string, listId: string) => {
      dispatchArchiveTask(listId, cardId);
    },

    handleRemoveTask: (task: TaskType) => {
      dispatchRemoveCard(task.list, task._id);
    },

    handleFilterTasksBySearchString: (searchString: string) => {
      dispatchSetSearchString(searchString);
      dispatchApplyFilters();
    },

    handleFilterTasks: (optionId: string, filterId: TaskboardFilterKeys) => {
      dispatchSetFilters(optionId, filterId);
      dispatchApplyFilters();
    },

    handleApplyFilters: () => {
      dispatchApplyFilters();
    },

    handleResetFilters: () => {
      dispatchResetFilters();
      dispatchApplyFilters();
    },
  };
};

export default useTaskboardHandlers;
