/*
 * This file provides a helper function to make API calls in simpler way
 */

import axios, { AxiosRequestConfig, Method, AxiosError } from 'axios';
import { getLocalStorageToken } from './localStorage.service';
import { UploadedFileResponse, UploadAssetResponse } from './types';

const api = process.env.REACT_APP_SERVER_URL || 'http://localhost:8080/api';

const getTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return timeZone;
};

interface RequestType {
  url: string;
  data?: any;
  method?: Method;
  noHeaders?: boolean;
  params?: any;
  signal?: AxiosRequestConfig['signal'];
}

const errorHandler = (err: AxiosError<any>) => {
  const httpError = new CustomEvent('HttpError', {
    detail: {
      error: err,
    },
  });
  if (err && err.response && err.response.data && err.response.data.message) {
    // eslint-disable-next-line no-param-reassign
    err.message = err.response.data.message;
  }
  window.dispatchEvent(httpError);
  return err;
};

export const uploadFiles = async ({ url, formData }: { url: string; formData: FormData }) => {
  try {
    const response = await axios.post(`${api}/${url}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-access-token': getLocalStorageToken(),
      },
    });

    return response.data.file as UploadedFileResponse;
  } catch (error: any) {
    throw errorHandler(error);
  }
};

export const uploadAsset = async ({ file, queryString = '' }: { file: File; queryString?: string }) => {
  try {
    const fileData = new FormData();
    fileData.append('file', file, file.name);

    const response = await axios.post(`${api}/assets/upload${queryString}`, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-access-token': getLocalStorageToken(),
      },
    });

    return response.data.asset as UploadAssetResponse;
  } catch (error: any) {
    throw errorHandler(error);
  }
};

export const request = ({ url, data, method, noHeaders, params, signal }: RequestType) =>
  new Promise<any>((resolve, reject) => {
    const requestParams: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        'x-time-zone': getTimeZone(),
      },
      method,
      url: api + url,
      signal,
    };
    if (method !== 'get') {
      requestParams.data = data;
    }
    if (!noHeaders) {
      requestParams.headers = {
        ...requestParams.headers,
        'x-access-token': getLocalStorageToken(),
      };
    }
    if (params) {
      requestParams.params = params;
    }
    axios(requestParams)
      .then((res: any) => resolve(res))
      .catch((err) => reject(errorHandler(err)));
  });
