import { useDispatch } from 'react-redux';
import { IHeader, IToolbar } from '../Layout.types';
import {
  setHeaderVisibility,
  setHeaderTitleVisibility,
  setHeaderTitleLoading,
  setHeaderTitle,
  setHeaderAvatars,
  setUserMenuVisibility,
  setWelcomeTitleVisibility,
  setOrgNotificationsBellVisibility,
  setMessagesVisibility,
  setToolBarVisibility,
  setToolBarMenuItems,
  setToolBarActionButtons,
  setSidebarVisibility,
  toggleSidebarState,
  setSidebarIsDrawer,
} from '../Layout.slice';

const useLayoutDispatcher = () => {
  const dispatch = useDispatch();

  return {
    setHeaderVisibility: (isVisible: boolean) => {
      dispatch(setHeaderVisibility(isVisible));
    },
    setHeaderTitleVisibility: (isVisible: boolean) => {
      dispatch(setHeaderTitleVisibility(isVisible));
    },
    setHeaderTitleLoading: (isLoading: boolean) => {
      dispatch(setHeaderTitleLoading(isLoading));
    },
    setHeaderTitle: (title: string) => {
      dispatch(setHeaderTitle(title));
    },
    setHeaderAvatars: (avatars: IHeader['pageTitle']['avatars']) => {
      dispatch(setHeaderAvatars(avatars));
    },
    setUserMenuVisibility: (isVisible: boolean) => {
      dispatch(setUserMenuVisibility(isVisible));
    },
    setWelcomeTitleVisibility: (isVisible: boolean) => {
      dispatch(setWelcomeTitleVisibility(isVisible));
    },
    setOrgNotificationsBellVisibility: (isVisible: boolean) => {
      dispatch(setOrgNotificationsBellVisibility(isVisible));
    },
    setMessagesVisibility: (isVisible: boolean) => {
      dispatch(setMessagesVisibility(isVisible));
    },
    setToolBarVisibility: (isVisible: boolean) => {
      dispatch(setToolBarVisibility(isVisible));
    },
    setToolBarMenuItems: (menuItems: IToolbar['menuItems']) => {
      dispatch(setToolBarMenuItems(menuItems));
    },
    setToolBarActions: (actionButtons: IToolbar['actionButtons']) => {
      dispatch(setToolBarActionButtons(actionButtons));
    },
    setSidebarVisibility: (isVisible: boolean) => {
      dispatch(setSidebarVisibility(isVisible));
    },
    toggleSidebarState: () => {
      dispatch(toggleSidebarState());
    },
    setSidebarIsDrawer: (isDrawer: boolean) => {
      dispatch(setSidebarIsDrawer(isDrawer));
    },
  };
};

export default useLayoutDispatcher;
