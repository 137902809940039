import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Badge, Overlay, Popover } from 'react-bootstrap';
import { faFilter, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { useTaskboardHandlers } from '../../../../../components/taskboard/Taskboard.hooks';
import { useTaskboardFilters } from './FilterTasksBtn.hooks';
import IconButton from '../../../../../components/IconButton';
import { RootState } from '../../../../../../setup';
import FilterContainer from '../../../../../components/filter/FilterContainer';
import useScreenSize from '../../../../../modules/dimensions/useScreenSize';

const FilterTasksBtn: React.FC = () => {
  const intl = useIntl();
  const filtersBTNRef = useRef(null);
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);

  const { isScreenSmallerThanOrEqual } = useScreenSize();

  const { counts } = useSelector<RootState, RootState['taskboard']>(({ taskboard }) => taskboard);

  const { handleFilterTasks, handleApplyFilters, handleResetFilters } = useTaskboardHandlers();
  const { filters, appliedFilters } = useTaskboardFilters();
  const appliedFiltersCount = appliedFilters
    ? Object.values(appliedFilters)
        .map((f) => Object.values(f).length)
        .reduce((p, c) => p + c, 0)
    : 0;

  const isDueDateOverdue = appliedFilters.dueDate && 'overdue' in appliedFilters.dueDate;
  const isDueDateToday = appliedFilters.dueDate && 'today' in appliedFilters.dueDate;

  const isMinimalButton = isScreenSmallerThanOrEqual('lg');

  return (
    <>
      <IconButton
        size="sm"
        variant={isDueDateOverdue ? 'primary' : 'light'}
        icon={faTriangleExclamation}
        iconSize="lg"
        className={isDueDateOverdue ? 'text-white' : ''}
        onClick={() => handleFilterTasks('overdue', 'dueDate')}
        title={intl.formatMessage({ id: 'Overdue' })}
      >
        {!isMinimalButton ? intl.formatMessage({ id: 'Overdue' }) : null}
        {counts.overdue > 0 && (
          <Badge bg="danger" className="ms-1 p-1">
            {counts.overdue}
          </Badge>
        )}
      </IconButton>
      <IconButton
        size="sm"
        variant={isDueDateToday ? 'primary' : 'light'}
        icon={faCalendarCheck}
        iconSize="lg"
        className={isDueDateToday ? 'text-white' : ''}
        onClick={() => handleFilterTasks('today', 'dueDate')}
        title={intl.formatMessage({ id: 'Today' })}
      >
        {!isMinimalButton ? intl.formatMessage({ id: 'Today' }) : null}
        {counts.today > 0 && (
          <Badge bg="warning" className="ms-1 p-1 text-dark ">
            {counts.today}
          </Badge>
        )}
      </IconButton>

      <div ref={filtersBTNRef}>
        <IconButton
          size="sm"
          variant="light"
          icon={faFilter}
          iconSize="lg"
          onClick={() => setShowFiltersMenu((prevState) => !prevState)}
          title={intl.formatMessage({ id: 'TOOLBAR_ACTIONS.FILTER' })}
        >
          {!isMinimalButton ? intl.formatMessage({ id: 'TOOLBAR_ACTIONS.FILTER' }) : null}
          {appliedFiltersCount > 0 && <Badge className="ms-2 p-1">{appliedFiltersCount}</Badge>}
        </IconButton>
      </div>

      <Overlay
        target={filtersBTNRef.current}
        placement="bottom"
        show={showFiltersMenu}
        rootClose
        onHide={() => setShowFiltersMenu(false)}
      >
        <Popover className="min-w-300px">
          <Popover.Body>
            <FilterContainer
              filters={filters}
              appliedFilters={appliedFilters}
              onApplyFilters={handleApplyFilters}
              onClearAllFilters={handleResetFilters}
              onOptionClick={handleFilterTasks}
              appliedFiltersCount={appliedFiltersCount}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default FilterTasksBtn;
