import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { OrgModel } from '../../../modules/org/org.types';
import { RootState } from '../../../../setup';
import AsideMenuItem from './AsideMenuItem';
import ProgramDropdownMenu from '../../../pages/hub/components/ProgramDropdownMenu';
import constants from '../../../shared/consts';

type WorkspaceMenuItemProps = {
  parentTeamId: string;
  program: OrgModel;
  isActive: boolean;
  to: string;
  isSubItem?: boolean;
};

const ProgramMenuItem: React.FC<WorkspaceMenuItemProps> = ({
  parentTeamId,
  program,
  isActive,
  to,
  isSubItem = false,
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const workspaceUnreadNotificationCountsMap = useSelector<
    RootState,
    RootState['notifications']['workspaceUnreadNotificationCountsMap']
  >((state) => state.notifications.workspaceUnreadNotificationCountsMap);

  const unreadNotificationsCount = useMemo(
    () => program.workspaces.reduce((acc, cur) => acc + workspaceUnreadNotificationCountsMap[cur as string], 0),
    [program.workspaces, workspaceUnreadNotificationCountsMap]
  );

  const loggedInUser = useSelector<RootState, RootState['user']>((state) => state.user, shallowEqual);

  return (
    <AsideMenuItem
      to={to}
      title={program.name}
      isActive={isActive}
      isSubItem={isSubItem}
      avatars={[
        {
          imagePath: program.logo?.link,
          name: program.name,
          size: 'xxs',
          badge: unreadNotificationsCount,
        },
      ]}
      dropdownMenu={
        <ProgramDropdownMenu
          programId={program._id}
          programName={program.name}
          isProgramOwner={program.members.some(
            (member) => member.user._id === loggedInUser._id && member.role === constants.userRoles.OWNER
          )}
          parentOrgId={parentTeamId}
          onDropDownToggle={(nextShow) => setIsDropDownOpen(nextShow)}
        />
      }
      isDropDownOpen={isDropDownOpen}
    />
  );
};

export default ProgramMenuItem;
