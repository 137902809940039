import React from 'react';
import { Alert, Button, Container, Form, Modal } from 'react-bootstrap';
import './styles.scss';

type IProps = {
  show: boolean;
  title: string;
  discardText: string;
  submitText: string;
  onHide: () => void;
  onCancel: () => void;
  onSubmit: (_a: any) => void;
  isSubmitting: boolean;
  isValid: boolean;
  isDirty: boolean;
  generalError: string | null;
  children?: React.ReactNode;
};

const CreateModal: React.FC<IProps> = ({
  show,
  onHide,
  title,
  onCancel,
  onSubmit,
  discardText,
  submitText,
  children,
  isSubmitting,
  isValid,
  isDirty,
  generalError,
}) => {
  const handleModalHide = () => {
    if (!isSubmitting) {
      onHide();
    }
  };
  return (
    <Modal show={show} onHide={handleModalHide} className="create-modal" fullscreen="sm-down">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Container>
            <Alert variant="danger" show={Boolean(generalError)}>
              {generalError}
            </Alert>
            {children}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel} disabled={isSubmitting}>
            {discardText}
          </Button>
          <Button variant="primary" onClick={onSubmit} type="submit" disabled={isSubmitting || !isValid || !isDirty}>
            {submitText}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateModal;
