import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage, useIntl } from 'react-intl';

type IProps = {
  show: boolean;
  title: string;
  itemToDeleteName: string;
  itemToDeleteType: string;
  onDeleteApproval: () => void;
  generalError?: string;
  onHide: () => void;
  onCancel: () => void;
  isSubmitting: boolean;
};

const DeleteApprovalModal: React.FC<IProps> = ({
  show,
  title,
  itemToDeleteName,
  itemToDeleteType,
  generalError,
  onHide,
  onDeleteApproval,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleModalHide = () => {
    if (!isSubmitting) {
      onHide();
    }
  };
  return (
    <Modal show={show} onHide={handleModalHide} className="create-modal" fullscreen="sm-down">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Alert variant="danger" show={Boolean(generalError)}>
            {generalError}
          </Alert>
          <p>
            <FormattedMessage
              id="DELETE_MODAl_TEXT"
              values={{
                b: (...chunks: any) => <b>{chunks}</b>,
                itemToDeleteName,
                orgType: itemToDeleteType,
              }}
            />
          </p>
          <Form.Control
            type="text"
            name="deleteApproval"
            className="form-control form-control-lg form-control-solid mb-4 mb-lg-3"
            value={inputValue}
            autoComplete="off"
            onChange={handleInputChange}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel} disabled={isSubmitting}>
          {intl.formatMessage({ id: 'MODAL.CANCEL' })}
        </Button>
        <Button
          variant="danger"
          onClick={onDeleteApproval}
          disabled={isSubmitting || inputValue.toLowerCase() !== 'delete'}
        >
          {intl.formatMessage({ id: 'Delete' })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteApprovalModal;
