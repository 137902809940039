import React from 'react';
import FileCard from '../fileCard/FileCard';

type Props = {
  files: File[];
  onRemoveFile: (_fileIndex: number) => void;
  disabled?: boolean;
};

const FilesList: React.FC<Props> = ({ files, onRemoveFile, disabled }) => (
  <div className="d-flex flex-wrap my-3">
    {files.map((file, index) => (
      <FileCard
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        title={file.name}
        fileLink={URL.createObjectURL(file)}
        onDelete={() => onRemoveFile(index)}
        iconPath="/media/svg/files/file.svg"
        disabled={disabled}
      />
    ))}
  </div>
);

export default FilesList;
