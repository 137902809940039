import { FC } from 'react';
import { useSelector } from 'react-redux';
import MenuInner from './MenuInner';
import ToolbarActions from './toolbarActions/ToolbarActions';
import { RootState } from '../../../../setup';

const Toolbar: FC = () => {
  const screenSize = useSelector<RootState, RootState['dimensions']['screenSize']>(
    ({ dimensions }) => dimensions.screenSize
  );

  const isSmallScreen = screenSize === 'xs';

  return (
    <div className={`page-toolbar container-fluid d-flex flex-stack align-items-center ${isSmallScreen ? 'px-0' : ''}`}>
      <div className="d-flex header-menu menu menu-rounded menu-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-0 align-items-stretch">
        <MenuInner />
      </div>

      <div className="d-flex justify-content-end container-fluid px-0">
        <ToolbarActions />
      </div>
    </div>
  );
};

export default Toolbar;
