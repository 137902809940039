import { Asset } from '../../../types/assets.types';
import { request } from '../../shared/http.service';
import { LabelType, TaskType } from '../taskboard/Taskboard.types';

const taskService = {
  createLabel: async (labelText: string, labelColor: string, workspaceId: string) => {
    const data = {
      color: labelColor,
      name: labelText,
    };
    const res = await request({ url: `/workspaces/${workspaceId}/label`, data, method: 'post' });
    return res.data.data.label as LabelType;
  },

  editLabel: async (labelText: string, labelColor: string, labelId: string) => {
    const data = {
      color: labelColor,
      name: labelText,
    };
    const res = await request({ url: `/v2/labels/${labelId}`, data, method: 'put' });
    return res.data.data.label as LabelType;
  },

  deleteLabel: async (labelId: string) => {
    const res = await request({ url: `/v2/labels/${labelId}`, method: 'delete' });
    return res.data.data.label as LabelType;
  },

  addAttachment: async (taskId: TaskType['_id'], fileData: FormData) => {
    const res = await request({
      url: `/v2/tasks/${taskId}/attachments`,
      method: 'post',
      data: fileData,
    });
    return res.data.data.task as TaskType;
  },

  deleteAttachment: async (taskId: TaskType['_id'], attachmentId: Asset['_id']) => {
    const res = await request({
      url: `/v2/tasks/${taskId}/attachments/${attachmentId}`,
      method: 'delete',
    });
    return res.data.data.task as TaskType;
  },
};

export default taskService;
