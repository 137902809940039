import React, { FC, useState } from 'react';
import workspaceService from '../../modules/workspace/workspace.service';
import { CreateFormValues } from '../CreateModal/createModal.types';
import CreateModalWrapper from '../CreateModal/CreateModalWrapper';

type Props = Pick<React.ComponentProps<typeof CreateModalWrapper>, 'show' | 'onClose'> & {
  orgId: string;
};

const CreateWorkspace: FC<Props> = ({ orgId, show, onClose }) => {
  const [generalError, setGeneralError] = useState<string | null>(null);

  const handleCreateWorkspaceFormSubmit = async ({ name, description, img }: CreateFormValues) => {
    try {
      const workspace = await workspaceService.createWorkspace({
        name,
        description,
        orgId,
      });
      await workspaceService.updateWorkspaceImg(workspace._id, img);
    } catch (error) {
      setGeneralError('Create Workspace Failed');
    }
  };
  return (
    <CreateModalWrapper
      isWithoutShortName
      isWithAvatar={false}
      show={show}
      onClose={onClose}
      type="WORKSPACE"
      imageAlt="Organization Avatar"
      onFormSubmit={handleCreateWorkspaceFormSubmit}
      submitError={generalError}
    />
  );
};

export default CreateWorkspace;
