import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUp } from '@fortawesome/free-solid-svg-icons';
import UpgradeButton from '../OrgBilling/UpgradeButton';

type Props = {
  onClick: () => void;
  orgId: string;
  canCreatePrograms?: boolean;
};

const CreateProgramDropdownItem: React.FC<Props> = ({ onClick, orgId, canCreatePrograms }) => {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Dropdown.Item onClick={onClick} disabled={!canCreatePrograms}>
        {intl.formatMessage({
          id: 'HUB.ORG_HEADER.DROPDOWN.CREATE_PROGRAM',
        })}
      </Dropdown.Item>
      {!canCreatePrograms && (
        <OverlayTrigger
          delay={{ show: 250, hide: 1200 }}
          overlay={
            <Popover style={{ minWidth: 300 }}>
              <Popover.Body className="d-flex align-items-center justify-content-center gap-2">
                <p className="m-0">
                  {intl.formatMessage({
                    id: 'Power your team with new features.',
                  })}
                </p>
                <UpgradeButton orgId={orgId} />
              </Popover.Body>
            </Popover>
          }
        >
          <FontAwesomeIcon icon={faCircleUp} size="lg" className="text-success me-2" />
        </OverlayTrigger>
      )}
    </div>
  );
};

export default CreateProgramDropdownItem;
