import React from 'react';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { MinimalUserModel } from '../../modules/auth/models/UserModel';
import RichTextEditor from '../richTextEditor/RichTextEditor';

type Props = Pick<React.ComponentProps<typeof RichTextEditor>, 'onChange'> & {
  isLoading: boolean;
  description: string;
  users: MinimalUserModel[];
  tabIndex?: number;
};

const EditableTaskDescription: React.FC<Props> = ({ isLoading, description, onChange, users, tabIndex }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Label>
        {intl.formatMessage({
          id: 'Description',
        })}
      </Form.Label>
      <RichTextEditor
        value={description}
        mentionOptions={users.map((user: MinimalUserModel) => ({
          id: user._id,
          value: user.firstname,
        }))}
        placeholder={intl.formatMessage({ id: 'Description' })}
        onChange={onChange}
        isLoading={isLoading}
        className="form-control form-control-solid mb-3 mb-lg-2"
        tabIndex={tabIndex}
      />
    </>
  );
};

export default EditableTaskDescription;
