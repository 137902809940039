import React, { FC, ReactElement, useMemo, useState } from 'react';
import { OverlayTriggerProps } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import BloomAvatar, { avatarSizeMap } from '../bloomAvatar/BloomAvatar';
import './style.scss';
import { BloomAvatarProps } from '../bloomAvatar/BloomAvatar.types';

type WrapperProps = {
  to?: string;
  children?: React.ReactNode;
};

const WrapperComponent: React.FC<WrapperProps> = ({ to, children }) => {
  const { url } = useRouteMatch();

  if (to) {
    return (
      <Link
        to={{
          pathname: to,
          state: { referrerPage: url },
        }}
        className="cursor-pointer"
      >
        {children}
      </Link>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export interface AvatarItem {
  _id: string;
  title: string;
  imageLink?: string;
  to?: string;

  tooltipTrigger?: OverlayTriggerProps['trigger'];
  tooltipHeader?: string | ReactElement;
  tooltipBody?: string | ReactElement;
  onClick?: () => void;
  avatarStatus?: BloomAvatarProps['avatarStatus'];
}

type Props = {
  list: Array<AvatarItem>;
  size?: keyof typeof avatarSizeMap;
  maxAvatarsToShow: number;
};

const AvatarsList: FC<Props> = ({ list, size = 'xs', maxAvatarsToShow = 6 }) => {
  const [showFullList, setShowFullList] = useState(false);

  const avatarsCount = list.length;
  const slicedAvatars = useMemo(
    () => (showFullList ? list : list.slice(0, maxAvatarsToShow)),
    [list, maxAvatarsToShow, showFullList]
  );

  return (
    <div className="d-flex ms-5 flex-wrap">
      {slicedAvatars.map((item) => {
        return (
          <div key={item._id} className="avatar-list-item">
            <WrapperComponent to={item.to}>
              <BloomAvatar
                name={item.title}
                imagePath={item.imageLink}
                size={size}
                rounded
                tooltipTrigger={item.tooltipTrigger}
                tooltipHeader={item.tooltipHeader}
                tooltipBody={item.tooltipBody}
                avatarStatus={item.avatarStatus}
                onClick={() => item.onClick && item.onClick()}
              />
            </WrapperComponent>
          </div>
        );
      })}
      {avatarsCount > maxAvatarsToShow && (
        <div className="avatar-list-item">
          <BloomAvatar
            name={`${showFullList ? '-' : '+'}${avatarsCount - maxAvatarsToShow}`}
            shouldAbbreviateName={false}
            disableTooltip
            size={size}
            rounded
            color="#F5F8FA"
            textColor="#8193A4"
            onClick={() => setShowFullList((prevState) => !prevState)}
          />
        </div>
      )}
    </div>
  );
};

export default AvatarsList;
