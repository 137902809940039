import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import CreateOrgBtn from './CreateOrgBtn/CreateOrgBtn';
import CreateTasksBtn from './CreateTasksBtn/CreateTasksBtn';
import FilterTasksBtn from './FilterTasksBtn/FilterTasksBtn';
import TakeAssessmentBtn from './TakeAssessmentBtn/TakeAssessmentBtn';
import { RootState } from '../../../../../setup';
import TaskboardSearch from './TaskboardSearch/TaskboardSearch';

const actionsComponentsMap: { [key: string]: React.ReactElement } = {
  createTasksBtn: <CreateTasksBtn key="createTasksBtn" />,
  filterTasksBtn: <FilterTasksBtn key="filterTasksBtn" />,
  takeAssessmentBtn: <TakeAssessmentBtn key="takeAssessmentBtn" />,
  createOrg: <CreateOrgBtn key="createOrgBtn" />,
  taskboardSearch: <TaskboardSearch key="taskboardSearch" />,
};

type ToolbarActionsProps = {};

const ToolbarActions: React.FC<ToolbarActionsProps> = () => {
  const { toolbar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (toolbar.actionButtons.length <= 0) return <></>;

  return (
    <div className="d-flex align-items-center py-1">
      {toolbar.actionButtons.map((action) => actionsComponentsMap[action])}
    </div>
  );
};

export default ToolbarActions;
