import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Accordion } from 'react-bootstrap';
import FilterItem from './FilterItem';
import { AppliedFilters, FilterObject, TaskboardFilterKeys } from './Filter.types';

type Props = {
  filters: FilterObject[];
  appliedFilters: AppliedFilters;
  appliedFiltersCount?: number;
  onApplyFilters?: () => void;
  onClearAllFilters: () => void;
  onOptionClick: (_optionId: string, _filterId: TaskboardFilterKeys) => void;
};

const FilterContainer: React.FC<Props> = ({
  filters,
  appliedFilters,
  onApplyFilters,
  onClearAllFilters,
  onOptionClick,
  appliedFiltersCount,
}) => {
  const intl = useIntl();
  const hasNoFiltersSelected = Object.values(appliedFilters).every((filter) => Object.values(filter).length === 0);

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <p className="m-0">{intl.formatMessage({ id: 'TASK_FILTERS.FILTER_OPTIONS' })}</p>
        <Button
          variant="transparent"
          size="sm"
          className="p-0"
          disabled={hasNoFiltersSelected}
          onClick={onClearAllFilters}
        >
          {intl.formatMessage({ id: 'TASK_FILTERS.CLEAR_ALL' })}
          <span>{` (${appliedFiltersCount || 0})`}</span>
          <i className="bi bi-x" />
        </Button>
      </div>
      <hr />
      <Accordion flush>
        {filters.map((filter) => (
          <FilterItem
            key={filter.id}
            id={filter.id}
            title={filter.title}
            allowMultiSelect={filter.allowMultiSelect}
            appliedFilters={appliedFilters[filter.id]}
            options={filter.options}
            onOptionClick={onOptionClick}
          />
        ))}
      </Accordion>
      <Button onClick={onApplyFilters} size="sm" className="mt-4">
        {intl.formatMessage({ id: 'TASK_FILTERS.APPLY_FILTERS' })}
      </Button>
    </>
  );
};

export default FilterContainer;
