import React, { forwardRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CalendarIcon from './CalendarIcon';

import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = forwardRef(
  (
    {
      value,
      onClick,
      withoutIcon,
      isClearable,
      disabled,
      tabIndex,
    }: {
      value?: string;
      onClick?: () => {};
      withoutIcon?: boolean;
      isClearable?: boolean;
      disabled?: boolean;
      tabIndex?: number;
    },
    ref: any
  ) => (
    <InputGroup>
      <Form.Control
        autoComplete="off"
        type="text"
        name="datepicker-input"
        defaultValue={value}
        onClick={onClick}
        onFocus={onClick}
        onKeyDown={(e) => {
          if (e.key !== 'Tab') {
            e.preventDefault();
          }
        }}
        disabled={disabled}
        ref={ref}
        tabIndex={tabIndex}
      />
      <CalendarIcon withoutIcon={withoutIcon} isClearable={isClearable} value={value} />
    </InputGroup>
  )
);

type Props = {
  date?: Date;
  onChange: (_date: Date) => void;
  readOnly?: boolean;
  withoutIcon?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  tabIndex?: number;
};

const BloomDatePicker: React.FC<Props> = ({
  date,
  onChange,
  readOnly,
  withoutIcon,
  isClearable,
  disabled,
  tabIndex,
}) => (
  <DatePicker
    selected={date}
    onChange={onChange}
    disabled={disabled}
    customInput={
      <CustomInput disabled={disabled} withoutIcon={withoutIcon} isClearable={isClearable} tabIndex={tabIndex} />
    }
    readOnly={readOnly}
    isClearable={!disabled && isClearable}
    tabIndex={tabIndex}
  />
);

export default BloomDatePicker;
