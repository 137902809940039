import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { UserModel } from '../auth/models/UserModel';
import { TourSlug } from '../../components/productTour/productTour.types';

interface UserState extends UserModel {
  isLoggedIn?: boolean;
}

const initialState: UserState = {
  _id: '',
  username: '',
  email: '',
  firstname: '',
  lastname: '',
  fullname: '',
  images: {},
  timeZone: '',
  orgs: [],
  locale: '',
  discourseUser: '',
  isLoggedIn: false,
  isClosed: false,
  productTours: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfileImage(state: UserState, action: PayloadAction<{ link?: string }>) {
      return {
        ...state,
        images: {
          ...state?.images,
          profile: {
            ...state?.images?.profile,
            link: action.payload.link || '',
          },
        },
      };
    },
    setUserFirstName(state: UserState, action: PayloadAction<{ firstName: string }>) {
      return {
        ...state,
        firstname: action.payload.firstName,
      };
    },
    updateOrg(
      state: UserState,
      action: PayloadAction<{
        orgId: string;
        updatedOrgValues: Partial<UserState['orgs'][0]>;
      }>
    ) {
      const orgs = cloneDeep(state.orgs);
      const orgIdx = orgs.findIndex((org) => org._id === action.payload.orgId);
      orgs[orgIdx] = {
        ...orgs[orgIdx],
        ...action.payload.updatedOrgValues,
      };
      return {
        ...state,
        orgs,
      };
    },
    setProductTourIsDone(state: UserState, action: PayloadAction<{ productTourSlug?: TourSlug }>) {
      const tours = cloneDeep(state.productTours);
      const ptIdx = tours.findIndex((pt) => pt.slug === action.payload.productTourSlug);
      tours[ptIdx] = {
        ...tours[ptIdx],
        isDone: true,
      };
      return {
        ...state,
        productTours: tours,
      };
    },
    setUser(state: UserState, action: PayloadAction<{ user: UserModel }>) {
      return {
        ...state,
        isLoggedIn: true,
        ...action.payload.user,
      };
    },
    resetUser() {
      return {
        ...initialState,
      };
    },
    removeOrgFromUserOrgs(state: UserState, action: PayloadAction<{ orgId: string }>) {
      const orgs = cloneDeep(state?.orgs);
      return {
        ...state,
        orgs: orgs.filter((org) => org._id !== action.payload.orgId),
      };
    },
    openUserAccount(state: UserState) {
      return {
        ...state,
        isClosed: false,
      };
    },
    closeUserAccount(state: UserState) {
      return {
        ...state,
        isClosed: true,
      };
    },
  },
});

export const {
  setUserProfileImage,
  setUserFirstName,
  updateOrg,
  setProductTourIsDone,
  setUser,
  resetUser,
  removeOrgFromUserOrgs,
  openUserAccount,
  closeUserAccount,
} = slice.actions;

export default slice.reducer;
