import { Quill } from 'react-quill';
import * as Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';

const useEmoji = () => {
  Quill.register('modules/emoji', Emoji, true);

  return {
    modules: {
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true,
    },
  };
};

export default useEmoji;
