import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import CreateModal from './CreateModal';
import CreateForm from './CreateForm';
import { BloomSpace, CreateFormValues } from './createModal.types';
import orgService from '../../modules/org/OrgService';

type IProps = {
  show: boolean;
  onClose: () => void;
  type: BloomSpace;
  imageAlt: string;
  onFormSubmit: (_a: CreateFormValues) => void;
  isWithoutShortName?: boolean;
  isWithAvatar?: boolean;
  submitError?: string | null;
};

const titlesTypeMap: {
  [key: string]: {
    title: string;
    discard: string;
    submit: string;
  };
} = {
  TEAM: {
    title: 'Create Team',
    discard: 'CREATE_MODAL.ORG_DISCARD',
    submit: 'Create Team',
  },
  PROGRAM: {
    title: 'CREATE_MODAL.PROGRAM_TITLE',
    discard: 'CREATE_MODAL.PROGRAM_DISCARD',
    submit: 'CREATE_MODAL.PROGRAM_SUBMIT',
  },
  WORKSPACE: {
    title: 'CREATE_MODAL.WORKSPACE_TITLE',
    discard: 'CREATE_MODAL.WORKSPACE_DISCARD',
    submit: 'CREATE_MODAL.WORKSPACE_SUBMIT',
  },
};

export const CreateModalWrapper: React.FC<IProps> = ({
  show,
  onClose,
  type,
  imageAlt,
  onFormSubmit,
  isWithoutShortName,
  isWithAvatar,
  submitError = null,
}) => {
  const intl = useIntl();

  const [img, setImg] = useState('');
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [validatedShortName, setValidatedShortName] = useState({
    value: '',
    isValid: false,
  });

  const titlesIds = titlesTypeMap[type];

  const CreateOrgSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
    shortName: isWithoutShortName
      ? Yup.string()
      : Yup.string()
          .required(intl.formatMessage({ id: 'SHORT_NAME_REQUIRED' }))
          .matches(/^[a-z0-9]([a-z0-9-]{3,12})[a-z0-9]$/, intl.formatMessage({ id: 'INVALID_SHORT_NAME' }))
          .test('Is Taken', intl.formatMessage({ id: 'SHORT_NAME_ALREADY_TAKEN' }), async (newShortName) => {
            if (newShortName === validatedShortName.value) return validatedShortName.isValid;
            try {
              const isValid = await orgService.isShortNameValid(newShortName);
              setValidatedShortName({
                value: newShortName as string,
                isValid,
              });
              return isValid;
            } catch (error) {
              return false;
            }
          }),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      shortName: '',
    },
    validationSchema: CreateOrgSchema,
    onSubmit: async (values) => {
      try {
        await onFormSubmit({ ...values, img });
        onClose();
        window.location.reload();
      } catch (error: any) {
        setGeneralError(`Failed to create due to ${error.message}`);
        formik.setSubmitting(false);
      }
    },
  });

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    formik.submitForm();
  };

  return (
    <CreateModal
      show={show}
      title={intl.formatMessage({ id: titlesIds.title })}
      discardText={intl.formatMessage({ id: titlesIds.discard })}
      submitText={intl.formatMessage({ id: titlesIds.submit })}
      onHide={onClose}
      onCancel={onClose}
      onSubmit={handleSubmit}
      isSubmitting={formik.isSubmitting}
      isValid={formik.isValid}
      isDirty={formik.dirty}
      generalError={generalError || submitError}
    >
      <CreateForm
        formik={formik}
        onSubmitImg={setImg}
        imageAlt={imageAlt}
        isWithoutShortName={isWithoutShortName}
        isWithAvatar={isWithAvatar}
      />
    </CreateModal>
  );
};

export default CreateModalWrapper;
