import { WorkspaceType } from '../../modules/org/org.types';

const assetsPath = process.env.REACT_APP_ASSETS_PATH || 'https://assets-test.bloom.pm/';

const BloomAvatarService = {
  getImageLink: (imagePath: string | null | undefined) => {
    if (!imagePath) {
      return undefined;
    }
    return `${assetsPath}${imagePath}`;
  },
  parseWorkspaceAvatar: (avatars: WorkspaceType['avatars']) => {
    if (!avatars) return [];

    if (avatars.length === 1) {
      return [
        {
          imagePath: avatars?.[0].logo?.link,
          name: avatars?.[0]?.name,
          size: 'sm' as const,
        },
      ];
    }
    return avatars.map((a) => ({
      imagePath: a.logo?.link,
      name: a.name,
      size: 'xs' as const,
    }));
  },
};

export default BloomAvatarService;
