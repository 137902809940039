import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { shallowEqual, useSelector } from 'react-redux';
import DeleteOrg from '../../../components/DeleteOrg';
import { OrgModel } from '../../../modules/org/org.types';
import useLayoutDispatcher from '../../../layout/hooks/useLayoutDispatcher';
import { RootState } from '../../../../setup';

type Props = {
  parentOrgId: OrgModel['_id'];
  programId: OrgModel['_id'];
  programName: OrgModel['name'];
  isProgramOwner: boolean;
  menuTitle?: string;
  dataName?: string;
  onDropDownToggle?: (_nextShow: boolean) => void;
};

const ProgramDropdownMenu: React.FC<Props> = ({
  programId,
  parentOrgId,
  programName,
  isProgramOwner,
  menuTitle,
  dataName,
  onDropDownToggle,
}) => {
  const intl = useIntl();

  const { toggleSidebarState } = useLayoutDispatcher();

  const { sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const onMenuItemClick = () => {
    if (sidebar.isDrawer && sidebar.isSidebarOpen) toggleSidebarState();
  };

  return (
    <Dropdown onToggle={onDropDownToggle}>
      <Dropdown.Toggle data-name={dataName} size="sm" variant="transparent" className="py-0 ps-1 pe-1">
        {menuTitle && <span className="me-2">{menuTitle}</span>}
        <FontAwesomeIcon size="xl" icon={faEllipsis} className="text-muted" />
      </Dropdown.Toggle>
      <Dropdown.Menu flip>
        <Dropdown.Item as={Link} to={`/teams/${parentOrgId}/programs/${programId}/settings`} onClick={onMenuItemClick}>
          {intl.formatMessage({
            id: 'Program Settings',
          })}
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to={`/teams/${parentOrgId}/programs/${programId}/people-and-teams`}
          onClick={onMenuItemClick}
        >
          {intl.formatMessage({
            id: 'INVITE_PROGRAM_MANAGERS',
          })}
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to={`/teams/${parentOrgId}/programs/${programId}/people-and-teams`}
          onClick={onMenuItemClick}
        >
          {intl.formatMessage({
            id: 'INVITE_TEAMS',
          })}
        </Dropdown.Item>
        {isProgramOwner && <DeleteOrg renderAs="menu item" orgId={programId} orgName={programName} orgType="PROGRAM" />}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProgramDropdownMenu;
