/* eslint-disable max-classes-per-file */
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import { getMentionIds } from './useMention';

export interface RichTextEditorContentable {
  isUploading: boolean;
  htmlContent: string;
  rawText: string;
  cleanHtml: string;
  mentionIds: string[];
}

export class RichTextEditorSimpleContent implements RichTextEditorContentable {
  private readonly html: string;

  public readonly isUploading = false;

  constructor(html?: string) {
    this.html = html || '';
  }

  get htmlContent() {
    return this.html;
  }

  get rawText() {
    return this.htmlContent;
  }

  // eslint-disable-next-line class-methods-use-this
  get mentionIds() {
    return [];
  }

  get cleanHtml() {
    return this.htmlContent;
  }
}

export default class RichTextEditorContent implements RichTextEditorContentable {
  private readonly editor?: UnprivilegedEditor | null;

  private readonly reactQuill?: ReactQuill | null;

  public readonly isUploading: boolean;

  constructor(reactQuill?: ReactQuill | null, isUploading?: boolean) {
    this.reactQuill = reactQuill;
    this.editor = reactQuill?.editor ? reactQuill.makeUnprivilegedEditor(reactQuill.editor) : null;
    this.isUploading = isUploading || false;
  }

  get htmlContent() {
    if (!this.editor) return '';

    return this.editor.getHTML();
  }

  get rawText() {
    if (!this.editor) return '';

    return this.editor.getText().trim();
  }

  get mentionIds() {
    if (!this.editor) return [];

    return getMentionIds(this.editor.getContents());
  }

  get cleanHtml() {
    if (!this.reactQuill?.editor) return '';

    const clonedRoot = this.reactQuill.editor.root.cloneNode(true) as HTMLElement;
    // These are containers for the images that did not get uploaded and in
    // this case they have a spinner loading that will go forever and a base64
    // image that we don't want to send to the backend.
    const loadingContainersForUploadingImages = clonedRoot.querySelectorAll('.image-uploading');
    Array.from(loadingContainersForUploadingImages).forEach((el) => el.remove());

    // checking if there is any other base46 images and removing them
    const imageList = clonedRoot.querySelectorAll('img');
    Array.from(imageList)
      .filter((el) => el.src.startsWith('data:image'))
      .forEach((el) => el.remove());

    return clonedRoot.innerHTML;
  }
}
