import { FC } from 'react';

import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../shared/helperFunctions';

const LOGO = '/media/logos/bloom_beta_logo.png';
const LOGO_SMALL = '/media/logos/bloom_sunflower.png';

type IProps = {
  className?: string;
  onClick?: () => void;
  isSmall?: boolean;
};

const Logo: FC<IProps> = ({ className, onClick, isSmall = false }) => {
  return (
    <Link to="/hub" className={className} onClick={onClick}>
      <img alt="Logo" className="h-45px mt-2 ms-7" src={toAbsoluteUrl(isSmall ? LOGO_SMALL : LOGO)} />
    </Link>
  );
};

export default Logo;
