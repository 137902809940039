import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../shared/helperFunctions';

type Props = {
  className?: string;
  path: string;
  badge?: number | string;
  svgClassName?: string;
};

const KTSVG: React.FC<Props> = ({ className = '', path, svgClassName = 'mh-50px', badge }) => {
  return (
    <span className={`svg-icon ${className} position-relative`}>
      {Boolean(badge) && (
        <div className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fs-9">
          {badge}
        </div>
      )}
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  );
};

export default KTSVG;
