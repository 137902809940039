import { request } from '../../shared/http.service';
import { UserModel } from '../auth/models/UserModel';

const userService = {
  getUserSharedWorkspaceWithProgram: async (programShortName: string) => {
    const res = await request({ url: `/v2/users/program/${programShortName}/shared-workspace` });
    return res?.data?.data?.workspace?._id as string;
  },
  removeUserProfileImage: async () => {
    const res = await request({ url: '/v2/users/setting/deletepicture', method: 'delete' });
    return res.data.data.user as UserModel;
  },
  updateUserProfileImage: async (img: any) => {
    const res = await request({
      url: '/v2/users/setting/changepicture',
      data: { file: img },
      method: 'post',
    });
    return res.data.data.imagePath as string;
  },
  updateUserFirstName: async (data: string) => {
    const res = await request({
      url: '/v2/users/setting/changename',
      data: {
        firstname: data,
        lastname: '',
      },
      method: 'put',
      noHeaders: false,
    });
    return res.data.data as UserModel;
  },

  setNewPasswordCall: async (data: any, email: any) => {
    const res = await request({
      url: '/v2/users/setting/changepassword',
      data: {
        email,
        newpassword: data.newPassword,
        password: data.currentPassword,
      },
      method: 'put',
      noHeaders: false,
      params: null,
    });
    return res.data.data as UserModel;
  },

  getUserProfile: async (userId: string) => {
    const res = await request({ url: `/v2/users/profile/${userId}`, method: 'get' });
    return res;
  },

  callVerifyMe: async () => {
    try {
      const {
        data: {
          data: { user },
        },
      } = await request({ url: '/v2/users/me', method: 'get' });
      return user.data;
    } catch (err: any) {
      throw new Error(err.response);
    }
  },

  closeUserAccount: async (userId: string) => {
    await request({ url: `/v2/users/${userId}/close`, method: 'put' });
  },

  openUserAccount: async (userId: string) => {
    await request({ url: `/v2/users/${userId}/open`, method: 'put' });
  },

  logout: () => request({ url: '/v2/users/logout' }),
};

export default userService;
