import { ValueOf } from '../shared/types';
import constants from '../shared/consts';

export const taskboardPathname = (workspaceId: string, orgId: string, programId?: string) =>
  `/teams/${orgId}${programId ? `/programs/${programId}` : ''}/workspaces/${workspaceId}/taskboard`;

export const getNotificationsPathname = ({ orgId, parentOrgId }: { orgId: string; parentOrgId?: string }) => {
  if (parentOrgId) return `/teams/${parentOrgId}/programs/${orgId}/notifications`;
  return `/teams/${orgId}/notifications`;
};

export const getWorkspacePathname = ({
  orgId,
  workspaceId,
  programId,
}: {
  workspaceId: string;
  orgId: string;
  programId?: string;
}) => {
  return `/teams/${orgId}/${programId ? `programs/${programId}/` : ''}workspaces/${workspaceId}`;
};

export const growthPagePathname = ({
  orgId,
  workspaceId,
  programId,
}: {
  orgId: string;
  workspaceId: string;
  programId?: string;
}) => `${getWorkspacePathname({ orgId, workspaceId, programId })}/growth`;

export const libraryPagePathname = ({
  orgId,
  programId,
  workspaceId,
}: {
  orgId?: string;
  programId?: string;
  workspaceId?: string;
}) => {
  if (!orgId || !workspaceId) return '/library';
  return `${getWorkspacePathname({ orgId, workspaceId, programId })}/library`;
};

export const takeAssessmentPagePathname = ({
  orgId,
  programId,
  workspaceId,
  assessmentShortName,
}: {
  orgId: string;
  programId?: string;
  workspaceId: string;
  assessmentShortName: string;
}) => {
  return `${getWorkspacePathname({ orgId, workspaceId, programId })}/take-assessment/${assessmentShortName}`;
};

export const growthSummaryPagePathname = (orgId: string) => `/teams/${orgId}/team-activities`;

export const formSubmissionPathname = (formSubmissionId: string) => `/form-submission/${formSubmissionId}`;

export const teamActionsSummaryPathname = (orgId: string, summaryType: ValueOf<typeof constants.summaryType>) =>
  `/teams/${orgId}/summary/${summaryType}/team-actions`;
