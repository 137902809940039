import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { captureException } from '@sentry/react';
import useSubscription from '../../pubsub/useSubscription';
import { incrementUnreadNotificationsCount } from '../notifications.slice';

const TOPIC_PREFIX = 'notification-';

const useNotificationsSubscriber = (loggedInUserId: string) => {
  const dispatch = useDispatch();

  const notificationTopic = `${TOPIC_PREFIX}${loggedInUserId}`;

  const { message } = useSubscription(notificationTopic);

  useEffect(() => {
    const onMessageReceive = (topic: string, data: any) => {
      if (notificationTopic !== topic) return;

      try {
        const parsedData = JSON.parse(data);
        if (parsedData.type !== 'notification/new') return;

        const { workspaceId } = parsedData.data;

        dispatch(incrementUnreadNotificationsCount({ workspaceId }));
      } catch (err: any) {
        captureException(err);
      }
    };

    if (!message) return;

    onMessageReceive(message.topic, message.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
};

export default useNotificationsSubscriber;
