import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { breakPoints, WindowSizeType } from './dimensions.types';

type dimensions = { width: number; screenSize: WindowSizeType };

const getWindowSizeType = (width: number) => {
  if (width >= breakPoints.xxl) {
    return 'xxl';
  }
  if (width >= breakPoints.xl) {
    return 'xl';
  }
  if (width >= breakPoints.lg) {
    return 'lg';
  }
  if (width >= breakPoints.md) {
    return 'md';
  }
  if (width >= breakPoints.sm) {
    return 'sm';
  }
  return 'xs';
};

const initialState: dimensions = {
  width: window.innerWidth,
  screenSize: getWindowSizeType(window.innerWidth),
};

const slice = createSlice({
  name: 'dimensions',
  initialState,
  reducers: {
    setWidth(state: dimensions, action: PayloadAction<dimensions['width']>) {
      const windowSizeType = getWindowSizeType(action.payload);
      return { ...state, width: action.payload, screenSize: windowSizeType };
    },
  },
});

export const { setWidth } = slice.actions;

export default slice.reducer;
