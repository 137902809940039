const constants = {
  taskboardListKeys: {
    BACKLOG: 'Backlog',
    TODO: 'To Do',
    IN_PROGRESS: 'In Progress',
    IN_REVIEW: 'In Review',
    DONE: 'Done',
  },
  navTypes: {
    TASKBOARD: 'taskboard',
    TEAM_ACTIONS: 'team-actions',
    ARCHIVE: 'archive',
    TASKS_ARCHIVE: 'archive/tasks',
    NOTIFICATIONS: 'notifications',
    LEARN: 'learn',
    COMMUNITY: 'community',
  },
  summaryType: {
    ALL_SUMMARY: 'all',
    TEAMS_SUMMARY: 'teams',
    OWN_SUMMARY: 'own',
  },
  workspacePages: {
    taskboard: 'taskboard',
    growth: 'growth',
    library: 'library',
    community: 'community',
    teamActions: 'team-actions',
    archive: 'archive',
    notifications: 'notifications',
    takeAssessment: 'take-assessment',
    programActivities: 'program-activities',
    peopleAndTeams: 'people-and-teams',
    settings: 'settings',
  },
  routs: {
    root: '/',
    hub: '/hub',
    library: '/library',
    libraryItem: '/library/:activityShortCode',
    libraryItemEdit: '/library/:activityShortCode/edit',
    libraryItemCreate: '/library/new',

    community: '/community',

    logout: '/logout',
    login: '/auth/login',
    discourseLogin: '/auth/discourse',
    signup: '/auth/signup',
    register: '/auth/register',
    emailConfirmation: '/auth/email-confirmation',
    joinProgram: '/auth/join-program/:programCode',
    changePassword: '/auth/change-password',
    forgotPassword: '/auth/change-password-request',

    error404: '/error/404',
    error500: '/error/500',

    userProfile: '/users/:userId',

    teamsRoot: '/teams/:orgId',
    teamSettings: '/teams/:orgId/settings',
    teamNotifications: '/teams/:orgId/notifications',
    teamCommunity: '/teams/:orgId/community',
    teamActivities: '/teams/:orgId/team-activities',

    workspaceRoot: '/teams/:orgId/workspaces/:workspaceId/:workspacePage',
    workspaceSettings: '/teams/:orgId/workspaces/:workspaceId/settings',
    workspaceTaskboard: '/teams/:orgId/workspaces/:workspaceId/taskboard',
    workspaceTeamActions: '/teams/:orgId/workspaces/:workspaceId/team-actions',
    workspaceArchive: '/teams/:orgId/workspaces/:workspaceId/archive',
    workspaceCommunity: '/teams/:orgId/workspaces/:workspaceId/community',
    workspaceLibrary: '/teams/:orgId/workspaces/:workspaceId/library',
    workspaceLibraryItem: '/teams/:orgId/workspaces/:workspaceId/library/:activityShortCode',
    workspaceLibraryItemEdit: '/teams/:orgId/workspaces/:workspaceId/library/:activityShortCode/edit',
    workspaceLibraryItemCreate: '/teams/:orgId/workspaces/:workspaceId/library/new',

    workspaceTakeAssessment: '/teams/:orgId/workspaces/:workspaceId/take-assessment/:assessmentShortName',

    workspaceGrowth: '/teams/:orgId/workspaces/:workspaceId/growth',
    workspaceDDInstance: '/teams/:orgId/workspaces/:workspaceId/growth/ddi/:ddInstanceId',
    workspaceFormSubmission:
      '/teams/:orgId/workspaces/:workspaceId/growth/ddi/:ddInstanceId/form-submission/:formSubmissionId',

    programRoot: '/teams/:orgId/programs/:programId',
    programSettings: '/teams/:orgId/programs/:programId/settings',
    programNotifications: '/teams/:orgId/programs/:programId/notifications',
    programPeopleAndTeams: '/teams/:orgId/programs/:programId/people-and-teams',
    programActivities: '/teams/:orgId/programs/:programId/program-activities',
    programCommunity: '/teams/:orgId/programs/:programId/community',
    programActions: '/teams/:orgId/programs/:programId/program-actions',
    programCommunitySummary: '/teams/:orgId/programs/:programId/community-summary',
    programTakeAssessment: '/teams/:orgId/programs/:programId/take-assessment/:assessmentSlug',

    programWorkspaceSettings: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/settings',
    programWorkspaceRoot: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/:workspacePage',
    programWorkspaceTaskboard: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/taskboard',
    programWorkspaceCommunity: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/community',
    programWorkspaceTeamActions: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/team-actions',
    programWorkspaceArchive: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/archive',
    programWorkspaceNotifications: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/notifications',

    programWorkspaceGrowth: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/growth',
    programWorkspaceLibrary: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/library',
    programWorkspaceLibraryItem: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/library/:activityShortCode',
    programWorkspaceLibraryItemEdit:
      '/teams/:orgId/programs/:programId/workspaces/:workspaceId/library/:activityShortCode/edit',
    programWorkspaceLibraryItemCreate: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/library/new',
    programWorkspaceDDInstance: '/teams/:orgId/programs/:programId/workspaces/:workspaceId/growth/ddi/:ddInstanceId',
    programWorkspaceFormSubmission:
      '/teams/:orgId/programs/:programId/workspaces/:workspaceId/growth/ddi/:ddInstanceId/form-submission/:formSubmissionId',

    programWorkspaceTakeAssessment:
      '/teams/:orgId/programs/:programId/workspaces/:workspaceId/take-assessment/:assessmentShortName',
  },
  path: {
    ROOT: '/',
    HUB: '/hub',
    EDITOR: '/editor',
    COMMUNITY: '/community',
    USER_PROFILE: '/users/:userId',
    ORG_PROFILE: '/teams/:orgId',
    WORKSPACE_PROFILE: '/workspace/:workspaceId',
    SUMMARY_ROOT: '/teams/:orgId/summary/:summaryType',
    SUMMARY_PAGES: '/teams/:orgId/summary/:summaryType/:navType',
    WORKSPACE: '/workspaces/:workspaceId',
    WORKSPACE_PAGES: '/workspaces/:workspaceId/:navType',
    PROGRAM_PAGES: '/programs/:programId',
    AUTH: '/auth',
    ERROR_404: '/error/404',
  },
  dateFormatString: 'MMM DD, YYYY',
  dueDateFormatString: 'YYYY-MM-DD',
  orgTypes: {
    TEAM: 'TEAM',
    PROGRAM: 'PROGRAM',
  },
  workspaceTypes: {
    PRIVATE: 'Private',
    SHARED: 'Shared',
  },
  userRoles: {
    OWNER: 'OWNER',
    USER: 'USER',
  },
  HEADER_HEIGHT: 70,
  uploadFilesBy: {
    workspace: 'workspace',
    org: 'org',
  },
  createTaskForActivities: {
    DEFAULT_LABEL_TITLE: 'Suggested Activity',
  },
  LOCALES: [
    { title: 'English', value: 'en' },
    { title: 'العربية', value: 'ar' },
  ],
  INVITATION_TYPES: {
    NEW_USER_SIGNUP: {
      slug: 'NEW_USER_SIGNUP',
      codePrefix: 'pw-us-inv',
    },
    USER_JOIN_TEAM: {
      slug: 'USER_JOIN_TEAM',
      codePrefix: 'pw-st-inv',
    },
    TEAM_JOIN_PROGRAM: {
      slug: 'TEAM_JOIN_PROGRAM',
      codePrefix: 'pw-pg-inv',
    },
  },
  INVITATION_JOINING_AS: {
    INDIVIDUAL: 'INDIVIDUAL',
    TEAM: 'TEAM',
  },
  VALID_NAME_REGEX: /^(\w|[-\s])+$/,

  ONLY_NUMBERS_AND_LETTERS_REGEX: /[a-zA-Z0-9]/,
  taskCreationTypes: {
    TEAM_TASKS: 'TEAM_TASKS',
    PROGRAM_TASKS: 'PROGRAM_TASKS',
  },
  TASKBOARD_COLUMN_WIDTH: 300,
  SCORE_TYPE: {
    NUMBER: 'number',
    NA: 'NA',
  },
  DD: {
    TYPES: {
      INDIVIDUAL: 'INDIVIDUAL',
      TEAM: 'TEAM',
    },
    TAKEN_BY: {
      WORKSPACE: 'Workspace',
      USER: 'User',
    },
  },
  OPEN_COMMUNITY_PATH: process.env.OPEN_COMMUNITY_PATH || 'c/bloom-oc/308/none',
  CALENDAR_PATH: 'calendar',
  EMBEDS_HOST: process.env.REACT_APP_EMBEDS_HOST || 'https://library.bloom.pm',
  FEEDBACK_PAGE_LINK: 'https://www.bloom.pm/feedback',
  HELP_LINK: 'https://bloom.pm/help',
} as const;

export default constants;
