import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CirclePicker } from 'react-color';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { LabelType } from '../taskboard/Taskboard.types';
import constants from './constants';

type Props = {
  label?: LabelType;
  onCreateLabel: (_name: string, _color: string) => void;
  onEditLabel: (_labelId: string, _name: string, _color: string) => void;
  onDeleteLabel: (_id: string) => void;
  onCancel: () => void;
};

const LabelAddEdit: React.FC<Props> = ({ label, onCreateLabel, onEditLabel, onDeleteLabel, onCancel }) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const labelSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'Label name is Required' })),
    color: Yup.string().required(intl.formatMessage({ id: 'Label color is Required' })),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      color: constants.labelsColors[0],
    },
    validationSchema: labelSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (label) {
        onEditLabel(label._id, values.name, values.color);
      } else {
        onCreateLabel(values.name, values.color);
      }
      setSubmitting(false);
      onCancel();
    },
  });

  useEffect(() => {
    formik.setValues({
      name: label?.name || '',
      color: label?.color || constants.labelsColors[0],
    });

    const timer = setTimeout(() => {
      inputRef?.current?.focus();
    }, 1000);

    return () => clearTimeout(timer);
  }, [label]);

  return (
    <div className="py-3">
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control
          type="text"
          name="name"
          ref={inputRef}
          autoComplete="off"
          aria-describedby="label-name"
          placeholder={intl.formatMessage({ id: 'Enter label name' })}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={formik.isSubmitting}
          isInvalid={formik.touched.name && !!formik.errors.name}
        />
      </Form.Group>
      <CirclePicker
        color={formik.values.color}
        colors={constants.labelsColors}
        onChange={(color) => {
          formik.setFieldValue('color', color.hex.toUpperCase());
        }}
        className="mx-auto"
      />
      <Form.Text className="text-danger my-3">{formik.errors.color}</Form.Text>
      <div className="d-flex justify-content-between mt-7 mx-1">
        <Button
          variant="secondary"
          type="button"
          size="sm"
          className="py-3 text-success"
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => formik.submitForm()}
        >
          <i className="bi bi-check2 text-success fs-1 p-0" />
          {intl.formatMessage({ id: 'Save' })}
        </Button>
        {label && (
          <Button
            variant="secondary"
            type="button"
            size="sm"
            className="py-3 text-danger"
            onClick={() => {
              onDeleteLabel(label._id);
              onCancel();
            }}
          >
            <i className="bi bi-trash text-danger fs-1 p-0" />
            {intl.formatMessage({ id: 'Delete' })}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LabelAddEdit;
