import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import unionBy from 'lodash/unionBy';
import moment from 'moment';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import constants from '../../shared/consts';
import { ValueOf } from '../../shared/types';
import {
  AppliedFilters,
  ListMapType,
  ListType,
  TaskboardFilterKeys,
  TaskboardFilters,
  TaskType,
} from './Taskboard.types';

interface TaskboardReducerState {
  _id?: string;
  doneListId?: string;
  lists?: ListType<TaskType>[];
  listsMap: ListMapType;
  listsOrder: string[];
  displayListsMap: ListMapType;
  isLoading: boolean;
  appliedFilters: TaskboardFilters;
  searchString: string;
  counts: {
    today: number;
    tomorrow: number;
    next_7_days: number;
    next_30_days: number;
    overdue: number;
    no_due_date: number;
  };
}

const initialState: TaskboardReducerState = {
  _id: '',
  doneListId: '',
  lists: [],
  listsMap: {},
  listsOrder: [],
  displayListsMap: {},
  isLoading: true,
  appliedFilters: {},
  searchString: '',
  counts: {
    today: 0,
    tomorrow: 0,
    next_7_days: 0,
    next_30_days: 0,
    overdue: 0,
    no_due_date: 0,
  },
};

const initialFilters = { assignedTo: {}, labels: {}, dueDate: {} };

const slice = createSlice({
  name: 'taskboard',
  initialState,
  reducers: {
    setTaskboardData(state: TaskboardReducerState, action: PayloadAction<Partial<TaskboardReducerState>>) {
      const clonedState = cloneDeep(state);
      const doneList = action.payload?.lists?.find(
        (list: ListType<TaskType>) => list.name === constants.taskboardListKeys.DONE
      );
      const listsMap: { [key: string]: ListType<TaskType> } = {};
      const listsOrder: Array<ListType<any>['_id']> = [];
      action.payload?.lists?.forEach((list) => {
        listsMap[list._id] = list;
        listsOrder.push(list._id);
      });
      const taskboardId = action.payload._id || clonedState._id;

      if (!clonedState.appliedFilters[taskboardId!]) {
        clonedState.appliedFilters[taskboardId!] = initialFilters;
      }
      return {
        ...clonedState,
        ...action.payload,
        doneListId: doneList?._id || clonedState.doneListId,
        listsMap,
        displayListsMap: listsMap,
        listsOrder,
        isLoading: false,
      };
    },
    resetTaskboard(state: TaskboardReducerState) {
      const clonedAppliedFilters = cloneDeep(state.appliedFilters);

      return {
        ...initialState,
        appliedFilters: clonedAppliedFilters,
      };
    },
    editCard(state: TaskboardReducerState, action: PayloadAction<{ listId: string; card: TaskType }>) {
      const clonedState = cloneDeep(state);
      const { listId } = action.payload;
      const taskIdx = findTaskIdxFor(clonedState, listId, action.payload.card._id);
      const clonedListsMap = cloneDeep(clonedState.listsMap);
      const list = clonedListsMap[listId];

      const newTaskDueDateState = checkTaskDueDateState(action.payload.card.duedate);
      const oldTaskDueDateState = checkTaskDueDateState(list.tasks[taskIdx].duedate);

      if (newTaskDueDateState !== oldTaskDueDateState) {
        if (newTaskDueDateState === 'today' || list.name !== 'Done') {
          clonedState.counts[newTaskDueDateState] += 1;
        }

        if (oldTaskDueDateState === 'today' || list.name !== 'Done') {
          clonedState.counts[oldTaskDueDateState] -= 1;
        }
      }

      list.tasks[taskIdx] = {
        ...list.tasks[taskIdx],
        ...action.payload.card,
      };

      return {
        ...clonedState,
        listsMap: clonedListsMap,
        displayListsMap: applyFiltersUtil(
          clonedListsMap,
          clonedState.appliedFilters[clonedState._id!],
          state.searchString
        ),
      };
    },
    modifyCommentsCount(
      state: TaskboardReducerState,
      action: PayloadAction<{
        listId: string;
        taskId: string;
        commentModification: TaskType['commentsCount'];
      }>
    ) {
      const clonedState = cloneDeep(state);
      const listId = action.payload.listId as string;
      const commentModification = action.payload.commentModification as TaskType['commentsCount'];
      const taskIdx = findTaskIdxFor(clonedState, listId, action.payload.taskId);
      const { listsMap } = clonedState;
      const list = listsMap?.[listId];
      if (taskIdx >= 0 && list && listsMap) {
        list.tasks[taskIdx].commentsCount.unresolved += commentModification.unresolved;
        list.tasks[taskIdx].commentsCount.all += commentModification.all;
        listsMap[listId] = list;
      }
      return {
        ...clonedState,
        displayListsMap: applyFiltersUtil(listsMap, clonedState.appliedFilters[clonedState._id!], state.searchString),
      };
    },
    removeCard(state: TaskboardReducerState, action: PayloadAction<{ listId: string; cardId: string }>) {
      const { listId, cardId } = action.payload;
      const clonedState = cloneDeep(state);

      const { task, listsMap } = removeCardFromList(clonedState, listId, cardId);
      const list = listsMap[listId];

      const taskDueDateState = checkTaskDueDateState(task.duedate);

      if (taskDueDateState === 'today' || list.name !== 'Done') {
        clonedState.counts[taskDueDateState] -= 1;
      }

      return {
        ...clonedState,
        listsMap,
        displayListsMap: applyFiltersUtil(listsMap, clonedState.appliedFilters[clonedState._id!], state.searchString),
      };
    },
    addCardsToList(state: TaskboardReducerState, action: PayloadAction<{ listId: string; cards: TaskType[] }>) {
      const { listId, cards } = action.payload;

      const clonedState = cloneDeep(state);
      const listsMap = addCardsToListUtil(clonedState, listId, cards);

      for (const card of cards) {
        const taskDueDateState = checkTaskDueDateState(card.duedate);
        clonedState.counts[taskDueDateState] += 1;
      }

      return {
        ...clonedState,
        listsMap,
        displayListsMap: applyFiltersUtil(listsMap, clonedState.appliedFilters[clonedState._id!], state.searchString),
      };
    },
    moveCardAcrossLists(
      state: TaskboardReducerState,
      action: PayloadAction<{
        cardId: string;
        sourceListId: string;
        targetListId: string;
        position: number;
      }>
    ) {
      const { cardId, sourceListId, targetListId, position } = action.payload;

      const clonedState = cloneDeep(state);
      const taskIdx = findTaskIdxFor(clonedState, sourceListId, cardId);
      if (taskIdx < 0) {
        return clonedState;
      }
      const { task, listsMap } = removeCardFromList(clonedState, sourceListId, cardId);

      listsMap[targetListId].tasks.splice(position, 0, clonedState.listsMap[sourceListId].tasks[taskIdx]);

      const taskDueDateState = checkTaskDueDateState(task.duedate);

      if (taskDueDateState !== 'today') {
        if (targetListId === clonedState.doneListId) {
          clonedState.counts[taskDueDateState] -= 1;
        }
        if (sourceListId === clonedState.doneListId) {
          clonedState.counts[taskDueDateState] += 1;
        }
      }

      return {
        ...clonedState,
        listsMap,
        displayListsMap: applyFiltersUtil(listsMap, clonedState.appliedFilters[clonedState._id!], state.searchString),
      };
    },
    setFilters(
      state: TaskboardReducerState,
      action: PayloadAction<{
        optionId: string;
        filterId: TaskboardFilterKeys;
      }>
    ) {
      const clonedState = cloneDeep(state);
      const taskboardFilters = clonedState.appliedFilters[clonedState._id!];
      const filter = taskboardFilters[action.payload.filterId];

      if (filter && action.payload.optionId in filter) {
        delete filter[action.payload.optionId];
      } else {
        filter[action.payload.optionId] = action.payload.optionId;
      }

      return clonedState;
    },
    setSearchString(
      state: TaskboardReducerState,
      action: PayloadAction<{
        searchString: string;
      }>
    ) {
      const clonedState = cloneDeep(state);
      clonedState.searchString = action.payload.searchString;

      return clonedState;
    },
    resetFilters(state: TaskboardReducerState) {
      const clonedState = cloneDeep(state);
      clonedState.appliedFilters[clonedState._id!] = initialFilters;
      return clonedState;
    },
    applyFilters(state: TaskboardReducerState) {
      const clonedState = cloneDeep(state);
      const filteredListsMap = applyFiltersUtil(
        clonedState.listsMap,
        clonedState.appliedFilters[clonedState._id!],
        state.searchString
      );

      const filtersWithoutDueDate = omit(clonedState.appliedFilters[clonedState._id!], ['dueDate']);
      const filteredListsMapWithoutDueDate = applyFiltersUtil(
        clonedState.listsMap,
        filtersWithoutDueDate,
        state.searchString
      );

      return {
        ...clonedState,
        displayListsMap: filteredListsMap,
        counts: calculateCountsUtil(filteredListsMapWithoutDueDate),
      };
    },
  },
});

// Utilities

const addCardsToListUtil = (state: TaskboardReducerState, listId: string, tasks: TaskType[]) => {
  const clonedListsMap = cloneDeep(state.listsMap);
  clonedListsMap[listId].tasks = unionBy(tasks, clonedListsMap[listId].tasks, '_id');
  return clonedListsMap;
};

const findTaskIdxFor = (state: TaskboardReducerState, listId: string, taskId: string) => {
  const list = state.listsMap?.[listId];
  const cardIdx = list?.tasks.findIndex((t) => t._id === taskId);
  return cardIdx;
};

const removeCardFromList = (state: TaskboardReducerState, listId: string, taskId: string) => {
  const taskIdx = findTaskIdxFor(state, listId, taskId);
  const clonedListsMap = cloneDeep(state.listsMap);
  const [task] = clonedListsMap[listId].tasks.splice(taskIdx, 1);
  return { task, listsMap: clonedListsMap };
};

const applyFiltersUtil = (
  listsMap: TaskboardReducerState['listsMap'],
  filters: ValueOf<TaskboardReducerState['appliedFilters']>,
  searchString: string
): TaskboardReducerState['listsMap'] => {
  const noFiltersApplied =
    filters && Object.values(filters).every((f) => Object.values(f).length === 0) && !searchString;
  if (noFiltersApplied) {
    return listsMap;
  }

  const newListsMap: TaskboardReducerState['listsMap'] = {};

  Object.keys(listsMap).forEach((listId) => {
    const list = listsMap[listId];
    let clonedTasks = cloneDeep(list.tasks);

    if (Object.values(filters.labels).length > 0) {
      clonedTasks = filterByLabels(clonedTasks, filters.labels);
    }

    if (Object.values(filters.assignedTo).length > 0) {
      clonedTasks = filterByAssignee(clonedTasks, filters.assignedTo);
    }

    if (filters.dueDate && Object.values(filters.dueDate).length > 0) {
      clonedTasks = filterByDueDate(clonedTasks, filters.dueDate);
    }

    if (searchString) {
      clonedTasks = filterBySearchString(clonedTasks, searchString);
    }

    newListsMap[listId] = { ...listsMap[listId], tasks: clonedTasks };
  });

  return newListsMap;
};

const filterByAssignee = (cards: TaskType[], filter: AppliedFilters['assignedTo']): TaskType[] =>
  cards.filter((card) => {
    if ('UNASSIGNED' in filter && card.assigned.length === 0) {
      return true;
    }

    return card.assigned.some((user) => user._id in filter);
  });

const filterByLabels = (cards: TaskType[], filter: AppliedFilters['labels']): TaskType[] =>
  cards.filter((card) => card.label.some((label) => label in filter));

const filterBySearchString = (cards: TaskType[], searchString: string): TaskType[] =>
  cards.filter(
    (card) =>
      card.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
      card.desc?.includes(searchString) ||
      card.taskNumber.toString().includes(searchString)
  );

export const checkTaskDueDateState = (taskDueDate: TaskType['duedate']) => {
  if (!taskDueDate) {
    return 'no_due_date';
  }

  const today = moment();
  const tomorrow = moment().add(1, 'day');
  const oneWeekFromTomorrow = moment(tomorrow).add(1, 'week');
  const oneMonthFromTomorrow = moment(tomorrow).add(1, 'month');

  const dueDate = moment(taskDueDate);

  if (dueDate.isBefore(today, 'day')) {
    return 'overdue';
  }
  if (dueDate.isSame(today, 'day')) {
    return 'today';
  }
  if (dueDate.isSame(tomorrow, 'day')) {
    return 'tomorrow';
  }
  if (dueDate.isBetween(tomorrow, oneWeekFromTomorrow, null, '[]')) {
    return 'next_7_days';
  }
  if (dueDate.isBetween(tomorrow, oneMonthFromTomorrow, null, '[]')) {
    return 'next_30_days';
  }

  return 'no_due_date';
};

const filterByDueDate = (cards: TaskType[], filter: AppliedFilters['dueDate']): TaskType[] =>
  cards.filter((card) => {
    const taskDueDateState = checkTaskDueDateState(card.duedate);

    return (
      ('no_due_date' in filter && taskDueDateState === 'no_due_date') ||
      ('overdue' in filter && taskDueDateState === 'overdue') ||
      ('today' in filter && taskDueDateState === 'today') ||
      ('tomorrow' in filter && taskDueDateState === 'tomorrow') ||
      ('next_7_days' in filter && taskDueDateState === 'next_7_days') ||
      ('next_30_days' in filter && taskDueDateState === 'next_30_days')
    );
  });

const calculateCountsUtil = (listsMap: TaskboardReducerState['listsMap']) => {
  const counts = {
    today: 0,
    tomorrow: 0,
    next_7_days: 0,
    next_30_days: 0,
    overdue: 0,
    no_due_date: 0,
  };

  Object.values(listsMap).forEach((list) => {
    const { tasks } = list;
    const isDoneList = list.name === constants.taskboardListKeys.DONE;

    if (!isDoneList) {
      for (const task of tasks) {
        const taskDueDateState = checkTaskDueDateState(task.duedate);

        if (taskDueDateState === 'today') {
          ++counts.today;
        }
        if (taskDueDateState === 'tomorrow') {
          ++counts.tomorrow;
        }
        if (taskDueDateState === 'next_7_days') {
          ++counts.next_7_days;
        }
        if (taskDueDateState === 'next_30_days') {
          ++counts.next_30_days;
        }
        if (taskDueDateState === 'overdue') {
          ++counts.overdue;
        }
        if (taskDueDateState === 'no_due_date') {
          ++counts.no_due_date;
        }
      }
    } else {
      for (const task of tasks) {
        const taskDueDateState = checkTaskDueDateState(task.duedate);

        if (taskDueDateState === 'today') {
          ++counts.today;
        }
      }
    }
  });

  return counts;
};

export const {
  setTaskboardData,
  resetTaskboard,
  editCard,
  modifyCommentsCount,
  removeCard,
  addCardsToList,
  moveCardAcrossLists,
  setSearchString,
  setFilters,
  resetFilters,
  applyFilters,
} = slice.actions;

export default persistReducer(
  { storage, key: 'taskboard', whitelist: ['appliedFilters', 'searchString'] },
  slice.reducer
);
