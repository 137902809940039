import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import taskboardService from '../Taskboard.service';
import { RootState } from '../../../../setup';
import useTaskboardDispatcher from './useTaskboardDispatcher';

const useTaskboardLoader = (workspaceId: string) => {
  const { dispatchSetTaskboard, dispatchResetTaskboard, dispatchApplyFilters } = useTaskboardDispatcher();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { lists, listsMap, listsOrder, displayListsMap } = useSelector<RootState, RootState['taskboard']>(
    ({ taskboard }) => taskboard,
    shallowEqual
  );

  useEffect(() => {
    const loadAndDispatchTaskboardData = async (workspaceId: string) => {
      const taskboard = await taskboardService.getTaskboardData(workspaceId);
      dispatchSetTaskboard(taskboard);
      dispatchApplyFilters();
      setIsLoading(false);
    };

    loadAndDispatchTaskboardData(workspaceId);
    return () => {
      dispatchResetTaskboard();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return { lists, listsMap, displayListsMap, listsOrder, isLoading };
};

export default useTaskboardLoader;
