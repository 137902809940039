import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Overlay, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import BloomAvatar from '../../../../components/bloomAvatar/BloomAvatar';
import constants from '../../../../shared/consts';
import { RootState } from '../../../../../setup';

const HeaderUserMenu: React.FC = () => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef(null);

  const user = useSelector<RootState, RootState['user']>((state) => state.user, shallowEqual);
  const intl = useIntl();

  return (
    <>
      <div ref={userMenuRef} className="cursor-pointer symbol symbol-30px symbol-md-40px">
        <BloomAvatar
          name={user?.firstname}
          imagePath={user?.images?.profile?.link}
          size="sm"
          onClick={() => setShowUserMenu((prevState) => !prevState)}
        />
      </div>
      <Overlay
        target={userMenuRef.current}
        placement="bottom"
        show={showUserMenu}
        rootClose
        onHide={() => setShowUserMenu(false)}
      >
        <Popover>
          <Popover.Header as="div" className="d-flex align-items-center">
            <div className="symbol symbol-50px me-5">
              <BloomAvatar name={user.firstname} imagePath={user.images?.profile?.link} size="sm" />
            </div>

            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {user.firstname} {user.lastname}
              </div>

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 400, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {user.email}
                  </Tooltip>
                )}
              >
                <div className="fw-bold text-muted col-7 fs-7 text-truncate mw-100">{user.email}</div>
              </OverlayTrigger>
            </div>
          </Popover.Header>
          <Popover.Body className="d-flex flex-column fw-bold p-4 fs-6">
            <Link
              to={`/users/${user._id}`}
              className="userMenuLink text-gray-600 p-3"
              onClick={() => setShowUserMenu(false)}
            >
              {intl.formatMessage({ id: 'AUTH.MY_PROFILE' })}
            </Link>

            <div className="separator my-2" />

            {!user.isClosed && (
              <>
                <Link
                  to={constants.routs.library}
                  className="userMenuLink text-gray-600 p-3"
                  onClick={() => setShowUserMenu(false)}
                >
                  {intl.formatMessage({ id: 'Library' })}
                </Link>
                <Link
                  to={`/${constants.navTypes.COMMUNITY}#/${constants.OPEN_COMMUNITY_PATH}`}
                  className="userMenuLink text-gray-600 p-3"
                  onClick={() => setShowUserMenu(false)}
                >
                  {intl.formatMessage({ id: 'Open community' })}
                </Link>
                <Link
                  to={`/${constants.navTypes.COMMUNITY}#/${constants.CALENDAR_PATH}`}
                  className="userMenuLink text-gray-600 p-3"
                  onClick={() => setShowUserMenu(false)}
                >
                  {intl.formatMessage({ id: 'Calendar' })}
                </Link>
              </>
            )}
            <a
              href={constants.FEEDBACK_PAGE_LINK}
              className="userMenuLink text-gray-600 p-3"
              onClick={() => setShowUserMenu(false)}
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({ id: 'Feedback' })}
            </a>
            <a
              href={constants.HELP_LINK}
              className="userMenuLink text-gray-600 p-3"
              onClick={() => setShowUserMenu(false)}
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({ id: 'Help' })}
            </a>

            <Link
              to="/logout"
              className="userMenuLinkDanger mt-4 text-center text-gray-600 p-3"
              onClick={() => setShowUserMenu(false)}
            >
              {intl.formatMessage({ id: 'AUTH.SIGN_OUT' })}
            </Link>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export { HeaderUserMenu };
