import { request } from '../../shared/http.service';
import constants from '../../shared/consts';
import { MinimalWorkspaceType, WorkspaceType } from '../org/org.types';
import { LabelType, TaskFieldsType, TaskType } from '../../components/taskboard/Taskboard.types';
import { MinimalUserModel } from '../auth/models/UserModel';

const workspaceService = {
  createWorkspace: async ({
    name,
    description,
    orgId,
    logoId,
  }: {
    name: string;
    description: string;
    orgId: string;
    logoId?: string;
  }) => {
    const res = await request({
      url: '/workspaces',
      data: { name, description, orgId, logoId },
      method: 'post',
    });
    return res.data.data.workspace as WorkspaceType;
  },

  updateWorkspaceImg: async (workspaceId: string, img: string) => {
    const res = await request({
      url: `/workspaces/${workspaceId}/logo`,
      data: {
        file: {
          base64String: img,
          uploadBy: { key: constants.uploadFilesBy.workspace, id: workspaceId },
        },
      },
      method: 'put',
    });
    return res.data.data.workspace as WorkspaceType;
  },

  removeWorkspaceImg: async (workspaceId: string) => {
    const res = await request({
      url: `/workspaces/${workspaceId}/logo`,
      method: 'delete',
    });
    return res.data.data.workspace as WorkspaceType;
  },

  uploadImg: async (img: string, orgId: string) => {
    const res = await request({
      url: `/orgs/${orgId}/upload-img`,
      data: { file: img },
      method: 'post',
    });
    return res.data.data.asset;
  },

  updateWorkspace: async (workspaceId: string, { name, description }: { name: string; description: string }) => {
    const res = await request({
      url: `/workspaces/${workspaceId}`,
      data: { description, title: name },
      method: 'put',
    });
    return res.data.data.workspace as WorkspaceType;
  },

  getMinimalWorkspaceFromWorkspaceModal: ({
    _id,
    computedTitle,
    title,
    avatars,
    ownerOrgs,
  }: WorkspaceType): MinimalWorkspaceType => ({
    _id,
    computedTitle,
    title,
    avatars,
    ownerOrgs: ownerOrgs.map((o) => ({
      ...o,
      members: o.members.map((m) => ({ ...m, user: m.user._id })),
    })),
  }),

  getWorkspaceLabelsAndUsers: async (workspaceId: WorkspaceType['_id']) => {
    const res = await request({
      url: `/workspaces/${workspaceId}/labels-and-users`,
    });
    return {
      users: res?.data?.data?.users as MinimalUserModel[],
      labels: res?.data?.data?.labels as LabelType[],
    };
  },

  createTask: async ({
    taskFields,
    customLabelsNames,
    workspaceId,
  }: {
    taskFields: Partial<TaskFieldsType>;
    customLabelsNames: string[];
    workspaceId: string;
  }) => {
    const res = await request({
      url: `/workspaces/${workspaceId}/task`,
      data: {
        taskFields,
        customLabelsNames,
      },
      method: 'post',
    });
    return res?.data?.data?.task as TaskType;
  },
};

export default workspaceService;
