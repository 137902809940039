import { isEmpty, isEqual, xorWith } from 'lodash';
import csvDownload from 'json-to-csv-export';

export const areArraysEqual = (x: Array<any>, y: Array<any>) => isEmpty(xorWith(x, y, isEqual));

export const stringToColor = (stringInput: string) => {
  const stringUniqueHash = stringInput
    .split('')
    // eslint-disable-next-line no-bitwise
    .reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
  return `hsl(${stringUniqueHash % 360}, 30%, 50%)`;
};

export const stringCapitalize = (stringInput: string) => {
  return `${stringInput.charAt(0).toUpperCase()}${stringInput.slice(1).toLocaleLowerCase()}`;
};

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

interface CsvDownloadProps {
  data: any[];
  filename?: string;
  delimiter?: string;
  headers?: string[];
}

const getCurrentUrl = (pathname: string) => {
  return pathname.split(/[?#]/)[0];
};

export const checkIsMenuActive = (pathname: string, url: string) => {
  const currentPathName = getCurrentUrl(pathname);
  if (!currentPathName || !url) {
    return false;
  }

  if (currentPathName === url) {
    return true;
  }

  if (currentPathName.indexOf(url) > -1) {
    return true;
  }

  return false;
};

export const arrayToCSVAndDownload = (props: CsvDownloadProps) => {
  csvDownload(props);
};

export const isDate = (dateStr: string) => {
  return !Number.isNaN(new Date(dateStr).getDate());
};

export const removeStringNewLine = (stringInput: string) => {
  return stringInput.replace(/(\r\n|\n|\r)/gm, ' ');
};
