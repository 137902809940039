import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IUseSubscription, IMessage } from './pubSub.types';
import { RootState } from '../../../setup';

const useSubscription = (topic: string): IUseSubscription => {
  const [message, setMessage] = useState<IMessage | undefined>(undefined);

  const { client } = useSelector<RootState, RootState['pubSub']>((state) => state.pubSub);

  const onMessageReceive = useCallback(
    (receivedTopic: string, receivedMessage: any) => {
      if (topic === receivedTopic) {
        setMessage({
          topic: receivedTopic,
          message: receivedMessage.toString(),
        });
      }
    },
    [topic]
  );

  useEffect(() => {
    if (client?.connected && !client.disconnecting) {
      client.subscribe(topic);
      client.on('message', onMessageReceive);
    }

    return () => {
      if (client?.connected && !client.disconnecting) {
        client.unsubscribe(topic);
        client.off('message', onMessageReceive);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, client?.connected, client?.disconnecting, topic]);

  return {
    topic,
    message,
  };
};

export default useSubscription;
