/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect, IClientOptions, MqttClient } from 'mqtt';
import { setPubSub } from './pubSub.slice';
import { callGetMqttUrl } from './pubsub.service';
import { RootState } from '../../../setup';
import {
  STATUS_CONNECT,
  STATUS_CONNECTING,
  STATUS_CONNECTED,
  STATUS_OFFLINE,
  STATUS_RECONNECT,
  STATUS_RECONNECTING,
  STATUS_END,
  STATUS_ENDED,
  STATUS_ERROR,
  STATUS_CLOSE,
} from './pubSub.constants';

type Props = {
  isAuthorized?: boolean;
};
const usePubSub = ({ isAuthorized }: Props) => {
  const dispatch = useDispatch();

  const [isFetchingBrokerUrl, setIsFetchingBrokerUrl] = useState(false);

  const { client } = useSelector<RootState, RootState['pubSub']>((state) => state.pubSub);

  const brokerUrl = useRef(null);

  const fetchBrokerUrl = async () => {
    if (navigator.onLine) {
      setIsFetchingBrokerUrl(true);
      const urlData = await callGetMqttUrl();
      brokerUrl.current = urlData.data.data.url;
      setIsFetchingBrokerUrl(false);
    }
  };

  const endCurrentClient = () => {
    if (client) {
      client.removeAllListeners();
      client.end(true);
    }
  };

  const bindClientHooks = (mqttClient: MqttClient) => {
    mqttClient.on(STATUS_CONNECT, () => {
      dispatch(setPubSub({ connectionStatus: STATUS_CONNECTED }));
    });

    mqttClient.on(STATUS_RECONNECT, () => {
      dispatch(setPubSub({ connectionStatus: STATUS_RECONNECTING }));
    });

    mqttClient.on(STATUS_ERROR, (err) => {
      // eslint-disable-next-line no-console
      console.error(`Connection error: ${err}`);
      dispatch(setPubSub({ connectionStatus: STATUS_ERROR, error: err.message }));
    });

    mqttClient.on(STATUS_CLOSE, () => {
      dispatch(setPubSub({ connectionStatus: STATUS_CLOSE }));
      fetchBrokerUrl();
    });

    mqttClient.on(STATUS_OFFLINE, () => {
      dispatch(setPubSub({ connectionStatus: STATUS_OFFLINE }));
    });

    mqttClient.on(STATUS_END, () => {
      dispatch(setPubSub({ connectionStatus: STATUS_ENDED }));
    });
  };

  const initializeNewClient = (_brokerUrl: string, _options?: IClientOptions) => {
    endCurrentClient();
    dispatch(setPubSub({ connectionStatus: STATUS_CONNECTING }));
    const mqttClient = connect(_brokerUrl, {
      transformWsUrl: (url) => brokerUrl.current || url,
    });
    dispatch(setPubSub({ client: mqttClient }));
    bindClientHooks(mqttClient);
  };

  useEffect(() => {
    if (isAuthorized && !client && brokerUrl.current) {
      initializeNewClient(brokerUrl.current);
    }
    if (isAuthorized && !client && !isFetchingBrokerUrl) {
      fetchBrokerUrl();
    }
  }, [isAuthorized, brokerUrl.current]);
};

export default usePubSub;
