import { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import { useState } from 'react';
import { uploadFiles } from '../../shared/http.service';

const useImageUploader = ({ onError }: { onError: Function }) => {
  Quill.register('modules/imageUploader', ImageUploader, true);
  const [imagesBeingUploaded, setImageBeingUploaded] = useState<Array<string>>([]);

  const handleImageUploadStarts = (imageName: string) => {
    setImageBeingUploaded((prevState) => [...prevState, imageName]);
  };

  const handleImageUploadEnds = (imageName: string) => {
    setImageBeingUploaded((prevState) => prevState.filter((n) => imageName !== n));
  };

  const imageHandler = async (file: File) => {
    handleImageUploadStarts(file.name);
    const formData = new FormData();
    formData.append('file', file!);
    try {
      const res = await uploadFiles({ url: 'text-editor/upload-files', formData });
      handleImageUploadEnds(file.name);
      return res.src;
    } catch (err: any) {
      onError(err.message);
      handleImageUploadEnds(file.name);
      throw err;
    }
  };

  return {
    modules: {
      imageUploader: {
        upload: imageHandler,
      },
    },
    isUploading: imagesBeingUploaded.length > 0,
  };
};

export default useImageUploader;
