import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { components, OptionProps } from 'react-select';
import BloomAvatar from '../bloomAvatar/BloomAvatar';
import { MinimalUserModel } from '../../modules/auth/models/UserModel';

export type OptionWithAvatarType = {
  label: string;
  imageLink?: string;
  user?: MinimalUserModel;
};

type Props<O extends OptionWithAvatarType> = OptionProps<O>;

const OptionWithAvatar = <Option extends OptionWithAvatarType>(props: Props<Option>): React.ReactElement => {
  const {
    data: { label, imageLink, user },
    className,
    cx,
    isDisabled,
    isFocused,
    isSelected,
    innerProps,
  } = props;
  return (
    <components.Option
      {...props}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        `${className || ''} d-flex mb-1 w-100`
      )}
      aria-disabled={isDisabled}
      {...innerProps}
    >
      {label && (
        <BloomAvatar
          name={label}
          size="xs"
          imagePath={imageLink}
          avatarStatus={user?.isClosed ? 'closed' : undefined}
          rounded
        />
      )}
      <OverlayTrigger
        placement="top"
        delay={{ show: 400, hide: 400 }}
        overlay={(props) => (
          <Tooltip id="button-tooltip" {...props}>
            {label}
          </Tooltip>
        )}
      >
        <span className="mx-2 my-auto text-truncate">{label}</span>
      </OverlayTrigger>
    </components.Option>
  );
};

export default OptionWithAvatar;
