import { captureException } from '@sentry/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import constants from '../../../../../shared/consts';
import { BloomSpace, CreateFormValues } from '../../../../../components/CreateModal/createModal.types';
import orgService from '../../../../../modules/org/OrgService';
import CreateModalWrapper from '../../../../../components/CreateModal/CreateModalWrapper';

type IProps = {};

const CreateOrgBtn: React.FC<IProps> = () => {
  const intl = useIntl();

  const [show, setShow] = useState(false);

  const TYPE = constants.orgTypes.TEAM as BloomSpace;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFormSubmit = async (orgFields: CreateFormValues) => {
    const org = await orgService.createOrg({
      ...orgFields,
      type: TYPE,
    });

    try {
      if (orgFields.img) {
        const orgWithImg = await orgService.updateOrgImg(org._id, orgFields.img);
        return orgWithImg;
      }
    } catch (error) {
      captureException(error);
    }
    return org;
  };

  return (
    <>
      <Button className="btn btn-sm btn-primary" onClick={handleShow}>
        {intl.formatMessage({ id: 'Create Team' })}
      </Button>
      {show && (
        <CreateModalWrapper
          show={show}
          onClose={handleClose}
          type={TYPE}
          imageAlt="Organization Avatar"
          onFormSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};

export default CreateOrgBtn;
