import qs from 'query-string';
import storage from 'redux-persist/lib/storage';

export const getLocalStorageToken = () => {
  const { token } = qs.parse(window.location.search);
  if (!token) {
    return localStorage.getItem('token');
  }
  window.location.replace(`${window.location.origin}${window.location.pathname}`);

  return token as string;
};

export const setLocalStorageToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const clearLocalStorage = () => {
  // `storage.removeItem` is to clear redux persisted state
  storage.removeItem('persist:auth');
  storage.removeItem('persist:taskboard');
  storage.removeItem('persist:growth');
  storage.removeItem('persist:ddSummary');
  localStorage.clear();
};
