import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MinimalWorkspaceType, OrgModel, WorkspaceType } from '../../org/org.types';
import notificationsService from '../notifications.service';
import { mutateWorkspaceUnreadNotificationsCountBy } from '../notifications.slice';
import { MinimalParentNotificationType } from '../notifications.types';
import useProgramNotificationsAttentionMessage from './useProgramNotificationsAttentionMessage';

const useOrgNotificationsLoader = ({ orgId, isOnlyUnread }: { orgId: OrgModel['_id']; isOnlyUnread?: boolean }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [generalError, setGeneralError] = useState('');
  const [notifications, setNotifications] = useState<MinimalParentNotificationType<MinimalWorkspaceType>[]>([]);

  const { showProgramNotificationsAlert, setShowProgramNotificationsAlert } = useProgramNotificationsAttentionMessage();

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const getNotifications = async () => {
      setIsLoading(true);
      try {
        const res = await notificationsService.getOrgParentNotifications(
          orgId,
          {
            isOnlyUnread: Boolean(isOnlyUnread),
          },
          controller.signal
        );

        setNotifications(res);
        setGeneralError('');
      } catch (error: any) {
        setGeneralError(error.message);
      }
      setIsLoading(false);
    };
    getNotifications();

    return () => controller.abort();
  }, [isOnlyUnread, orgId]);

  const handleToggleChildUnreadState = async ({
    currentUnreadState,
    parentId,
    childId,
    workspaceId,
  }: {
    currentUnreadState: boolean;
    parentId: string;
    childId: string;
    workspaceId: WorkspaceType['_id'];
  }) => {
    try {
      if (currentUnreadState) {
        dispatch(mutateWorkspaceUnreadNotificationsCountBy({ workspaceId, mutateBy: -1 }));
        setNotifications((prevState) => {
          const clonedState = cloneDeep(prevState);
          const parent = clonedState.find((pn) => pn._id === parentId);
          if (parent) parent.unread = parent.unread.filter((n) => n !== childId);
          return clonedState;
        });
        await notificationsService.markChildNotificationAsRead(parentId, childId);
      } else {
        dispatch(mutateWorkspaceUnreadNotificationsCountBy({ workspaceId, mutateBy: 1 }));
        setNotifications((prevState) => {
          const clonedState = cloneDeep(prevState);
          clonedState.find((pn) => pn._id === parentId)?.unread.push(childId);
          return clonedState;
        });
        await notificationsService.markChildNotificationAsUnread(parentId, childId);
      }
    } catch (error: any) {
      setGeneralError(error.message);
    }
  };

  const handleMarkAllChildrenAsRead = async ({
    parentId,
    workspaceId,
    unreadChildrenCount,
  }: {
    parentId: string;
    workspaceId: WorkspaceType['_id'];
    unreadChildrenCount: number;
  }) => {
    try {
      dispatch(mutateWorkspaceUnreadNotificationsCountBy({ workspaceId, mutateBy: unreadChildrenCount * -1 }));
      setNotifications((prevState) => {
        const clonedState = cloneDeep(prevState);
        const parent = clonedState.find((pn) => pn._id === parentId);
        if (parent) parent.unread = [];
        return clonedState;
      });
      await notificationsService.markAllChildNotificationsAsRead(parentId);
    } catch (error: any) {
      setGeneralError(error.message);
    }
  };

  return {
    handleToggleChildUnreadState,
    handleMarkAllChildrenAsRead,
    notifications,
    isLoading,
    generalError,
    showProgramNotificationsAlert,
    setShowProgramNotificationsAlert,
  };
};

export default useOrgNotificationsLoader;
