import React, { useEffect, useState } from 'react';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/IconButton';

const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScrollToTopButtonVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScrollToTopButtonVisibility);

    return () => {
      window.removeEventListener('scroll', handleScrollToTopButtonVisibility);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!isVisible) return null;

  return (
    <IconButton
      variant="primary"
      className="scroll-to-top px-3"
      icon={faArrowUp}
      iconSize="xl"
      iconClassName="text-white"
      title="scroll to top"
      onClick={handleScrollToTop}
    />
  );
};

export default ScrollTop;
