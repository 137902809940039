import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHeader, ISidebar, IToolbar } from './Layout.types';

export type LayoutState = {
  header: IHeader;
  toolbar: IToolbar;
  sidebar: ISidebar;
};

export const initialLayoutState: LayoutState = {
  header: {
    isVisible: true,
    pageTitle: {
      isVisible: true,
      isLoading: false,
      title: '',
      avatars: undefined,
    },
    headerControls: {
      userMenu: {
        isVisible: true,
      },
      welcomeTitle: {
        isVisible: true,
      },
      orgNotificationsBell: {
        isVisible: false,
      },
      messages: {
        isVisible: false,
      },
    },
  },
  toolbar: {
    isVisible: false,
    menuItems: [],
    actionButtons: [],
  },
  sidebar: {
    isVisible: false,
    isSidebarOpen: true,
    isDrawer: false,
  },
};

const slice = createSlice({
  name: 'layout',
  initialState: initialLayoutState,
  reducers: {
    setHeaderVisibility(state: LayoutState, action: PayloadAction<IHeader['isVisible']>) {
      state.header.isVisible = action.payload;
    },
    setHeaderTitleVisibility(state: LayoutState, action: PayloadAction<IHeader['pageTitle']['isVisible']>) {
      state.header.pageTitle.isVisible = action.payload;
    },
    setHeaderTitleLoading(state: LayoutState, action: PayloadAction<IHeader['pageTitle']['isLoading']>) {
      state.header.pageTitle.isLoading = action.payload;
    },
    setHeaderTitle(state: LayoutState, action: PayloadAction<IHeader['pageTitle']['title']>) {
      state.header.pageTitle.title = action.payload;
    },
    setHeaderAvatars(state: LayoutState, action: PayloadAction<IHeader['pageTitle']['avatars']>) {
      state.header.pageTitle.avatars = action.payload;
    },
    setUserMenuVisibility(
      state: LayoutState,
      action: PayloadAction<IHeader['headerControls']['userMenu']['isVisible']>
    ) {
      state.header.headerControls.userMenu.isVisible = action.payload;
    },
    setWelcomeTitleVisibility(
      state: LayoutState,
      action: PayloadAction<IHeader['headerControls']['welcomeTitle']['isVisible']>
    ) {
      state.header.headerControls.welcomeTitle.isVisible = action.payload;
    },
    setOrgNotificationsBellVisibility(
      state: LayoutState,
      action: PayloadAction<IHeader['headerControls']['orgNotificationsBell']['isVisible']>
    ) {
      state.header.headerControls.orgNotificationsBell.isVisible = action.payload;
    },
    setMessagesVisibility(
      state: LayoutState,
      action: PayloadAction<IHeader['headerControls']['messages']['isVisible']>
    ) {
      state.header.headerControls.messages.isVisible = action.payload;
    },
    setToolBarVisibility(state: LayoutState, action: PayloadAction<IToolbar['isVisible']>) {
      state.toolbar.isVisible = action.payload;
    },
    setToolBarMenuItems(state: LayoutState, action: PayloadAction<IToolbar['menuItems']>) {
      state.toolbar.menuItems = action.payload;
    },
    setToolBarActionButtons(state: LayoutState, action: PayloadAction<IToolbar['actionButtons']>) {
      state.toolbar.actionButtons = action.payload;
    },
    setSidebarVisibility(state: LayoutState, action: PayloadAction<ISidebar['isVisible']>) {
      state.sidebar.isVisible = action.payload;
    },
    toggleSidebarState(state: LayoutState) {
      state.sidebar.isSidebarOpen = !state.sidebar.isSidebarOpen;
    },
    setSidebarIsDrawer(state: LayoutState, action: PayloadAction<ISidebar['isDrawer']>) {
      state.sidebar.isDrawer = action.payload;
    },
  },
});

export const {
  setHeaderVisibility,
  setHeaderTitleVisibility,
  setHeaderTitleLoading,
  setHeaderTitle,
  setHeaderAvatars,
  setUserMenuVisibility,
  setWelcomeTitleVisibility,
  setOrgNotificationsBellVisibility,
  setMessagesVisibility,
  setToolBarVisibility,
  setToolBarMenuItems,
  setToolBarActionButtons,
  setSidebarVisibility,
  toggleSidebarState,
  setSidebarIsDrawer,
} = slice.actions;

export default slice.reducer;
