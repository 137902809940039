import { useState, useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import useBlotFormatter from './useBlotFormatter';
import useImageUploader from './useImageUploader';
import useMagicUrl from './useMagicUrl';
import useEmoji from './useEmoji';
import useMention from './useMention';
import { MentionType } from './richTextEditor.type';

type Props = {
  mentionOptions?: Array<MentionType>;
  isMinimalEditor: boolean;
};

const useEditorConfig = ({ mentionOptions, isMinimalEditor }: Props) => {
  const [imageUploadError, setImageUploadError] = useState('');
  const editorRef = useRef<ReactQuill>(null);

  const { modules: imageUploaderModules, isUploading } = useImageUploader({
    onError: () => setImageUploadError('failed to upload your image, please try again'),
  });

  const { modules: blotFormatterModules } = useBlotFormatter();
  const { modules: magicUrlModules } = useMagicUrl();
  const { modules: emojiModules } = useEmoji();
  const { modules: mentionModules } = useMention(mentionOptions);

  const modules = useMemo(
    () => ({
      toolbar: [
        [{ header: 1 }, { header: 2 }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        ['emoji'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        isMinimalEditor ? ['link'] : ['link', 'image', 'video'],
      ],
      ...(isMinimalEditor ? {} : imageUploaderModules),
      ...(isMinimalEditor ? {} : blotFormatterModules),
      ...magicUrlModules,
      ...emojiModules,
      ...mentionModules,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMinimalEditor]
  );

  return { modules, editorRef, isUploading, imageUploadError };
};

export default useEditorConfig;
