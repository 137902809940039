import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Overlay, Popover } from 'react-bootstrap';
import DebouncedInput from '../../../../../components/DebouncedInput/DebouncedInput';
import { useTaskboardHandlers } from '../../../../../components/taskboard/Taskboard.hooks';
import { RootState } from '../../../../../../setup';
import IconButton from '../../../../../components/IconButton';
import useScreenSize from '../../../../../modules/dimensions/useScreenSize';

type IProps = {};

const TaskboardSearchInput: React.FC<IProps> = () => {
  const intl = useIntl();
  const { handleFilterTasksBySearchString } = useTaskboardHandlers();
  const { searchString } = useSelector<RootState, RootState['taskboard']>(({ taskboard }) => taskboard);

  return (
    <DebouncedInput
      value={searchString}
      onChange={(value) => handleFilterTasksBySearchString(value)}
      placeholder={intl.formatMessage({ id: 'Search...' })}
      className="form-control p-2"
    />
  );
};

const TaskboardSearch: React.FC<IProps> = () => {
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const intl = useIntl();

  const { isScreenSmallerThanOrEqual } = useScreenSize();

  const isSmallScreen = isScreenSmallerThanOrEqual('sm');

  if (!isSmallScreen) {
    return <TaskboardSearchInput />;
  }

  return (
    <>
      <div ref={ref}>
        <IconButton
          variant="light"
          size="sm"
          icon={faSearch}
          iconSize="lg"
          className="px-4"
          onClick={() => setShowMenu((prevState) => !prevState)}
          title={intl.formatMessage({ id: 'Search...' })}
        />
      </div>

      <Overlay target={ref.current} placement="bottom" show={showMenu} rootClose onHide={() => setShowMenu(false)}>
        <Popover>
          <Popover.Body className="p-3">
            <TaskboardSearchInput />
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default TaskboardSearch;
