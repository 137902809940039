import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../../../../../setup';
import OverlayPopup from '../../../../../components/overlayPopup/OverlayPopup';
import TakeAssessmentDrawer from '../../../../../components/Assessments/TakeAssessmentDrawer';

const TakeAssessmentBtn: React.FC = () => {
  const intl = useIntl();

  const [isTakeAssessmentsDrawerOpen, setIsTakeAssessmentsDrawerOpen] = useState(false);

  const handleCloseTakeAssessmentsDrawer = () => setIsTakeAssessmentsDrawerOpen(false);
  const handleOpenTakeAssessmentsDrawer = () => setIsTakeAssessmentsDrawerOpen(true);

  const user = useSelector<RootState, RootState['user']>((state) => state.user);

  const currentWorkspace = useSelector<RootState, RootState['currentWorkspace']>((state) => state.currentWorkspace);

  const isWorkspaceMember = useMemo(
    () => currentWorkspace.ownerOrgs[0].members.some((m: any) => m.user === user._id),
    [currentWorkspace.ownerOrgs, user._id]
  );

  return (
    <>
      <OverlayPopup
        placement="bottom"
        popoverChildren={intl.formatMessage({
          id: isWorkspaceMember
            ? 'TOOLBAR_ACTIONS.TAKE_ASSESSMENT'
            : "You can't take an assessment here because you are not part of this team",
        })}
      >
        <Button
          disabled={!isWorkspaceMember}
          onClick={handleOpenTakeAssessmentsDrawer}
          size="sm"
          data-name="take-assessment-btn"
        >
          <FontAwesomeIcon size="lg" icon={faPlus} style={{ marginRight: '4px' }} />
          {intl.formatMessage({ id: 'TOOLBAR_ACTIONS.TAKE_ASSESSMENT' })}
        </Button>
      </OverlayPopup>
      <TakeAssessmentDrawer isOpen={isTakeAssessmentsDrawerOpen} onClose={handleCloseTakeAssessmentsDrawer} />
    </>
  );
};

export default TakeAssessmentBtn;
