/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import KTSVG from '../KTSVG/KTSVG';
import SvgIconButton from '../svgIconButton/SvgIconButton';

type Props = {
  title: string;
  onDelete: () => void;
  fileLink: string;
  iconPath?: string;
  disabled?: boolean;
};

const FileCard: React.FC<Props> = ({ title, onDelete, iconPath, disabled, fileLink }) => {
  const intl = useIntl();
  return (
    <div className="d-flex align-items-center me-2 p-2 bg-light rounded mw-200px my-2">
      {iconPath && (
        <div>
          <KTSVG path={iconPath} className="svg-icon-1 me-1 " />
        </div>
      )}
      <OverlayTrigger
        placement="top"
        delay={{ show: 200, hide: 200 }}
        overlay={(props) => (
          <Tooltip id="button-tooltip" {...props}>
            {title}
          </Tooltip>
        )}
      >
        <div className="fs-5 fw-bolder text-truncate me-2">{title}</div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        delay={{ show: 200, hide: 200 }}
        overlay={(props) => (
          <Tooltip id="button-tooltip" {...props}>
            {intl.formatMessage({ id: 'View Attachment' })}
          </Tooltip>
        )}
      >
        <a target="_blank" href={fileLink} rel="noreferrer">
          <KTSVG
            path="/media/icons/custom/download-file.svg"
            className="svg-icon m-0 svg-icon-black svg-icon-1 svg-icon-primary"
          />
        </a>
      </OverlayTrigger>
      <SvgIconButton
        svgIconPath="/media/icons/duotune/general/gen034.svg"
        svgClassName="svg-icon-1 svg-icon-danger"
        title={intl.formatMessage({ id: 'Delete Attachment' })}
        onClick={onDelete}
        disabled={disabled}
      />
    </div>
  );
};

export default FileCard;
