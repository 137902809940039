import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { WorkspaceType } from '../../../modules/org/org.types';
import { RootState } from '../../../../setup';
import AsideMenuItem from './AsideMenuItem';
import WorkspaceDropdownMenu from '../../../pages/hub/components/WorkspaceDropdownMenu';

type WorkspaceMenuItemProps = {
  teamId: string;
  workspace: WorkspaceType;
  isActive: boolean;
  to: string;
  isSubItem?: boolean;
};

const WorkspaceMenuItem: React.FC<WorkspaceMenuItemProps> = ({
  teamId,
  workspace,
  isActive,
  to,
  isSubItem = false,
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const { workspaceUnreadNotificationCountsMap } = useSelector<RootState, RootState['notifications']>(
    ({ notifications }) => notifications
  );
  const notificationsBadgeNumber =
    workspaceUnreadNotificationCountsMap[workspace._id] > 99
      ? '+99'
      : `${workspaceUnreadNotificationCountsMap[workspace._id] || ''}`;

  return (
    <AsideMenuItem
      to={to}
      title={workspace.title}
      isActive={isActive}
      isSubItem={isSubItem}
      avatars={[
        {
          imagePath: workspace.ownerOrgs[0]?.logo?.link,
          name: workspace.ownerOrgs[0]?.name || '',
          size: 'xxs',
          badge: notificationsBadgeNumber,
        },
      ]}
      dropdownMenu={
        <WorkspaceDropdownMenu
          workspaceId={workspace._id}
          teamId={teamId}
          onDropDownToggle={(nextShow) => setIsDropDownOpen(nextShow)}
        />
      }
      isDropDownOpen={isDropDownOpen}
    />
  );
};

export default WorkspaceMenuItem;
