import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import { setUser } from './user.slice';
import userService from './user.service';

export const useUserLoader = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const loadUser = async () => {
    try {
      const user = await userService.callVerifyMe();
      if (user) {
        dispatch(setUser({ user }));
        Sentry.setUser({
          id: user._id,
          username: user.username,
          emai: user.email,
        });
      }
    } catch (error) {
      captureException(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  return isLoading;
};
