import { faLink } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import IconButton from '../../components/IconButton';
import RichTextEditor from '../../components/richTextEditor/RichTextEditor';
import useLayout from '../../layout/hooks/useLayout';

const EditorPage: React.FC = () => {
  const intl = useIntl();

  useLayout({
    sidebarIsVisible: false,
    toolbarIsVisible: false,
    toolbarMenuItems: [],
    pageTitleIsVisible: false,
    messagesIsVisible: false,
    notificationsBellIsVisible: false,
  });

  const [value, setValue] = React.useState('');
  return (
    <Container className="my-5 pb-9">
      <RichTextEditor
        onChange={(state) => setValue(state.rawText === '' ? '' : state.htmlContent)}
        value={value}
        placeholder="type here..."
        className="form-control"
      />
      <Card className="mt-5">
        <Card.Body>
          <Card.Title>
            {intl.formatMessage({
              id: 'The HTML equivalent of the editor content',
            })}
            :
          </Card.Title>
          <Card.Text className="bg-light p-4 m-2 round">
            <code>{value}</code>
          </Card.Text>
          <IconButton
            variant="primary"
            title={intl.formatMessage({
              id: 'Copy HTML Code',
            })}
            iconSize="lg"
            icon={faLink}
            className="text-white"
            onClick={() => navigator.clipboard.writeText(value)}
          >
            {intl.formatMessage({ id: 'Copy HTML Code' })}
          </IconButton>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditorPage;
