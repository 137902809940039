import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { sortLabelsAlphabetically } from '../../modules/label/label.service';
import { LabelType } from '../taskboard/Taskboard.types';

type Props = {
  allLabels: LabelType[];
  selectedLabels: string[];
  onToggleLabel: (_label: LabelType) => void;
  onSelectForEdit: (_label: LabelType) => void;
};

const TaskLabelList: React.FC<Props> = ({ allLabels, selectedLabels, onToggleLabel, onSelectForEdit }) => {
  const intl = useIntl();

  const sortedLabels = sortLabelsAlphabetically([...allLabels]);

  return (
    <>
      {sortedLabels.map((label) => (
        <div
          key={label._id}
          role="button"
          aria-hidden="true"
          onClick={() => onToggleLabel(label)}
          className="btn btn-light d-flex align-items-center mb-2 py-3"
          style={{ backgroundColor: `${label.color}10`, color: label.color }}
        >
          <p className="flex-1 m-0 flex-grow-1 text-start text-truncate">{label.name}</p>
          {selectedLabels.indexOf(label._id) > -1 && <i className="bi bi-check2 fs-1 mx-2 text-success" />}
          <Button
            variant="secondary"
            type="button"
            size="sm"
            className="p-2"
            aria-label={intl.formatMessage({ id: 'Edit' })}
            aria-hidden="true"
            onClick={(e) => {
              e.stopPropagation();
              onSelectForEdit(label);
            }}
          >
            <i className="bi bi-pencil fs-2 p-0" aria-hidden="true" />
          </Button>
        </div>
      ))}
    </>
  );
};

export default TaskLabelList;
