import { OverlayTriggerProps } from 'react-bootstrap';
import { ReactElement } from 'react';
import { avatarSizeMap } from './BloomAvatar';

export const avatarStatusPropsMap = {
  inactive: {
    intlId: 'Inactive',
    borderColor: '',
    badgeBgColor: '',
  },
  closed: {
    intlId: 'Closed',
    borderColor: 'border-warning',
    badgeBgColor: 'warning',
  },
  deleted: {
    intlId: 'Deleted',
    borderColor: 'border-danger',
    badgeBgColor: 'danger',
  },
};

type AvatarStatus = keyof typeof avatarStatusPropsMap;

export type BloomAvatarProps = {
  name: string;
  tooltipTrigger?: OverlayTriggerProps['trigger'];
  tooltipHeader?: string | ReactElement;
  tooltipBody?: string | ReactElement;
  avatarStatus?: AvatarStatus;
  imagePath?: string;
  imageUrl?: string;
  size: keyof typeof avatarSizeMap;
  rounded?: boolean;
  color?: string;
  textColor?: string;
  containerClassName?: string;
  className?: string;
  shouldAbbreviateName?: boolean;
  disableTooltip?: boolean;
  badge?: string | number;
  onClick?: () => void;
};
