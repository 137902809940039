import React, { ComponentProps } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

type Props = {
  disabled?: boolean;
  popoverChildren: React.ReactNode;
  placement?: ComponentProps<typeof OverlayTrigger>['placement'];
  children?: React.ReactNode;
};

const OverlayPopup: React.FC<Props> = ({ disabled, children, popoverChildren, placement = 'top' }) => {
  if (disabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 400, hide: 100 }}
      overlay={
        <Popover>
          <Popover.Body>{popoverChildren}</Popover.Body>
        </Popover>
      }
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default OverlayPopup;
