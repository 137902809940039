import { FormikProps } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../shared/helperFunctions';
import BloomImgInput from '../uploadFiles/BloomImgInput';

type IProps = {
  formik: FormikProps<{ name: string; description: string; shortName: string }>;
  onSubmitImg: (_a: string) => void;
  imageAlt: string;
  isWithoutShortName?: boolean;
  isWithAvatar?: boolean;
};

const DEFAULT_IMG = toAbsoluteUrl('/media/defaultImages/defaultOrgLogo.png');

export const CreateForm: React.FC<IProps> = ({
  formik,
  onSubmitImg,
  imageAlt,
  isWithoutShortName,
  isWithAvatar = true,
}) => {
  const intl = useIntl();

  return (
    <Row className="justify-content-md-between">
      {isWithAvatar && (
        <Col xs={12} lg={4}>
          <Form.Group controlId="imageInput">
            <BloomImgInput
              onSubmitImg={onSubmitImg}
              defaultImgPath={DEFAULT_IMG}
              disabled={formik.isSubmitting}
              alt={imageAlt}
            />
          </Form.Group>
        </Col>
      )}
      <Col xs={12} lg={isWithAvatar ? 8 : 12}>
        <Form.Group controlId="nameInput">
          <Form.Control
            type="text"
            name="name"
            className="form-control-solid mb-4 mb-lg-3"
            placeholder={intl.formatMessage({
              id: 'CREATE_FORM.NAME',
            })}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            isInvalid={formik.touched.name && !!formik.errors.name}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
        </Form.Group>
        {!isWithoutShortName && (
          <Form.Group controlId="shortNameInput">
            <Form.Control
              type="text"
              name="shortName"
              className="form-control-solid mb-4 mb-lg-3"
              placeholder={intl.formatMessage({
                id: 'CREATE_FORM.SHORT_NAME',
              })}
              value={formik.values.shortName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={formik.isSubmitting}
              isValid={formik.touched.shortName && !formik.errors.shortName}
              isInvalid={formik.touched.shortName && !!formik.errors.shortName}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.shortName}</Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group controlId="descInput">
          <Form.Control
            as="textarea"
            name="description"
            className="form-control-solid mb-3 mb-lg-2"
            placeholder={intl.formatMessage({
              id: 'CREATE_FORM.DESCRIPTION',
            })}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.description && !!formik.errors.description}
            disabled={formik.isSubmitting}
            rows={6}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default CreateForm;
