import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React from 'react';

type Props = {
  color: string;
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  noMaxWidth?: boolean;
};

const Label: React.FC<Props> = ({ color, name }) => (
  <span className="badge mw-100 text-truncate py-3 align-middle" style={{ backgroundColor: `${color}10`, color }}>
    {name}
  </span>
);

const LabelWithTooltip: React.FC<Props> = ({ color, name, noMaxWidth = false }) => {
  if (noMaxWidth) {
    return <Label color={color} name={name} />;
  }

  return (
    <OverlayTrigger placement="top" delay={{ show: 400, hide: 100 }} overlay={<Tooltip>{name}</Tooltip>}>
      <span className="max-w-40 m-1">
        <Label color={color} name={name} />
      </span>
    </OverlayTrigger>
  );
};

export default LabelWithTooltip;
