import { Quill } from 'react-quill';
import BlotFormatter, { ImageSpec } from 'quill-blot-formatter';

const useBlotFormatter = () => {
  Quill.register('modules/blotFormatter', BlotFormatter, true);

  return {
    modules: {
      blotFormatter: {
        specs: [ImageSpec],
      },
    },
  };
};

export default useBlotFormatter;
