import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import BloomAvatar from '../bloomAvatar/BloomAvatar';
import IconButton from '../IconButton';
import { ChildNotificationType } from '../../modules/notifications/notifications.types';

type Props = {
  childNotification: ChildNotificationType;
  isUnread: boolean;
  toggleIsUnread: (_currentState: boolean) => void;
  onChildNotificationClicked?: () => void;
  workspacePathname: string;
};

const NotificationChildItem: React.FC<Props> = ({
  childNotification,
  workspacePathname,
  isUnread,
  toggleIsUnread,
  onChildNotificationClicked,
}) => {
  const intl = useIntl();

  return (
    <div className="d-flex flex-row align-items-center border-bottom border-start ms-8 p-3">
      <BloomAvatar
        name={childNotification.author.firstname}
        size="sm"
        rounded
        imagePath={childNotification.author.images?.profile?.link}
        avatarStatus={childNotification.author.isClosed ? 'closed' : undefined}
      />
      <div className="d-flex flex-column flex-1 justify-content-center ms-2">
        {childNotification.createdForEntity.schemaType === 'Task' ? (
          <Link
            className="fs-6 text-gray-800 text-hover-primary fw-bolder"
            to={`${workspacePathname}/taskboard/task/${childNotification.createdForEntity.id}`}
            onClick={() => {
              if (isUnread) {
                toggleIsUnread(isUnread);
              }
              if (onChildNotificationClicked) {
                onChildNotificationClicked();
              }
            }}
          >
            {childNotification.message}
          </Link>
        ) : (
          <span className="fs-6 text-gray-800 fw-bolder">{childNotification.message}</span>
        )}
        <span className="text-muted text-start fs-9">{moment(childNotification.createdAt).fromNow()}</span>
      </div>
      <div className="ps-4">
        <IconButton
          size="sm"
          variant="secondary"
          icon={faCircle}
          iconClassName={`${isUnread ? 'text-primary' : 'text-white'}`}
          onClick={() => toggleIsUnread(isUnread)}
          title={intl.formatMessage({ id: isUnread ? 'Mark as read' : 'Mark as unread' })}
        />
      </div>
    </div>
  );
};
export default NotificationChildItem;
