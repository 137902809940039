export const breakPointOrderList = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

// Coming from bootstrap 5 breakPoints
export const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
} as const;

export type WindowSizeType = keyof typeof breakPoints;
