import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { LabelType } from '../taskboard/Taskboard.types';
import Label from './Label';

type Props = {
  allLabels: LabelType[];
};

const AllLabelsPopup: React.FC<Props> = ({ allLabels }) => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 400, hide: 100 }}
    overlay={
      <Popover id="popover-positioned-top">
        <Popover.Body>
          {allLabels.map((label) => (
            <Label key={label._id} name={label.name} color={label.color} noMaxWidth />
          ))}
        </Popover.Body>
      </Popover>
    }
  >
    <span className="m-1">
      <Label color="#369bff" name="..." noMaxWidth />
    </span>
  </OverlayTrigger>
);

export default AllLabelsPopup;
