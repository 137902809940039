import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Accordion, Alert, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { RootState } from '../../../setup';
import orgService from '../../modules/org/OrgService';
import { Assessment } from '../../pages/Growth/DDInstances.types';
import { stringCapitalize, toAbsoluteUrl } from '../../shared/helperFunctions';
import { useParamsFromPath } from '../../shared/hooks';
import constants from '../../shared/consts';
import { takeAssessmentPagePathname } from '../../routing/router.service';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TakeAssessmentDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [assessments, setAssessments] = useState<{ [ddType: string]: Array<Assessment> }>({});
  const [generalError, setGeneralError] = useState<string | null>(null);

  const { orgId, programId } = useParamsFromPath<{ orgId: string; programId?: string }>([
    constants.routs.programRoot,
    constants.routs.teamsRoot,
  ]);

  const currentWorkspace = useSelector<RootState, RootState['currentWorkspace']>((state) => state.currentWorkspace);

  const { orgOrProgramId } = useMemo(() => {
    const isProgramWorkspace = currentWorkspace.sharedWithOrgs.length > 0;
    const parentOrg = isProgramWorkspace ? currentWorkspace.sharedWithOrgs[0] : currentWorkspace.ownerOrgs[0];

    return {
      orgOrProgramId: parentOrg._id,
    };
  }, [currentWorkspace.ownerOrgs, currentWorkspace.sharedWithOrgs]);

  const getOrgAssessments = async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const orgAssessments = await orgService.getOrgAssessmentsSortedAndGroupedByDDType(orgOrProgramId);
        if (orgAssessments) setAssessments(orgAssessments);
      } catch (err: any) {
        setGeneralError(`Failed to get forms due to: ${err.message}`);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && orgOrProgramId && assessments) getOrgAssessments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setAssessments({});
    if (isOpen && orgOrProgramId) {
      getOrgAssessments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgOrProgramId]);

  return (
    <Offcanvas show={isOpen} onHide={onClose} placement="end" className="w-md-50 w-100">
      <Offcanvas.Header closeButton className="border-bottom">
        <img alt="Bloom" src={toAbsoluteUrl('/media/logos/bloom-flower.png')} width={48} />
        <Offcanvas.Title className="text-bloom-dark-blue fs-2 flex-grow-1">
          {intl.formatMessage({ id: 'Assessments' })}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="overflow-y-overlay">
        <p className="text-bloom-dark-blue fs-5 fw-bolder mb-1">
          {intl.formatMessage({ id: 'Take assessment description header' })}
        </p>
        <p className="text-muted fs-5">{intl.formatMessage({ id: 'Take assessments description' })}</p>
        <Alert variant="danger" show={Boolean(generalError)} onClose={() => setGeneralError(null)} dismissible>
          {generalError}
        </Alert>

        {isLoading ? (
          <Skeleton count={7} height={60} className="mb-2" containerClassName="w-100" />
        ) : (
          Object.keys(assessments).map((ddType) => (
            <Fragment key={ddType}>
              <p className="text-bloom-dark-blue fs-5 fw-bolder mb-2 mt-4">
                {intl.formatMessage({ id: 'ddType Assessments' }, { ddType: stringCapitalize(ddType) })}
              </p>
              <Accordion alwaysOpen>
                {assessments[ddType].map((assessment, i) => (
                  <div key={assessment._id} className="rounded border border-gray-300 mb-3 overflow-auto">
                    <Accordion.Item
                      eventKey={i.toString()}
                      className="border-top-0 border-bottom-0 border-end-0 rounded-0"
                      style={{ borderLeft: `6px solid ${assessment.developmentDomain.color}` }}
                    >
                      <Accordion.Button className="bg-transparent">
                        <div className="d-flex align-items-center flex-grow-1 pe-3">
                          <p className="text-bloom-dark-blue fs-5 fw-bolder mb-0 flex-grow-1">{assessment.title}</p>
                          <Link
                            to={takeAssessmentPagePathname({
                              orgId,
                              programId,
                              workspaceId: currentWorkspace._id,
                              assessmentShortName: assessment.shortName,
                            })}
                            className="btn btn-light btn-sm"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {intl.formatMessage({ id: 'TOOLBAR_ACTIONS.TAKE_ASSESSMENT' })}
                          </Link>
                        </div>
                      </Accordion.Button>
                      <Accordion.Body className="text-muted fs-5">{assessment.description}</Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))}
              </Accordion>
            </Fragment>
          ))
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TakeAssessmentDrawer;
