import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { Accordion, Alert, Badge, Button, Spinner, AccordionContext } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import NotificationChildItem from './NotificationChildItem';
import AvatarsList from '../AvatarsList';
import { ChildNotificationType, MinimalParentNotificationType } from '../../modules/notifications/notifications.types';
import useChildNotificationsLoader from '../../modules/notifications/hooks/useChildNotificationsLoader';

type Props = {
  eventKey: string;
  parentNotificationId: MinimalParentNotificationType['_id'];
  title: MinimalParentNotificationType['title'];
  message: MinimalParentNotificationType['displayMessage'];
  date: MinimalParentNotificationType['lastChildCreatedAt'];
  workspaceTitle?: string;
  userList: MinimalParentNotificationType['usersInvolved'];
  unreadChildNotifications: Array<ChildNotificationType['_id']>;
  createdForEntityId: MinimalParentNotificationType['createdForEntity']['id'];
  hasDestination?: boolean;
  onToggleIsUnread: (
    _currentState: boolean,
    _parentNotificationId: string,
    _childNotificationId: string,
    _notificationType?: string
  ) => void;
  onMarkAllChildNotificationsAsRead: (_parentNotificationId: string) => void;
  onChildNotificationClicked?: () => void;
  highlightedOnly?: boolean;
  inBell?: boolean;
  workspacePathname: string;
};

const NotificationParentItem: React.FC<Props> = ({
  eventKey,
  parentNotificationId,
  title,
  workspaceTitle,
  message,
  date,
  userList,
  unreadChildNotifications,
  createdForEntityId,
  hasDestination,
  onToggleIsUnread,
  onMarkAllChildNotificationsAsRead,
  highlightedOnly,
  inBell = false,
  onChildNotificationClicked,
  workspacePathname,
}) => {
  const intl = useIntl();

  const { activeEventKey } = useContext(AccordionContext);
  const isExpanded = activeEventKey === eventKey;

  const { childNotifications, childNotificationsCount, loadMore, isLoading, generalError } =
    useChildNotificationsLoader({ parentNotificationId, highlightedOnly });

  useEffect(() => {
    if (isExpanded && childNotifications.length === 0) {
      loadMore();
    }

    if (isExpanded && !isLoading && unreadChildNotifications.length > 0) {
      onMarkAllChildNotificationsAsRead(parentNotificationId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded, isLoading]);

  const unreadNotificationsCount = unreadChildNotifications.length || 0;

  return (
    <Accordion.Item eventKey={eventKey} className="border-left-0 border-right-0">
      <Accordion.Header as="div">
        <div className="flex-grow-1">
          <span className="fs-6 text-gray-800 fw-bolder">{title}</span>
          <div className="text-muted fw-bold fs-6">{message}</div>
          <div className="d-flex mb-3">
            <p className="text-muted fs-8 mb-0 me-3">{moment(date).fromNow()}</p>
            <p className="text-muted fs-8 mb-0 me-3">{workspaceTitle}</p>
          </div>
          <AvatarsList
            list={userList.map((user) => ({
              _id: user._id,
              title: user.firstname,
              imageLink: user.images?.profile?.link,
              avatarStatus: user.isClosed ? 'closed' : undefined,
            }))}
            maxAvatarsToShow={4}
          />
        </div>
        <Badge bg="primary" pill className="mx-2">
          {unreadNotificationsCount > 99 ? '+99' : `${unreadNotificationsCount || ''}`}
        </Badge>
      </Accordion.Header>
      <Accordion.Body className="p-0">
        <Alert variant="danger" show={Boolean(generalError)}>
          {generalError}
        </Alert>
        {childNotifications.map((child) => (
          <NotificationChildItem
            key={child._id}
            workspacePathname={workspacePathname}
            childNotification={child}
            isUnread={unreadChildNotifications.includes(child._id)}
            toggleIsUnread={(currentState) => onToggleIsUnread(currentState, parentNotificationId, child._id)}
            onChildNotificationClicked={onChildNotificationClicked}
          />
        ))}
        <div className="d-flex justify-content-center">
          {inBell
            ? hasDestination && (
                <Link
                  className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                  to={`${workspacePathname}/taskboard/task/${createdForEntityId}`}
                  onClick={() => {
                    if (onChildNotificationClicked) {
                      onChildNotificationClicked();
                    }
                  }}
                >
                  <Button variant="secondary" className="my-4">
                    {intl.formatMessage({ id: 'See more' })}
                  </Button>
                </Link>
              )
            : childNotifications.length < childNotificationsCount && (
                <Button
                  variant="secondary"
                  onClick={loadMore}
                  className="my-4"
                  disabled={isLoading || childNotifications.length >= childNotificationsCount}
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">{intl.formatMessage({ id: 'LOADING_MESSAGE' })}</span>
                    </Spinner>
                  ) : (
                    intl.formatMessage({ id: 'Load more' })
                  )}
                </Button>
              )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default NotificationParentItem;
