import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import * as Realm from 'realm-web';

export default function getGraphQLAppId() {
  return process.env.REACT_APP_GRAPHQL_APP_ID || '';
}

interface ClientMap {
  [name: string]: ApolloClient<unknown>;
}

export type ApolloBloomClient = ApolloClient<unknown>;

const appId: String = getGraphQLAppId();

let app: (Realm.App<any, any> & Partial<{ userId: String }>) | undefined;

const apolloClients: ClientMap = {};

async function getValidAccessToken(userId: String, accessHeaderToken: String) {
  if (!app!.currentUser || app!.userId !== userId) {
    app!.userId = userId;
    const credentials = Realm.Credentials.function({
      accessToken: accessHeaderToken,
    });
    await app!.logIn(credentials);
  } else if (app?.currentUser.state !== Realm.UserState.Active) {
    await app!.currentUser.refreshCustomData();
  }
  return app!.currentUser!.accessToken;
}

export async function getApolloClient(userId: String, accessHeaderToken: String) {
  const key = `${userId}--${accessHeaderToken}`;
  if (apolloClients[key]) return apolloClients[key];
  if (!app) {
    app = new Realm.App(appId.toString());
  }
  const client = new ApolloClient({
    link: new HttpLink({
      uri: `https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/${appId}/graphql`,
      // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
      // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
      // access token before sending the request.
      fetch: async (uri, options) => {
        const accessToken = await getValidAccessToken(userId, accessHeaderToken);
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        options.headers.Authorization = `Bearer ${accessToken}`;
        return fetch(uri, options);
      },
    }),
    cache: new InMemoryCache(),
  });

  apolloClients[key] = client;
  return client;
}
