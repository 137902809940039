import React from 'react';
import { TourProvider } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import useScreenSize from '../../modules/dimensions/useScreenSize';
import '@reactour/popover/dist/index.css';

const RADIUS = 9;

type Props = { children: React.ReactElement };

const ProductTour: React.FC<Props> = ({ children }) => {
  const { isScreenSmallerThanOrEqual } = useScreenSize();

  const disableBody = (target: Element | null) => {
    if (target) disableBodyScroll(target);
  };
  const enableBody = (target: Element | null) => {
    if (target) enableBodyScroll(target);
  };

  const getArrowStyles = (
    position: 'top' | 'right' | 'bottom' | 'left',
    verticalAlign: 'top' | 'bottom',
    horizontalAlign: 'left' | 'right'
  ) => {
    const oppositeSide = {
      top: 'bottom',
      bottom: 'top',
      right: 'left',
      left: 'right',
    };
    const width = 16;
    const height = 12;
    const color = 'white';
    const isVertical = position === 'top' || position === 'bottom';
    const spaceFromSide = 10;

    const arrowStyles = {
      [`--rtp-arrow-${isVertical ? oppositeSide[horizontalAlign] : verticalAlign}`]: `${height + spaceFromSide}px`,
      [`--rtp-arrow-${oppositeSide[position]}`]: `${-height + 2}px`,
      [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${width / 2}px solid transparent`,
      [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${width / 2}px solid transparent`,
      [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
    };
    return arrowStyles;
  };

  return (
    <TourProvider
      showDots={false}
      showBadge={false}
      showCloseButton={false}
      showNavigation={false}
      steps={[]}
      styles={{
        popover: (base: any, state: any) => ({
          ...base,
          borderRadius: RADIUS,
          padding: 15,
          maxWidth: isScreenSmallerThanOrEqual('sm') ? 350 : 500,
          ...getArrowStyles(state.position, state.verticalAlign, state.horizontalAlign),
        }),
        maskArea: (base) => ({ ...base, rx: RADIUS }),
      }}
      disableInteraction
      onClickMask={() => {}}
      onClickHighlighted={(e) => {
        e.stopPropagation();
      }}
      afterOpen={disableBody}
      beforeClose={enableBody}
    >
      {children}
    </TourProvider>
  );
};

export default ProductTour;
