import React, { useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CreateTaskDrawer from '../../../../../components/createTaskDrawer/CreateTaskDrawer';
import { ValueOf } from '../../../../../shared/types';
import constants from '../../../../../shared/consts';
import { RootState } from '../../../../../../setup';
import IconButton from '../../../../../components/IconButton';
import useScreenSize from '../../../../../modules/dimensions/useScreenSize';

type CreateBtnWithMenuProps = {
  handleShowTaskDrawer: (_isProgramTask: ValueOf<typeof constants.taskCreationTypes>) => void;
};

const CreateBtnWithMenu: React.FC<CreateBtnWithMenuProps> = ({ handleShowTaskDrawer }) => {
  const intl = useIntl();
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const { isScreenSmallerThanOrEqual } = useScreenSize();
  const isMinimalButton = isScreenSmallerThanOrEqual('lg');

  return (
    <>
      <div ref={ref}>
        <IconButton
          size="sm"
          icon={faPlus}
          iconSize="lg"
          className="text-white"
          onClick={() => setShowMenu((prevState) => !prevState)}
          title={intl.formatMessage({ id: 'TOOLBAR_ACTIONS.CREATE' })}
        >
          {!isMinimalButton ? intl.formatMessage({ id: 'TOOLBAR_ACTIONS.CREATE' }) : null}
        </IconButton>
      </div>

      <Overlay target={ref.current} placement="bottom" show={showMenu} rootClose onHide={() => setShowMenu(false)}>
        <Popover>
          <Popover.Body className="p-3">
            <Button
              variant="light"
              className="w-100 mb-2"
              onClick={() => {
                setShowMenu(false);
                handleShowTaskDrawer(constants.taskCreationTypes.TEAM_TASKS);
              }}
            >
              {intl.formatMessage({ id: 'TOOLBAR_ACTIONS.TASKS_FOR_THIS_WORKSPACE' })}
            </Button>
            <Button
              variant="light"
              className="w-100"
              onClick={() => {
                setShowMenu(false);
                handleShowTaskDrawer(constants.taskCreationTypes.PROGRAM_TASKS);
              }}
            >
              {intl.formatMessage({ id: 'TOOLBAR_ACTIONS.TASKS_FOR_TEAMS' })}
            </Button>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

const CreateBtnWithoutMenu: React.FC<CreateBtnWithMenuProps> = ({ handleShowTaskDrawer }) => {
  const intl = useIntl();
  const { isScreenSmallerThanOrEqual } = useScreenSize();
  const isMinimalButton = isScreenSmallerThanOrEqual('lg');

  return (
    <IconButton
      size="sm"
      icon={faPlus}
      iconSize="lg"
      className="text-white"
      onClick={() => handleShowTaskDrawer(constants.taskCreationTypes.TEAM_TASKS)}
      title={intl.formatMessage({ id: 'TOOLBAR_ACTIONS.CREATE_TASK' })}
    >
      {!isMinimalButton ? intl.formatMessage({ id: 'TOOLBAR_ACTIONS.CREATE_TASK' }) : null}
    </IconButton>
  );
};

const CreateTasksBtn: React.FC = () => {
  const currentWorkspace = useSelector<RootState, RootState['currentWorkspace']>(
    (state) => state.currentWorkspace,
    shallowEqual
  );

  const [show, setShow] = useState(false);
  const [taskType, setTaskType] = useState<undefined | ValueOf<typeof constants.taskCreationTypes>>();

  const handleShowTaskDrawer = (_taskType: ValueOf<typeof constants.taskCreationTypes>) => {
    setTaskType(_taskType);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const isProgramWorkspace = currentWorkspace.ownerOrgs?.[0]?.type === constants.orgTypes.PROGRAM;
  return (
    <>
      {isProgramWorkspace ? (
        <CreateBtnWithMenu handleShowTaskDrawer={handleShowTaskDrawer} />
      ) : (
        <CreateBtnWithoutMenu handleShowTaskDrawer={handleShowTaskDrawer} />
      )}
      <CreateTaskDrawer show={show} onHide={handleClose} taskType={taskType!} />
    </>
  );
};

export default CreateTasksBtn;
