import _ from 'lodash';
import { request } from '../../shared/http.service';
import { WorkspaceType, OrgModel, Invitation, WorkspaceWithTasksCount } from './org.types';
import { BloomSpace } from '../../components/CreateModal/createModal.types';
import { Assessment } from '../../pages/Growth/DDInstances.types';
import { WorkspaceUnreadNotificationCountsMapType } from '../notifications/notifications.types';

const orgService = {
  createOrg: async ({
    name,
    description,
    shortName,
    type,
    parentOrgId,
  }: {
    name: string;
    description: string;
    img: string;
    shortName: string;
    type: BloomSpace;
    parentOrgId?: string; // This will be used in case of creating a program only for now (04-Jan-2022)
  }) => {
    const res = await request({
      url: '/orgs',
      data: { name, description, shortName, type, parentOrgId },
      method: 'post',
    });
    return res?.data?.data?.org;
  },

  getOrg: async (orgId: string) => {
    const res = await request({ url: `/orgs/${orgId}`, method: 'get' });
    return res.data.data.org as OrgModel<WorkspaceType>;
  },

  updateOrgImg: async (orgId: string, img: string) => {
    const res = await request({ url: `/orgs/${orgId}/logo`, data: { file: img }, method: 'put' });
    return res.data.data.org as OrgModel;
  },

  getOrgWorkspaces: (org: any) => org.workspaces,

  checkIsOrgSelected: (currentWorkspaceId: string, workspaces: any[]) =>
    workspaces.some((workspace) => workspace._id === currentWorkspaceId),

  getOwnWorkspaces: (workspaces: WorkspaceType[], orgId: string) =>
    workspaces.filter((w) => w.ownerOrgs?.[0]?._id === orgId),

  getTeamsWorkspaces: (workspaces: WorkspaceType[], orgId: string) =>
    workspaces.filter((w) => w.ownerOrgs?.[0]?._id !== orgId),

  getTeamsOrgs: (workspaces: WorkspaceType[], orgId: string) =>
    _.uniq(workspaces.filter((w: any) => w.ownerOrgs[0] !== orgId).map((w: any) => w.ownerOrgs[0])),

  countProgramsAndTheirTeams: (orgs: OrgModel[]): { programsCount: number; teamsCount: number } => {
    if (!orgs) return { programsCount: 0, teamsCount: 0 };

    const teamsCount = orgs.reduce((sum, { workspaces }) => sum + workspaces.length, 0);
    return { programsCount: orgs.length, teamsCount };
  },

  updateOrg: async (orgId: string, updatedValues: Partial<OrgModel>) => {
    const res = await request({ url: `/orgs/${orgId}`, data: updatedValues, method: 'put' });
    return res.data.data.org as OrgModel;
  },

  getInvitedMembers: (orgId: string): Promise<Invitation[]> =>
    request({ url: `/orgs/${orgId}/invited-members`, method: 'get' }).then(({ data }: any) => data.data.invitedMembers),

  getInvitedTeams: (orgId: string): Promise<Invitation[]> =>
    request({ url: `/orgs/${orgId}/invited-teams`, method: 'get' }).then(({ data }: any) => data.data.invitedTeams),

  inviteMember: async (orgId: string, userEmailToBeInvited: string, redirectUrl?: string) => {
    const user = {
      email: userEmailToBeInvited,
      role: 'USER',
    };
    const res = await request({
      url: `/orgs/${orgId}/invitations`,
      data: { user, redirectUrl },
      method: 'post',
    });
    return res.data.data.invitation as Invitation;
  },

  isShortNameValid: async (shortName?: string) => {
    if (!shortName) return false;
    const res = await request({ url: `/orgs/validate-short-name/${shortName}`, method: 'get' });
    const { isShortNameValid } = res.data.data;
    return isShortNameValid;
  },

  cancelInvitation: async (orgId: string, invitationId: string) => {
    const res = await request({
      url: `/orgs/${orgId}/invitations/${invitationId}`,
      method: 'delete',
    });
    const { invitation } = res.data.data;
    return invitation;
  },

  activateMember: async (orgId: string, userId: string) => {
    const res = await request({
      url: `/orgs/${orgId}/members/${userId}/activate`,
      method: 'put',
    });
    const { org } = res.data.data;
    return org.members;
  },

  deactivateMember: async (orgId: string, userId: string) => {
    const res = await request({
      url: `/orgs/${orgId}/members/${userId}/deactivate`,
      method: 'put',
    });
    const { org } = res.data.data;
    return org.members;
  },

  leaveTeam: async (orgId: string, userId: string) => {
    const res = await request({
      url: `/orgs/${orgId}/members/${userId}/leave-team`,
      method: 'put',
    });
    const { org } = res.data.data;
    return org as OrgModel;
  },

  transferTeamOwnership: async (orgId: string, newOwnerId: string) => {
    const res = await request({
      url: `/orgs/${orgId}/members/${newOwnerId}/transfer-ownership`,
      method: 'put',
    });
    const { org } = res.data.data;
    return org as OrgModel;
  },

  removeOrgImg: async (orgId: string) => {
    const res = await request({ url: `/orgs/${orgId}/logo`, method: 'delete' });
    const { org } = res.data.data;
    return org as OrgModel;
  },

  filterOutChildOrgs: (orgs: OrgModel<WorkspaceType>[]) => {
    const childOrgs = orgs.reduce<string[]>(
      (acc, cur) => [...acc, ...cur.childOrgs.map((childOrg) => childOrg._id)],
      []
    );
    return orgs.filter((org) => !childOrgs.includes(org._id));
  },

  inviteTeam: async (orgId: string, teamOwnerEmail: string, joiningAs?: string, redirectUrl?: string) => {
    const user = {
      email: teamOwnerEmail,
    };
    const res = await request({
      url: `/orgs/${orgId}/invite-to-program`,
      data: { user, joiningAs, redirectUrl },
      method: 'post',
    });
    return res.data.data.invitation as Invitation;
  },

  resendInvitation: (orgId: string, invitationId: string) =>
    request({
      url: `/orgs/${orgId}/invitations/${invitationId}/resend`,
      method: 'post',
    }),

  getOrgTeamWorkspaces: async (orgId: string) => {
    const res = await request({ url: `/orgs/${orgId}/workspaces`, method: 'get' });
    const { workspaces } = res.data.data;
    return orgService.getTeamsWorkspaces(workspaces, orgId);
  },

  deleteOrg: async (orgId: string) => {
    const res = await request({ url: `/orgs/${orgId}`, method: 'delete' });
    return res.data.data as OrgModel;
  },

  getWorkspacesNotificationCounts: (workspaces: WorkspaceType[]) =>
    workspaces.reduce<WorkspaceUnreadNotificationCountsMapType>(
      (acc, workspace) => ({
        ...acc,
        [workspace._id]: workspace.unreadNotificationsCount,
      }),
      {}
    ),
  // TODO: right now in BE it's called forms but in FE it's called assessments we need to use one of them
  getOrgAssessments: async (orgId: string) => {
    const res = await request({ url: `/orgs/${orgId}/enabled-forms`, method: 'get' });
    const { enabledForms } = res.data.data;
    return enabledForms as Array<Assessment>;
  },
  getOrgAssessmentsSortedAndGroupedByDDType: async (orgId: string) => {
    const orgAssessments = await orgService.getOrgAssessments(orgId);
    const assessmentsGroupedByDDType = orgAssessments.reduce(
      (acc: { [ddType: string]: Array<Assessment> }, assessment) => {
        const ddType = assessment.developmentDomain.type;
        acc[ddType] = acc[ddType] ?? [];
        acc[ddType].push(assessment);
        return acc;
      },
      {}
    );

    // eslint-disable-next-line guard-for-in
    for (const ddType in assessmentsGroupedByDDType) {
      assessmentsGroupedByDDType[ddType] = _.sortBy(assessmentsGroupedByDDType[ddType], 'title');
    }

    return assessmentsGroupedByDDType;
  },
  enableAssessment: async (orgId: string, formId: string) => {
    await request({ url: `/orgs/${orgId}/forms/${formId}/enable-form`, method: 'put' });
  },
  disableAssessment: async (orgId: string, formId: string) => {
    await request({ url: `/orgs/${orgId}/forms/${formId}/disable-form`, method: 'put' });
  },

  moveTeamsBetweenPrograms: async ({
    currentOrgId,
    destinationOrgId,
    workspaceIdList,
  }: {
    currentOrgId: OrgModel['_id'];
    destinationOrgId: OrgModel['_id'];
    workspaceIdList: Array<WorkspaceType['_id']>;
  }) => {
    const res = await request({
      url: `/orgs/${currentOrgId}/move-shared-workspaces`,
      method: 'put',
      data: { destinationOrgId, workspaceIdList },
    });
    return res.data.data as WorkspaceType[];
  },
  getProgramPeopleAndTeams: async (orgId: string) => {
    const res = await request({ url: `/orgs/${orgId}/program-people-teams`, method: 'get' });
    return res.data.data.org as OrgModel<WorkspaceWithTasksCount>;
  },
  updateOrgSubscriptionPlan: async ({
    orgId,
    planId,
    subscriptionId,
    priceId,
    promotionCodeId,
  }: {
    orgId: string;
    planId: string;
    subscriptionId: string;
    priceId: string;
    promotionCodeId?: string;
  }) => {
    const data = {
      planId,
      subscriptionId,
      priceId,
      promotionCodeId,
    };
    const res = await request({ url: `/orgs/${orgId}/subscription`, data, method: 'put' });
    return res.data.orgSubscription as OrgModel['subscription'];
  },
  initializeOrgSubscriptionPlan: async ({ orgId }: { orgId: string }) => {
    const res = await request({ url: `/orgs/${orgId}/init-subscription`, method: 'put' });
    return res.data.orgSubscription as OrgModel['subscription'];
  },
  toggleSubscriptionCancelationState: async ({
    orgId,
    subscriptionId,
    newState,
  }: {
    orgId: string;
    subscriptionId: string;
    newState: boolean;
  }) => {
    const data = {
      subscriptionId,
      newState,
    };
    await request({ url: `/orgs/${orgId}/subscription/toggle-auto-renew-state`, data, method: 'put' });
  },
};

export default orgService;
