import React, { useState } from 'react';
import { Accordion, Alert, Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import NotificationParentItem from './NotificationParentItem';
import noNotifications from '../../../assets/empty-notification.png';
import { OrgModel } from '../../modules/org/org.types';
import useOrgNotificationsLoader from '../../modules/notifications/hooks/useOrgNotificationsLoader';
import { getWorkspacePathname } from '../../routing/router.service';

type Props = {
  orgId: OrgModel['_id'];
  parentOrgId?: OrgModel['_id'];
  inBell?: boolean;
  isOnlyUnread?: boolean;
  onChildNotificationClick: () => void;
};

const OrgNotificationsList: React.FC<Props> = ({
  orgId,
  parentOrgId,
  inBell,
  isOnlyUnread,
  onChildNotificationClick,
}) => {
  const {
    notifications,
    isLoading,
    generalError,
    handleMarkAllChildrenAsRead,
    handleToggleChildUnreadState,
    showProgramNotificationsAlert,
    setShowProgramNotificationsAlert,
  } = useOrgNotificationsLoader({ orgId, isOnlyUnread });

  const [selectedNotificationId, setSelectedNotificationId] = useState<AccordionEventKey>();
  const intl = useIntl();

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-20">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{intl.formatMessage({ id: 'LOADING_MESSAGE' })}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <Alert
        variant="info"
        show={showProgramNotificationsAlert}
        dismissible
        className="m-3"
        onClose={() => setShowProgramNotificationsAlert(false)}
      >
        {intl.formatMessage({
          id: 'To see program notifications visit any program by clicking it on the sidebar in the manage programs dropdown and then open the bell from there',
        })}
      </Alert>
      <Alert variant="danger" show={Boolean(generalError)} className="m-3">
        {generalError}
      </Alert>
      {notifications.length > 0 ? (
        <>
          <Accordion
            defaultActiveKey={selectedNotificationId}
            onSelect={(eventKey) => setSelectedNotificationId(eventKey)}
          >
            {notifications.map((parentNotification) => (
              <NotificationParentItem
                key={parentNotification._id}
                eventKey={parentNotification._id}
                parentNotificationId={parentNotification._id}
                title={parentNotification.title}
                workspaceTitle={parentNotification.workspace.computedTitle}
                message={parentNotification.displayMessage}
                unreadChildNotifications={parentNotification.unread}
                date={parentNotification.lastChildCreatedAt}
                userList={parentNotification.usersInvolved}
                createdForEntityId={parentNotification.createdForEntity.id}
                hasDestination={parentNotification.createdForEntity.schemaType === 'Task'}
                onToggleIsUnread={(state, parentNotificationId, childNotificationId) => {
                  handleToggleChildUnreadState({
                    currentUnreadState: state,
                    parentId: parentNotificationId,
                    childId: childNotificationId,
                    workspaceId: parentNotification.workspace._id,
                  });
                }}
                onMarkAllChildNotificationsAsRead={() => {
                  handleMarkAllChildrenAsRead({
                    parentId: parentNotification._id,
                    workspaceId: parentNotification.workspace._id,
                    unreadChildrenCount: parentNotification.unread.length,
                  });
                }}
                highlightedOnly={isOnlyUnread}
                inBell={inBell}
                onChildNotificationClicked={onChildNotificationClick}
                workspacePathname={getWorkspacePathname({
                  workspaceId: parentNotification.workspace._id,
                  orgId: parentOrgId || orgId,
                  programId: parentOrgId ? orgId : undefined,
                })}
              />
            ))}
          </Accordion>
          <div className="w-100 d-flex align-items-center justify-content-center p-7">
            <span>{intl.formatMessage({ id: 'Yay! You have seen it all' })}</span>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-20">
          <img src={noNotifications} alt="No notifications found" />
        </div>
      )}
    </>
  );
};

export default OrgNotificationsList;
