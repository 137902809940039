import React from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { checkIsMenuActive } from '../../../shared/helperFunctions';
import KTSVG from '../../../components/KTSVG/KTSVG';
import { RootState } from '../../../../setup';

type Props = {
  to: string;
  title: string;
  icon?: string;
  badge?: number | string;
  isActive?: boolean;
  isOpen?: boolean;
  dropdownMenu?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: () => void;
};

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  badge,
  isActive: forceIsActive,
  isOpen = false,
  dropdownMenu,
  onClick,
}) => {
  const { pathname } = useLocation();

  const { sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const isActive = forceIsActive ?? checkIsMenuActive(pathname, to);

  return (
    <Accordion
      defaultActiveKey={isOpen ? '0' : '1'}
      className={`ms-3 mt-2 ${sidebar.isSidebarOpen ? 'w-250px' : 'w-65px'}`}
    >
      <Accordion.Item eventKey="0" className="border-0 ">
        <Accordion.Button as="div" className="ps-3 pe-2 py-3 shadow-none bg-white" onClick={onClick}>
          {icon && (
            <KTSVG
              path={icon}
              badge={badge}
              className={`svg-icon svg-icon-1 ${isActive ? 'svg-icon-primary' : 'svg-icon-bloom-dark-blue'} ms-3 me-0`}
            />
          )}
          <p
            className={`${isActive ? 'text-primary' : 'text-bloom-dark-blue'} ${
              sidebar.isSidebarOpen ? 'opacity-1 ms-4' : 'opacity-0 ms-0'
            } fs-5 fw-bolder mb-0 text-truncate smooth-hide`}
          >
            {title}
          </p>
          {dropdownMenu}
        </Accordion.Button>
        <Accordion.Body className="p-0">{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AsideMenuItemWithSub;
