import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import LabelsContainer from '../labels/LabelsContainer';
import TaskLabelList from './TaskLabelList';
import LabelAddEdit from './LabelAddEdit';
import { LabelsObject, LabelType } from '../taskboard/Taskboard.types';
import DebouncedInput from '../DebouncedInput/DebouncedInput';
import './style.scss';

type Props = {
  taskLabels: string[];
  allLabels: LabelType[];
  onToggleLabel: (_label: LabelType) => void;
  onCreateLabel: (_name: string, _color: string) => void;
  onEditLabel: (_labelId: string, _name: string, _color: string) => void;
  onDeleteLabel: (_id: string) => void;
  disabled: boolean;
  readOnly?: boolean;
  labelsObject: LabelsObject;
  tabIndex?: number;
};

const TaskLabels: React.FC<Props> = ({
  taskLabels,
  allLabels,
  onToggleLabel,
  onCreateLabel,
  onEditLabel,
  onDeleteLabel,
  disabled,
  readOnly,
  labelsObject,
  tabIndex,
}) => {
  const intl = useIntl();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [labels, setLabels] = useState<string[]>(taskLabels || []);
  const [searchString, setSearchString] = useState('');
  const [selectedLabel, setSelectedLabel] = useState<LabelType | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [showLabelAddEdit, setShowLabelAddEdit] = useState(false);

  useEffect(() => {
    setLabels(taskLabels || []);
  }, [allLabels, taskLabels]);

  useEffect(() => {
    if (showLabelAddEdit) scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [showLabelAddEdit, selectedLabel]);

  const filteredLabels = useMemo(
    () => allLabels.filter((label) => label.name.toLowerCase().includes(searchString.toLowerCase())),
    [searchString, allLabels]
  );

  return (
    <div className="d-flex">
      {!readOnly && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 400, hide: 100 }}
          overlay={<Tooltip>{intl.formatMessage({ id: 'Manage labels' })}</Tooltip>}
        >
          <Button
            variant="secondary"
            type="button"
            size="sm"
            aria-label={intl.formatMessage({ id: 'Manage labels' })}
            aria-hidden="true"
            className="my-1 me-3 p-1 manage-labels-btn"
            disabled={disabled}
            onClick={() => setShowModal(true)}
            tabIndex={tabIndex}
          >
            <i className="bi bi-gear fs-1 p-0" />
          </Button>
        </OverlayTrigger>
      )}

      {labels.length > 0 ? <LabelsContainer labelsObject={labelsObject} labelIds={labels} truncate={false} /> : null}
      <Modal
        size="lg"
        centered
        scrollable
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setShowLabelAddEdit(false);
          setSelectedLabel(undefined);
        }}
        aria-labelledby="labels-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({ id: 'Add labels to the task' })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <DebouncedInput
              value={searchString}
              onChange={(value) => setSearchString(value)}
              placeholder={intl.formatMessage({ id: 'Search...' })}
              className="form-control mb-6"
              isClearable
              autoFocus
            />
          </Row>
          <Row>
            <TaskLabelList
              allLabels={filteredLabels}
              selectedLabels={labels}
              onToggleLabel={onToggleLabel}
              onSelectForEdit={(label) => {
                setSelectedLabel(label);
                setShowLabelAddEdit(true);
              }}
            />
          </Row>
          <Row className="mt-5">
            <Button
              variant="secondary"
              type="button"
              size="sm"
              className={`py-3 px-1 ${showLabelAddEdit ? 'text-danger' : ''}`}
              onClick={() => {
                setShowLabelAddEdit(!showLabelAddEdit);
                setSelectedLabel(undefined);
              }}
            >
              {showLabelAddEdit ? (
                <>
                  <i className="bi bi-x fs-1 p-0 text-danger" />
                  {intl.formatMessage({ id: 'CANCEL' })}
                </>
              ) : (
                <>
                  <i className="bi bi-plus fs-1 p-0" />
                  {intl.formatMessage({ id: 'Create label' })}
                </>
              )}
            </Button>
          </Row>
          {showLabelAddEdit && (
            <Row ref={scrollRef}>
              <LabelAddEdit
                label={selectedLabel}
                onCreateLabel={onCreateLabel}
                onEditLabel={onEditLabel}
                onDeleteLabel={onDeleteLabel}
                onCancel={() => {
                  setShowLabelAddEdit(false);
                  setSelectedLabel(undefined);
                }}
              />
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TaskLabels;
