import React, { useMemo } from 'react';
import Label from './Label';
import AllLabelsPopup from './AllLabelsPopup';
import { LabelsObject, LabelType } from '../taskboard/Taskboard.types';

type Props = {
  labelIds: string[];
  truncate?: boolean;
  labelsObject: LabelsObject;
};

const LabelsContainer: React.FC<Props> = ({ labelIds, truncate = true, labelsObject }) => {
  const labels: LabelType[] = useMemo(
    () => labelIds.map((labelId) => labelsObject[labelId]).filter((label) => label),
    [labelsObject, labelIds]
  );

  return (
    <div className="d-flex flex-wrap w-100">
      {!truncate && labels.map((label) => <Label key={label._id} name={label.name} color={label.color} />)}
      {truncate && labels.slice(0, 4).map((label) => <Label key={label._id} name={label.name} color={label.color} />)}
      {truncate && labels.length > 4 && <AllLabelsPopup allLabels={labels} />}
    </div>
  );
};

export default LabelsContainer;
