import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { WorkspaceType } from '../org/org.types';
import {
  MinimalParentNotificationType,
  DiscourseNotificationType,
  NotificationsResponseType,
  isParentNotification,
  WorkspaceUnreadNotificationCountsMapType,
} from './notifications.types';

interface NotificationsReducerState {
  highlightedChildrenCount: number;
  allNotifications: Array<MinimalParentNotificationType | DiscourseNotificationType>;
  bellNotifications: Array<MinimalParentNotificationType | DiscourseNotificationType>;
  allNotificationsCount: number;
  bellNotificationsCount: number;
  parentNotificationsCount: number;
  workspaceUnreadNotificationCountsMap: WorkspaceUnreadNotificationCountsMapType;
  unseenAndPinsCount: number;
  unseenCount: number;
  isLoaded: boolean;
}

const initialState: NotificationsReducerState = {
  highlightedChildrenCount: 0,
  allNotifications: [],
  bellNotifications: [],
  allNotificationsCount: 0,
  bellNotificationsCount: 0,
  parentNotificationsCount: 0,
  workspaceUnreadNotificationCountsMap: {},
  unseenAndPinsCount: 0,
  unseenCount: 0,
  isLoaded: false,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setAllNotifications(state: NotificationsReducerState, action: PayloadAction<NotificationsResponseType>) {
      return {
        ...state,
        highlightedChildrenCount: action.payload.highlightedChildrenCount,
        allNotifications: action.payload.notifications,
        allNotificationsCount: action.payload.total,
        parentNotificationsCount: action.payload.parentNotificationsCount,
        unseenAndPinsCount: action.payload.unseenAndPinsCount,
        unseenCount: action.payload.unseenCount,
      };
    },
    setBellNotifications(state: NotificationsReducerState, action: PayloadAction<NotificationsResponseType>) {
      return {
        ...state,
        highlightedChildrenCount: action.payload.highlightedChildrenCount,
        bellNotifications: action.payload.notifications,
        bellNotificationsCount: action.payload.total,
        parentNotificationsCount: action.payload.parentNotificationsCount,
        unseenAndPinsCount: action.payload.unseenAndPinsCount,
        unseenCount: action.payload.unseenCount,
      };
    },
    resetAllNotifications(state: NotificationsReducerState) {
      return {
        ...state,
        allNotifications: [],
        allNotificationsCount: 0,
      };
    },
    resetBellNotifications(state: NotificationsReducerState) {
      return {
        ...state,
        bellNotifications: [],
        bellNotificationsCount: 0,
      };
    },
    readAllChildren(
      state: NotificationsReducerState,
      action: PayloadAction<{
        parentNotificationId: string;
      }>
    ) {
      const newState = cloneDeep(state);

      const allIdx = newState.allNotifications.findIndex(
        (notification) => isParentNotification(notification) && notification._id === action.payload.parentNotificationId
      );
      const bellIdx = newState.bellNotifications.findIndex(
        (notification) => isParentNotification(notification) && notification._id === action.payload.parentNotificationId
      );

      let readCount = 0;
      let workspaceId = '';

      if (allIdx > -1) {
        const notification = newState.allNotifications[allIdx];
        if (isParentNotification(notification)) {
          readCount = notification.unread.length;
          notification.unread = [];
          workspaceId = notification.workspace;
        }
      }
      if (bellIdx > -1) {
        const notification = newState.bellNotifications[bellIdx];
        if (isParentNotification(notification)) {
          readCount = notification.unread.length;
          notification.unread = [];
          workspaceId = notification.workspace;
        }
      }
      newState.workspaceUnreadNotificationCountsMap[workspaceId] -= readCount;

      return newState;
    },

    mutateWorkspaceUnreadNotificationsCountBy(
      state: NotificationsReducerState,
      action: PayloadAction<{
        workspaceId: WorkspaceType['_id'];
        mutateBy: number;
      }>
    ) {
      const { workspaceId, mutateBy } = action.payload;

      return {
        ...state,
        workspaceUnreadNotificationCountsMap: {
          ...state.workspaceUnreadNotificationCountsMap,
          [workspaceId]: state.workspaceUnreadNotificationCountsMap[workspaceId] + mutateBy,
        },
      };
    },

    toggleChildRead(
      state: NotificationsReducerState,
      action: PayloadAction<{
        parentNotificationId: string;
        childNotificationId: string;
      }>
    ) {
      const newState = cloneDeep(state);

      let workspaceId = '';

      const allIdx = newState.allNotifications.findIndex(
        (notification) => isParentNotification(notification) && notification._id === action.payload.parentNotificationId
      );
      const bellIdx = newState.bellNotifications.findIndex(
        (notification) => isParentNotification(notification) && notification._id === action.payload.parentNotificationId
      );
      if (allIdx > -1) {
        const notification = newState.allNotifications[allIdx];
        if (isParentNotification(notification)) {
          notification.unread = notification.unread.filter((id: string) => id !== action.payload.childNotificationId);
          workspaceId = notification.workspace;
        }
      }
      if (bellIdx > -1) {
        const notification = newState.bellNotifications[bellIdx];
        if (isParentNotification(notification)) {
          notification.unread = notification.unread.filter((id: string) => id !== action.payload.childNotificationId);
          workspaceId = notification.workspace;
        }
      }

      newState.workspaceUnreadNotificationCountsMap[workspaceId] -= 1;

      return newState;
    },
    toggleChildUnread(
      state: NotificationsReducerState,
      action: PayloadAction<{
        parentNotificationId: string;
        childNotificationId: string;
      }>
    ) {
      const newState = cloneDeep(state);

      let workspaceId = '';

      const allIdx = newState.allNotifications.findIndex(
        (notification) => isParentNotification(notification) && notification._id === action.payload.parentNotificationId
      );
      const bellIdx = newState.bellNotifications.findIndex(
        (notification) => isParentNotification(notification) && notification._id === action.payload.parentNotificationId
      );

      if (allIdx > -1) {
        const notification = newState.allNotifications[allIdx];
        if (isParentNotification(notification)) {
          notification.unread.push(action.payload.childNotificationId);
          workspaceId = notification.workspace;
        }
      }
      if (bellIdx > -1) {
        const notification = newState.bellNotifications[bellIdx];
        if (isParentNotification(notification)) {
          notification.unread.push(action.payload.childNotificationId);
          workspaceId = notification.workspace;
        }
      }
      newState.workspaceUnreadNotificationCountsMap[workspaceId] += 1;

      return newState;
    },
    setUnreadNotificationsCount(
      state: NotificationsReducerState,
      action: PayloadAction<{
        workspaceUnreadNotificationCountsMap: WorkspaceUnreadNotificationCountsMapType;
      }>
    ) {
      return {
        ...state,
        workspaceUnreadNotificationCountsMap: action.payload.workspaceUnreadNotificationCountsMap,
        isLoaded: true,
      };
    },

    decrementUnreadNotificationsCount(
      state: NotificationsReducerState,
      action: PayloadAction<{
        workspaceId: string;
        decrementBy?: number;
      }>
    ) {
      const newState = cloneDeep(state);
      newState.workspaceUnreadNotificationCountsMap[action.payload.workspaceId] -= action.payload.decrementBy ?? 1;

      return newState;
    },

    incrementUnreadNotificationsCount(
      state: NotificationsReducerState,
      action: PayloadAction<{
        workspaceId: string;
      }>
    ) {
      if (state.workspaceUnreadNotificationCountsMap[action.payload.workspaceId]) {
        const newState = cloneDeep(state);
        newState.workspaceUnreadNotificationCountsMap[action.payload.workspaceId] += 1;
        return newState;
      }
      return state;
    },
    toggleDiscourseRead(
      state: NotificationsReducerState,
      action: PayloadAction<{
        workspaceId: string;
        notificationId: number;
      }>
    ) {
      const newState = cloneDeep(state);

      const allIdx = newState.allNotifications.findIndex(
        (notification) => !isParentNotification(notification) && notification.id === action.payload.notificationId
      );
      const bellIdx = newState.bellNotifications.findIndex(
        (notification) => !isParentNotification(notification) && notification.id === action.payload.notificationId
      );

      if (allIdx > -1) {
        const notification = newState.allNotifications[allIdx];
        if (!isParentNotification(notification)) {
          notification.read = true;
        }
      }
      if (bellIdx > -1) {
        const notification = newState.bellNotifications[bellIdx];
        if (!isParentNotification(notification)) {
          notification.read = true;
        }
      }
      newState.workspaceUnreadNotificationCountsMap[action.payload.workspaceId] -= 1;

      return newState;
    },
  },
});

export const {
  setAllNotifications,
  setBellNotifications,
  resetAllNotifications,
  resetBellNotifications,
  mutateWorkspaceUnreadNotificationsCountBy,
  readAllChildren,
  toggleChildRead,
  toggleChildUnread,
  setUnreadNotificationsCount,
  incrementUnreadNotificationsCount,
  decrementUnreadNotificationsCount,
  toggleDiscourseRead,
} = slice.actions;

export default slice.reducer;
