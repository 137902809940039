/* eslint-disable react-hooks/exhaustive-deps */
import { shallowEqual, useSelector } from 'react-redux';
import PageTitle from './pageTitle/PageTitle';
import HeaderControls from './HeaderControls';
import { RootState } from '../../../../setup';
import SidebarToggleButton from '../SidebarToggleButton';
import Logo from '../Logo';

const Header = () => {
  const { header, sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  return (
    <div className="top-header container-fluid d-flex align-items-center ps-3">
      {sidebar.isDrawer && sidebar.isVisible && <SidebarToggleButton isMenuOpen={sidebar.isSidebarOpen} />}

      {!sidebar.isVisible && <Logo />}

      {header.pageTitle.isVisible && <PageTitle />}

      <div className="flex-grow-1" />

      <HeaderControls />
    </div>
  );
};

export default Header;
