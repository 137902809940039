import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrgModel, WorkspaceType } from './org.types';

type CurrentOrg = Pick<OrgModel, '_id' | 'name' | 'logo' | 'childOrgs'> & {
  workspaces: Array<WorkspaceType | string>;
  parentOrgId?: OrgModel['_id'];
  type?: OrgModel['type'];
  subscription?: OrgModel['subscription'];
  isLoaded?: boolean;
};

const initialState: CurrentOrg = {
  _id: '',
  name: '',
  workspaces: [],
  isLoaded: false,
  childOrgs: [],
  type: undefined,
  subscription: undefined,
  parentOrgId: undefined,
};

const slice = createSlice({
  name: 'currentOrg',
  initialState,
  reducers: {
    setCurrentOrg(state: CurrentOrg, action: PayloadAction<{ currentOrg: CurrentOrg }>) {
      return { ...action.payload.currentOrg, isLoaded: true };
    },
  },
});

export const { setCurrentOrg } = slice.actions;

export default slice.reducer;
