import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { RootState } from '../../../../setup';
import useScreenSize from '../../../modules/dimensions/useScreenSize';

const MenuInner = () => {
  const { menuItems } = useSelector<RootState, RootState['layout']['toolbar']>(
    (state) => state.layout.toolbar,
    shallowEqual
  );

  const { pathname } = useLocation();

  const { isScreenSmallerThanOrEqual } = useScreenSize();

  let subMenuItemsCount = 0;

  if (isScreenSmallerThanOrEqual('xs')) {
    subMenuItemsCount = menuItems.length > 2 ? menuItems.length - 1 : 0;
  } else if (menuItems.length > 3) {
    subMenuItemsCount = 2;
  }

  const mainMenuItems = menuItems.slice(0, menuItems.length - subMenuItemsCount);
  const subMenuItems = menuItems.slice(menuItems.length - subMenuItemsCount, menuItems.length);

  return (
    <>
      {mainMenuItems.map((menuItem) => (
        <MenuItem
          key={menuItem.title}
          title={menuItem.title}
          pathKey={menuItem.pathKey}
          name={menuItem.dataTour}
          pathKeySuffix={menuItem.pathKeySuffix}
          isActive={pathname.includes(menuItem.pathKey)}
        />
      ))}
      {subMenuItems.length > 0 && (
        <MenuInnerWithSub isActive={subMenuItems.some((menuItem) => pathname.includes(menuItem.pathKey))} hasArrow>
          {subMenuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.title}
              title={menuItem.title}
              pathKey={menuItem.pathKey}
              name={menuItem.dataTour}
              pathKeySuffix={menuItem.pathKeySuffix}
              isActive={pathname.includes(menuItem.pathKey)}
              isSubMenu
            />
          ))}
        </MenuInnerWithSub>
      )}
    </>
  );
};

export default MenuInner;
