/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommentType, ReplyType } from './comments.types';

interface CommentsReducerState {
  comments: CommentType[];
}

const initialState: CommentsReducerState = {
  comments: [],
};

const slice = createSlice({
  name: 'entityComments',
  initialState,
  reducers: {
    setComments(state: CommentsReducerState, action: PayloadAction<{ comments: CommentType[] }>) {
      state.comments = [...state.comments, ...action.payload.comments];
    },
    addComment(state: CommentsReducerState, action: PayloadAction<{ comment: CommentType }>) {
      state.comments = [action.payload.comment, ...state.comments];
    },
    editComment(state: CommentsReducerState, action: PayloadAction<{ comment: CommentType }>) {
      const index = state.comments.findIndex((c) => c._id === action.payload.comment._id);
      if (index > -1) {
        state.comments[index] = action.payload.comment;
      }
    },
    removeComment(state: CommentsReducerState, action: PayloadAction<{ commentId: string }>) {
      state.comments = state.comments.filter((comment) => comment._id !== action.payload.commentId);
    },
    addReply(state: CommentsReducerState, action: PayloadAction<{ commentId: string; reply: ReplyType }>) {
      const index = state.comments.findIndex((c) => c._id === action.payload.commentId);
      if (index > -1) {
        state.comments[index]?.replies?.push(action.payload.reply);
      }
    },
    removeReply(state: CommentsReducerState, action: PayloadAction<{ commentId: string; replyId: string }>) {
      const index = state.comments.findIndex((c) => c._id === action.payload.commentId);
      if (index > -1) {
        const { replies } = state.comments[index];
        state.comments[index].replies = replies.filter((reply) => reply._id !== action.payload.replyId);
      }
    },
    toggleResolveComment(
      state: CommentsReducerState,
      action: PayloadAction<{ commentId: string; isResolveAction: boolean; resolvedAt: Date }>
    ) {
      const index = state.comments.findIndex((c) => c._id === action.payload.commentId);
      if (index > -1) {
        state.comments[index].isResolved = action.payload.isResolveAction;
        state.comments[index].resolvedAt = action.payload.resolvedAt;
      }
    },
    resetComments(state: CommentsReducerState) {
      state.comments = [];
    },
  },
});

export const {
  setComments,
  addComment,
  editComment,
  removeComment,
  addReply,
  removeReply,
  toggleResolveComment,
  resetComments,
} = slice.actions;

export default slice.reducer;
