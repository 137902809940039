import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MqttClient } from 'mqtt';
import {
  STATUS_CONNECT,
  STATUS_CONNECTING,
  STATUS_CONNECTED,
  STATUS_OFFLINE,
  STATUS_RECONNECT,
  STATUS_RECONNECTING,
  STATUS_END,
  STATUS_ENDED,
  STATUS_ERROR,
  STATUS_CLOSE,
} from './pubSub.constants';

interface pubSubReducerState {
  client?: MqttClient;
  connectionStatus?:
    | typeof STATUS_CONNECTING
    | typeof STATUS_CONNECT
    | typeof STATUS_CONNECTED
    | typeof STATUS_RECONNECT
    | typeof STATUS_RECONNECTING
    | typeof STATUS_ERROR
    | typeof STATUS_OFFLINE
    | typeof STATUS_END
    | typeof STATUS_ENDED
    | typeof STATUS_CLOSE;
  error?: string;
}

const initialState: pubSubReducerState = {};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setPubSub(state: pubSubReducerState, action: PayloadAction<pubSubReducerState>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setPubSub } = slice.actions;

export default slice.reducer;
