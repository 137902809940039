import React from 'react';
import BloomDatePicker from './BloomDatePicker';

type Props = {
  date: Date;
  withoutIcon?: boolean;
};

const BloomReadOnlyDatePicker: React.FC<Props> = ({ date, withoutIcon }) => (
  <BloomDatePicker
    date={date}
    onChange={() => {}} // onChange is not needed here, but putting this to make TS happy only
    readOnly
    withoutIcon={withoutIcon}
  />
);

export default BloomReadOnlyDatePicker;
