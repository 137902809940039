import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { shallowEqual, useSelector } from 'react-redux';
import { Alert, Button, Col, Container, Form, Offcanvas, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useFormik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { Prompt } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import moment from 'moment';
import TaskLabels from '../taskLabels/TaskLabels';
import { RootState } from '../../../setup';
import { MinimalUserModel } from '../../modules/auth/models/UserModel';
import BloomAvatar from '../bloomAvatar/BloomAvatar';
import BloomDatePicker from '../bloomDatePicker/BloomDatePicker';
import BloomReadOnlyDatePicker from '../bloomDatePicker/BloomReadOnlyDatePicker';
import { LabelType, TaskFieldsType } from '../taskboard/Taskboard.types';
import taskboardService from '../taskboard/Taskboard.service';
import constants from '../../shared/consts';
import { ValueOf } from '../../shared/types';
import taskService from '../taskDrawer/task.service';
import Assignee from '../assignee/Assignee';
import { FileRejectionError } from '../filesDropzone/FilesDropzone.types';
import FilesDropzone from '../filesDropzone/FilesDropzone';
import FilesList from '../FilesList';
import EditableTaskDescription from '../taskDrawer/EditableTaskDescription';
import RichTextEditorContent, { RichTextEditorContentable } from '../richTextEditor/RichTextEditorContent';
import useOrgPermissions from '../OrgBilling/useOrgPermissions';

type Props = {
  show: boolean;
  onHide: () => void;
  taskType: ValueOf<typeof constants.taskCreationTypes>;
};

const CreateTaskDrawer: React.FC<Props> = ({ show, onHide, taskType }) => {
  const intl = useIntl();

  const [assignee, setAssignee] = useState<undefined | MinimalUserModel>();
  const [dueDate, setDueDate] = useState<undefined | Date>();
  const [labelIds, setLabelIds] = useState<string[]>([]);
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [descEditorState, setDescEditorState] = useState<RichTextEditorContentable>(new RichTextEditorContent());

  const taskTitleRef = useRef<HTMLInputElement>(null);

  const handlePickerChange = (date: Date) => setDueDate(date);

  const user = useSelector<RootState, RootState['user']>(({ user }) => user, shallowEqual);
  const currentOrg = useSelector<RootState, RootState['currentOrg']>(({ currentOrg }) => currentOrg, shallowEqual);

  const currentWorkspace = useSelector<RootState, RootState['currentWorkspace']>(
    ({ currentWorkspace }) => currentWorkspace,
    shallowEqual
  );

  const labelsObject = useSelector<RootState, RootState['currentWorkspace']['labelsObject']>(
    ({ currentWorkspace: { labelsObject } }) => labelsObject,
    shallowEqual
  );

  const { lists } = useSelector<RootState, RootState['taskboard']>(({ taskboard }) => taskboard, shallowEqual);

  const { canAddTaskAttachments } = useOrgPermissions(currentOrg.subscription);

  const handleAssignToMe = () => {
    onHandleAssignedChange(user);
  };

  const onHandleAssignedChange = (selectedMember: MinimalUserModel) => {
    setAssignee(selectedMember);
  };

  const createTaskSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({ id: 'Title is Required' })),
  });

  const handleToggleLabel = (label: LabelType) => {
    const labelsClone = [...labelIds];
    const labelIndex = labelsClone.findIndex((oldLabelId) => oldLabelId === label._id);
    if (labelIndex > -1) {
      labelsClone.splice(labelIndex, 1);
    } else {
      labelsClone.push(label._id);
    }
    setLabelIds(labelsClone);
  };

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: createTaskSchema,
    onSubmit: async (values) => {
      const taskFields: Partial<TaskFieldsType> = {
        title: values.title,
        desc: descEditorState.cleanHtml,
        assigned: assignee?._id,
        duedate: dueDate ? moment(dueDate).format(constants.dueDateFormatString) : undefined,
        labels: labelIds,
      };
      const createdTask = await taskboardService.createTask({
        task: taskFields,
        listId: lists?.[0]._id,
        taskType,
        orgId: currentOrg?._id,
        mentionIds: descEditorState.mentionIds,
      });

      if (filesToUpload.length < 1) return;

      setIsUploadingAttachment(true);
      try {
        const promises = filesToUpload.map((file) => {
          const fileData = new FormData();
          fileData.append('file', file, file.name);

          return taskService.addAttachment(createdTask._id, fileData);
        });

        await Promise.all(promises);
      } catch (error: any) {
        setUploadError(`Error: couldn't upload attachment due to: ${error.message}`);
      }
      setIsUploadingAttachment(false);
    },
  });

  const resetValues = () => {
    formik.resetForm();
    setAssignee(undefined);
    setDueDate(undefined);
    setLabelIds([]);
    setGeneralError(null);
    setFilesToUpload([]);
    setUploadError(null);
    setDescEditorState(new RichTextEditorContent());
  };

  const handleSubmit = async (isAddAnotherTask: boolean) => {
    try {
      await formik.submitForm();
      resetValues();
      if (!isAddAnotherTask) {
        onHide();
      }
    } catch (error: any) {
      setGeneralError(`Failed to create task due to ${error.message}`);
    }
  };

  const isProgramTask = taskType === constants.taskCreationTypes.PROGRAM_TASKS;

  const handleCreateLabel = async (name: string, color: string) => {
    try {
      const res = await taskService.createLabel(name, color, currentWorkspace._id);
      handleToggleLabel(res);
    } catch (err: any) {
      setGeneralError(err.message);
    }
  };

  const handleEditLabel = async (labelId: string, name: string, color: string) => {
    try {
      await taskService.editLabel(name, color, labelId);
    } catch (err: any) {
      setGeneralError(err.message);
    }
  };

  const handleDeleteLabel = async (labelId: string) => {
    try {
      await taskService.deleteLabel(labelId);
      setLabelIds((prevState) => prevState.filter((id) => id !== labelId));
    } catch (err: any) {
      setGeneralError(err.message);
    }
  };

  const handleAddFiles = async (files: File[]) => {
    setFilesToUpload((prevState) => [...prevState, files[0]]);
  };

  const handleRemoveFile = async (fileIndex: number) => {
    setFilesToUpload((prevState) => {
      const newState = cloneDeep(prevState);
      newState.splice(fileIndex, 1);
      return newState;
    });
  };

  const handleFileRejectionError = (fileRejectionError: FileRejectionError) => {
    setUploadError(`Failed to upload the attachment due to ${fileRejectionError.message}`);
  };

  const hasChanges = React.useMemo(() => {
    const isTitleUpdated = formik.values.title.length > 0;

    const isDescriptionUpdated = !!descEditorState.htmlContent;

    const isLabelsUpdated = labelIds.length > 0;
    const isAttachmentsUpdated = filesToUpload.length > 0;
    const isAssigneeUpdated = assignee !== undefined;

    const isDueDateUpdated = dueDate !== undefined;

    return (
      isTitleUpdated ||
      isDescriptionUpdated ||
      isLabelsUpdated ||
      isAttachmentsUpdated ||
      isAssigneeUpdated ||
      isDueDateUpdated
    );
  }, [assignee, dueDate, labelIds, filesToUpload, formik.values.title, descEditorState.htmlContent]);

  useBeforeunload((event) => {
    if (hasChanges) {
      event.preventDefault();
    }
  });

  const onCloseDrawer = async () => {
    if (!hasChanges || window.confirm(intl.formatMessage({ id: 'Changes you made may not be saved.' }))) {
      resetValues();
      onHide();
    }
  };

  const forceTaskTitleFocus = () => taskTitleRef.current?.focus();

  return (
    <Offcanvas
      show={show}
      onHide={onCloseDrawer}
      onEntered={forceTaskTitleFocus}
      placement="end"
      className="w-sm-75 w-100 overflow-auto"
    >
      <Container>
        <Row>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="w-100">
              <Form.Group controlId="nameInput">
                <Form.Control
                  ref={taskTitleRef}
                  type="text"
                  name="title"
                  className="form-control form-control-lg form-control-solid w-100"
                  placeholder={intl.formatMessage({
                    id: 'Title',
                  })}
                  autoFocus
                  tabIndex={1}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={formik.isSubmitting}
                  isInvalid={formik.touched.title && !!formik.errors.title}
                />
                <Form.Control.Feedback type="invalid" className="position-absolute">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Alert variant="danger" show={Boolean(generalError)} onClose={() => setGeneralError(null)} dismissible>
              {generalError}
            </Alert>
            <Row className="justify-content-between mb-3">
              <Col xs={6} md={3}>
                <div>
                  <Row>
                    <h3 className="fw-bold mt-1">{intl.formatMessage({ id: 'Created By' })}</h3>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <BloomAvatar name={user.firstname} size="xs" imagePath={user.images?.profile?.link} rounded />
                    </Col>
                    <Col xs={10} className="d-flex align-items-center">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 400, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            {user.firstname}
                          </Tooltip>
                        )}
                      >
                        <span className=" mx-2 text-truncate">{user.firstname}</span>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <Assignee
                  isProgramAssignee={isProgramTask}
                  programName={currentOrg?.name}
                  assignee={assignee}
                  members={currentWorkspace.users}
                  onChange={onHandleAssignedChange}
                  onAssignToMe={handleAssignToMe}
                  tabIndex={2}
                />
              </Col>
              <Col xs={6} md={3}>
                <div>
                  <h3 className="fw-bold">{intl.formatMessage({ id: 'Creation Date' })}</h3>
                  <BloomReadOnlyDatePicker date={new Date()} withoutIcon />
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div>
                  <h3 className="fw-bold">{intl.formatMessage({ id: 'Due Date' })}</h3>
                  <BloomDatePicker date={dueDate} onChange={handlePickerChange} isClearable tabIndex={3} />
                </div>
              </Col>
            </Row>
            <Row className="my-3">
              <h3 className="fw-bold my-0 me-4">{intl.formatMessage({ id: 'Labels:' })}</h3>
              <TaskLabels
                onCreateLabel={handleCreateLabel}
                onEditLabel={handleEditLabel}
                onDeleteLabel={handleDeleteLabel}
                allLabels={currentWorkspace.labels}
                labelsObject={labelsObject}
                taskLabels={labelIds}
                onToggleLabel={handleToggleLabel}
                disabled={formik.isSubmitting}
                tabIndex={4}
              />
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group controlId="descInput">
                  <EditableTaskDescription
                    onChange={setDescEditorState}
                    isLoading={formik.isSubmitting}
                    description={descEditorState.htmlContent}
                    users={currentWorkspace.users}
                    tabIndex={5}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <h3 className="my-3">{intl.formatMessage({ id: 'Attachments' })}</h3>
              <Alert variant="danger" show={Boolean(uploadError)} onClose={() => setUploadError(null)} dismissible>
                {uploadError}
              </Alert>
              <FilesDropzone
                maxFiles={1}
                multiple={false}
                onAddFiles={handleAddFiles}
                disabled={formik.isSubmitting || isUploadingAttachment}
                isUploading={isUploadingAttachment}
                onFileRejectionError={handleFileRejectionError}
                canAddTaskAttachments={canAddTaskAttachments}
                orgId={currentOrg._id}
              />
              <FilesList files={filesToUpload} onRemoveFile={handleRemoveFile} />
            </Row>
            <Row className="justify-content-center mt-auto">
              <Col xs="auto" className="mb-2">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={onCloseDrawer}
                  disabled={formik.isSubmitting}
                  tabIndex={8}
                >
                  {intl.formatMessage({ id: 'Discard' })}
                </Button>
              </Col>
              <Col xs="auto" className="mb-2">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => handleSubmit(true)}
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || descEditorState.isUploading}
                  tabIndex={7}
                >
                  {intl.formatMessage({ id: 'Save and Add New Task' })}
                </Button>
              </Col>
              <Col xs="auto" className="mb-2">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => handleSubmit(false)}
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || descEditorState.isUploading}
                  tabIndex={6}
                >
                  {intl.formatMessage({ id: 'Save and Close' })}
                </Button>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Row>
      </Container>
      <Prompt
        when={hasChanges}
        message={intl.formatMessage({
          id: 'Changes you made may not be saved.',
        })}
      />
    </Offcanvas>
  );
};

export default CreateTaskDrawer;
