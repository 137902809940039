import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { SelectOption } from '../../components/select/Select.types';

export type LibraryState = {
  pageSettings: {
    searchPhrase: string;
    selectedDD: SelectOption | null;
    selectedCategory: SelectOption | null;
    selectedLabels: SelectOption[];
  };
};

const initialState: LibraryState = {
  pageSettings: {
    searchPhrase: '',
    selectedDD: null,
    selectedCategory: null,
    selectedLabels: [],
  },
};

const slice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setSearchPhrase(state: LibraryState, action: PayloadAction<{ searchPhrase: string }>) {
      const { searchPhrase } = action.payload;

      const clonedState = cloneDeep(state);

      return {
        pageSettings: {
          ...clonedState.pageSettings,
          searchPhrase,
        },
      };
    },
    setSelectedDD(state: LibraryState, action: PayloadAction<{ selectedDD: SelectOption | null }>) {
      const { selectedDD } = action.payload;

      const clonedState = cloneDeep(state);

      return {
        pageSettings: {
          ...clonedState.pageSettings,
          selectedDD,
          selectedCategory: null,
        },
      };
    },
    setSelectedCategory(state: LibraryState, action: PayloadAction<{ selectedCategory: SelectOption | null }>) {
      const { selectedCategory } = action.payload;

      const clonedState = cloneDeep(state);

      return {
        pageSettings: {
          ...clonedState.pageSettings,
          selectedCategory,
        },
      };
    },
    setSelectedLabels(state: LibraryState, action: PayloadAction<{ selectedLabels: SelectOption[] }>) {
      const { selectedLabels } = action.payload;

      const clonedState = cloneDeep(state);

      return {
        pageSettings: {
          ...clonedState.pageSettings,
          selectedLabels,
        },
      };
    },
  },
});

export const { setSearchPhrase, setSelectedDD, setSelectedCategory, setSelectedLabels } = slice.actions;

export default persistReducer({ storage, key: 'library', whitelist: ['pageSettings'] }, slice.reducer);
