import { request } from '../../shared/http.service';
import constants from '../../shared/consts';
import { ValueOf } from '../../shared/types';
import { GetTaskResponseType, ListType, TaskboardType, TaskFieldsType, TaskType } from './Taskboard.types';
import { MinimalUserModel, UserModel } from '../../modules/auth/models/UserModel';

const taskboardService = {
  getTaskboardData: async (workspaceId: string) => {
    const res = await request({
      url: `/workspaces/${workspaceId}/taskboard`,
      method: 'get',
    });
    return res?.data?.data?.taskboard as TaskboardType;
  },

  fetchTasksByList: async (listId: string) => {
    const res = await request({
      url: `/v2/lists/${listId}/tasks`,
      method: 'get',
    });
    return res.data.data.listWithTasks as ListType<TaskType>;
  },

  updateTaskList: async (options: {
    taskId: string;
    destinationListId: string;
    indexInDestinationList: number;
    reviewerId?: string;
  }) => {
    const { taskId, destinationListId, indexInDestinationList, reviewerId } = options;
    const res = await request({
      url: `/v2/tasks/${taskId}/update-list`,
      data: {
        destinationListId,
        indexInDestinationList,
        reviewerId,
      },
      method: 'put',
    });
    return res.data.data.task as TaskType;
  },

  toggleArchiveTask: async (taskId: string) => {
    const res = await request({
      url: `/v2/tasks/${taskId}/toggle-archive`,
      method: 'put',
    });
    return res?.data?.data?.task as TaskType;
  },

  createProgramTasks: async ({ orgId, task }: { orgId: string; task: TaskType }) => {
    const res = await request({
      url: `/orgs/${orgId}/program-tasks`,
      data: { tasksData: [task] },
      method: 'post',
    });
    return res?.data?.data?.newTasks?.[0];
  },

  addTaskToList: async ({
    listId,
    task,
    mentionIds,
  }: {
    listId: string;
    task: Partial<TaskType>;
    mentionIds: Array<UserModel['_id']>;
  }) => {
    const res = await request({
      url: `/v2/lists/${listId}/tasks`,
      data: { tasksData: [task], mentionIds },
      method: 'post',
    });
    return res.data?.data?.newTasks?.[0] as TaskType;
  },

  createTask: ({
    listId,
    orgId,
    task,
    taskType,
    mentionIds,
  }: {
    listId?: string;
    orgId?: string;
    task: Partial<TaskFieldsType>;
    taskType: ValueOf<typeof constants.taskCreationTypes>;
    mentionIds: Array<UserModel['_id']>;
  }) => createTaskMethodsMap[taskType]({ listId, orgId, task, mentionIds }),

  getTask: async (taskId: string) => {
    const res = await request({ url: `/v2/tasks/${taskId}`, method: 'get' });
    return res.data?.data as GetTaskResponseType;
  },

  editTask: async (taskFields: Partial<TaskType>, mentionIds: Array<MinimalUserModel['_id']>) => {
    const res = await request({
      url: `/v2/tasks/${taskFields._id}`,
      data: { taskFields, mentionIds },
      method: 'put',
    });
    return res.data.data.task;
  },

  archiveTask: async (taskId: string) => {
    const res = await request({
      url: `/v2/tasks/${taskId}/toggle-archive`,
      method: 'put',
    });
    return res.data.data.task as TaskType;
  },
};

const createTaskMethodsMap: {
  [key: string]: Function;
} = {
  [constants.taskCreationTypes.TEAM_TASKS]: taskboardService.addTaskToList,
  [constants.taskCreationTypes.PROGRAM_TASKS]: taskboardService.createProgramTasks,
};

export default taskboardService;
