import React, { FC } from 'react';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/ja';
import '@formatjs/intl-relativetimeformat/locale-data/zh';
import enMessages from './messages/en.json';

const allMessages = {
  de: enMessages,
  en: enMessages,
  es: enMessages,
  fr: enMessages,
  ja: enMessages,
  zh: enMessages,
};

const BloomI18n: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const locale = 'en'; // TODO: make this dynamic using Redux or context api
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { BloomI18n };
