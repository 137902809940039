import React from 'react';
import { InputGroup } from 'react-bootstrap';

type Props = {
  withoutIcon?: boolean;
  isClearable?: boolean;
  value?: string;
};

const CalendarIcon: React.FC<Props> = ({ withoutIcon, isClearable, value }) => {
  if (withoutIcon) return null;
  if (isClearable && value) return null;
  return (
    <InputGroup.Text>
      <i className="bi bi-calendar2-check" />
    </InputGroup.Text>
  );
};

export default CalendarIcon;
