import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Overlay, Popover } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import KTSVG from '../../../../components/KTSVG/KTSVG';
import IconButton from '../../../../components/IconButton';
import OrgNotificationsList from '../../../../components/notifications/OrgNotificatoinsList';
import { RootState } from '../../../../../setup';
import { getNotificationsPathname } from '../../../../routing/router.service';

type Props = {};

const OrgNotificationsMenu: React.FC<Props> = () => {
  const intl = useIntl();

  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
  const bellRef = useRef(null);

  const workspaceUnreadNotificationCountsMap = useSelector<
    RootState,
    RootState['notifications']['workspaceUnreadNotificationCountsMap']
  >((state) => state.notifications.workspaceUnreadNotificationCountsMap);

  const currentOrg = useSelector<RootState, RootState['currentOrg']>((state) => state.currentOrg);

  const unreadNotificationsCount = useMemo(() => {
    let total = 0;
    let i = 0;
    while (total <= 100 && i < currentOrg.workspaces.length) {
      const workspace = currentOrg.workspaces[i];
      const workspaceId = typeof workspace === 'string' ? workspace : workspace._id;
      total += workspaceUnreadNotificationCountsMap[workspaceId];
      i++;
    }
    return total;
  }, [currentOrg.workspaces, workspaceUnreadNotificationCountsMap]);

  return (
    <>
      <button
        type="button"
        ref={bellRef}
        className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
        onClick={() => setShowNotificationsMenu((prevState) => !prevState)}
      >
        <span className="svg-icon svg-icon-primary svg-icon-2x">
          <KTSVG path="/media/icons/custom/notifications1.svg" className="svg-icon-1" />
        </span>
        {unreadNotificationsCount > 0 && (
          <div className="position-absolute top-0 start-75 translate-middle badge rounded-pill bg-danger">
            {unreadNotificationsCount < 100 ? unreadNotificationsCount : '+99'}
          </div>
        )}
      </button>
      <Overlay
        target={bellRef.current}
        placement="bottom"
        show={showNotificationsMenu}
        rootClose
        onHide={() => setShowNotificationsMenu(false)}
      >
        <Popover className="headerPopoverContainer">
          <Popover.Header as="div" className="d-flex justify-content-between">
            <div className="flex-grow-1">
              <div className="text-bloom-dark-blue fs-3">
                <FormattedMessage
                  id="NotificationMenuTitle"
                  values={{
                    b: (...chunks: any) => <b>{chunks}</b>,
                    orgName: currentOrg.name,
                  }}
                />
              </div>
            </div>
            <div className="col-2">
              <Link
                className="d-flex"
                to={getNotificationsPathname({ orgId: currentOrg._id, parentOrgId: currentOrg.parentOrgId })}
                onClick={() => setShowNotificationsMenu(false)}
              >
                <IconButton size="sm" variant="secondary" title={intl.formatMessage({ id: 'View all' })}>
                  {intl.formatMessage({ id: 'View all' })}
                </IconButton>
              </Link>
            </div>
          </Popover.Header>
          <Popover.Body className="bellNotificationsContainer p-0">
            <OrgNotificationsList
              inBell
              isOnlyUnread
              orgId={currentOrg._id}
              parentOrgId={currentOrg.parentOrgId}
              onChildNotificationClick={() => setShowNotificationsMenu(false)}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default OrgNotificationsMenu;
