import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { shallowEqual, useSelector } from 'react-redux';
import { getLocalStorageToken } from '../shared/localStorage.service';
import { getApolloClient } from '../../graphql/helper';
import constants from '../shared/consts';
import { RootState } from '../../setup';
import useNotificationsInitializer from '../modules/notifications/hooks/useNotificationsInitializer';
import Loading from '../components/Loading/Loading';

const UserProfile = React.lazy(() => import('../pages/userProfile/UserProfile'));
const HubPage = React.lazy(() => import('../pages/hub/HubPage'));
const TeamRoutes = React.lazy(() => import('./TeamRoutes'));
const LibraryPage = React.lazy(() => import('../pages/library/LibraryPage'));
const LibraryItemPage = React.lazy(() => import('../pages/library/LibraryItemPage'));
const LibraryItemEditPage = React.lazy(() => import('../pages/library/LibraryItemEditPage'));
const LibraryItemCreatePage = React.lazy(() => import('../pages/library/LibraryItemCreatePage'));
const PageNotFound = React.lazy(() => import('../pages/PageNotFound/PageNotFound'));

export default () => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<unknown> | undefined>(undefined);
  const user = useSelector<RootState, RootState['user']>((state) => state.user, shallowEqual);

  useNotificationsInitializer(user._id);

  useEffect(() => {
    (async () => {
      if (user && !apolloClient) {
        const client = await getApolloClient(user._id, getLocalStorageToken() as string);
        setApolloClient(client);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => (
      <Suspense fallback={<Loading />}>
        {apolloClient && (
          <ApolloProvider client={apolloClient}>
            <Switch>
              {!user.isClosed
                ? [
                    <Route path={constants.routs.root} key={constants.routs.root} exact>
                      <Redirect to={constants.routs.hub} />
                    </Route>,
                    <Route path={constants.routs.hub} key={constants.routs.hub} exact>
                      <HubPage />
                    </Route>,
                    <Route path={constants.routs.library} key={constants.routs.library} exact>
                      <LibraryPage />
                    </Route>,
                    ...(user.role?.permissions.activity.add
                      ? [
                          <Route path={constants.routs.libraryItemCreate} key={constants.routs.libraryItemCreate} exact>
                            <LibraryItemCreatePage />
                          </Route>,
                        ]
                      : []),
                    ...(user.role?.permissions.activity.editAll || user.role?.permissions.activity.editOwn
                      ? [
                          <Route path={constants.routs.libraryItemEdit} key={constants.routs.libraryItemEdit} exact>
                            <LibraryItemEditPage />
                          </Route>,
                        ]
                      : []),
                    <Route path={constants.routs.libraryItem} key={constants.routs.libraryItem} exact>
                      <LibraryItemPage />
                    </Route>,
                    <Route
                      path={constants.routs.userProfile}
                      key={constants.routs.userProfile}
                      exact
                      render={(props) => <UserProfile {...props} />}
                    />,
                    <Route path={constants.routs.teamsRoot} key={constants.routs.teamsRoot} component={TeamRoutes} />,
                  ]
                : [
                    <Route path={constants.routs.root} key={constants.routs.root} exact>
                      <Redirect to={`/users/${user._id}`} />
                    </Route>,
                    <Route path={constants.routs.hub} key={constants.routs.hub} exact>
                      <Redirect to={`/users/${user._id}`} />
                    </Route>,
                    <Route path={constants.routs.teamsRoot} key={constants.routs.teamsRoot}>
                      <Redirect to={`/users/${user._id}`} />
                    </Route>,
                    <Route
                      path={constants.routs.userProfile}
                      key={constants.routs.userProfile}
                      exact
                      render={(props) => <UserProfile {...props} />}
                    />,
                  ]}

              <Route>
                <PageNotFound />
              </Route>
            </Switch>
          </ApolloProvider>
        )}
        {!apolloClient && <div>Loading...</div>}
      </Suspense>
    ),
    [
      apolloClient,
      user._id,
      user.isClosed,
      user.role?.permissions.activity.add,
      user.role?.permissions.activity.editAll,
      user.role?.permissions.activity.editOwn,
    ]
  );
};
