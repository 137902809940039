import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dropdown } from 'react-bootstrap';
import DeleteApprovalModal from '../deleteApproveModal/DeleteApproveModal';
import orgService from '../../modules/org/OrgService';
import { OrgModel } from '../../modules/org/org.types';

type Props = {
  orgId: string;
  orgName: string;
  orgType: OrgModel['type'];
  redirectTo?: string;
  renderAs?: 'button' | 'menu item';
};

const DeleteOrg: React.FC<Props> = ({ orgId, orgName, orgType, redirectTo, renderAs = 'button' }) => {
  const intl = useIntl();

  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generalError, setGeneralError] = useState('');

  const handleCloseModal = () => {
    setGeneralError('');
    setShow(false);
  };
  const handleShowModal = () => setShow(true);
  const handleDeleteApproval = async () => {
    try {
      setIsSubmitting(true);
      await orgService.deleteOrg(orgId);
      handleCloseModal();
      if (redirectTo) {
        window.location.assign(redirectTo);
      } else {
        window.location.reload();
      }
    } catch (error: any) {
      setGeneralError(`Couldn't delete the org due to ${error.message}`);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {renderAs === 'menu item' ? (
        <Dropdown.Item onClick={handleShowModal}>
          <p className="text-danger m-0">{intl.formatMessage({ id: 'Delete Org' }, { orgType })}</p>
        </Dropdown.Item>
      ) : (
        <Button onClick={handleShowModal} variant="danger" size="sm" className="me-2">
          {intl.formatMessage({ id: 'Delete Org' }, { orgType })}
        </Button>
      )}

      {show && (
        <DeleteApprovalModal
          show={show}
          title={intl.formatMessage({ id: 'ARE_YOU_SURE_DELETE_ORG' }, { orgType })}
          onDeleteApproval={handleDeleteApproval}
          itemToDeleteName={orgName}
          itemToDeleteType={orgType}
          generalError={generalError}
          onHide={handleCloseModal}
          onCancel={handleCloseModal}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default DeleteOrg;
