import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import KTSVG from '../../../components/KTSVG/KTSVG';
import useLayoutDispatcher from '../../hooks/useLayoutDispatcher';
import { RootState } from '../../../../setup';
import './styles.scss';

type Props = {
  href: string;
  title: string;
  icon?: string;
};

const AsideMenuLink: React.FC<Props> = ({ href, title, icon }) => {
  const { sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const { toggleSidebarState } = useLayoutDispatcher();

  return (
    <div
      className={`d-flex align-items-center ms-3 mt-2 py-3 rounded-2 bg-white ${
        sidebar.isSidebarOpen ? 'w-240px' : 'w-65px'
      }`}
    >
      <a
        className="d-flex align-items-center ps-3 pe-2 flex-grow-1"
        href={href}
        onClick={() => {
          if (sidebar.isDrawer) toggleSidebarState();
        }}
        target="_blank"
        rel="noreferrer"
      >
        {icon && <KTSVG path={icon} className="svg-icon svg-icon-1 svg-icon-bloom-dark-blue ms-3 me-0" />}
        <p
          className={`fs-5 fw-bolder mb-0 text-truncate text-start smooth-hide ${
            sidebar.isSidebarOpen ? 'opacity-1 ms-4' : 'opacity-0 ms-0'
          } text-bloom-dark-blue`}
          style={{ width: 147 }}
        >
          {title}
        </p>
      </a>
    </div>
  );
};

export default AsideMenuLink;
