import React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useIntl } from 'react-intl';
import Loading from '../Loading/Loading';
import { FileRejectionError } from './FilesDropzone.types';
import UpgradeButton from '../OrgBilling/UpgradeButton';

const FIVE_MB_IN_BYTES = 5242880;

type Props = {
  maxFiles: number;
  onAddFiles: (_files: File[]) => void;
  onFileRejectionError: (_fileRejectionError: FileRejectionError) => void;
  multiple?: boolean;
  disabled?: boolean;
  isUploading?: boolean;
  canAddTaskAttachments?: boolean;
  orgId?: string;
};

const FilesDropzone: React.FC<Props> = ({
  maxFiles,
  onAddFiles,
  multiple,
  disabled,
  isUploading,
  canAddTaskAttachments = false,
  orgId,
  onFileRejectionError,
}) => {
  const intl = useIntl();

  const handleDropFile = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) onAddFiles(acceptedFiles);
  };

  const handleDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) onFileRejectionError(fileRejections[0].errors[0]);
  };

  let intlId = 'Drag and drop files here to add them as attachments, or click to select files';

  if (!canAddTaskAttachments) {
    intlId = 'Power your team with new features.';
  } else if (isUploading) {
    intlId = 'Uploading your file, Please wait ...';
  }

  return (
    <Dropzone
      maxFiles={maxFiles}
      multiple={multiple}
      maxSize={FIVE_MB_IN_BYTES}
      disabled={disabled || isUploading || !canAddTaskAttachments}
      onDrop={handleDropFile}
      onDropRejected={handleDropRejected}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps({
              className: `dropzone ${disabled ? 'bg-light border-light' : ''}`,
            })}
          >
            <input {...getInputProps()} />
            <div className="d-flex align-items-center justify-content-center gap-2">
              <p className="m-0">
                {intl.formatMessage({
                  id: intlId,
                })}
              </p>
              {canAddTaskAttachments && isUploading && <Loading />}
              {!canAddTaskAttachments && orgId && <UpgradeButton orgId={orgId} />}
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FilesDropzone;
