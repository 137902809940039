import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from '../../../../setup';
import orgService from '../../../modules/org/OrgService';
import constants from '../../../shared/consts';
import useLayoutDispatcher from '../../hooks/useLayoutDispatcher';
import Logo from '../Logo';
import SidebarToggleButton from '../SidebarToggleButton';
import { OrgModel, WorkspaceType } from '../../../modules/org/org.types';
import AsideMenuItem from './AsideMenuItem';
import AsideMenuItemWithSub from './AsideMenuItemWithSub';
import { setCurrentOrg } from '../../../modules/org/currentOrg.slice';
import TeamSelect from '../TeamSelect';
import { useParamsFromPath } from '../../../shared/hooks';
import WorkspaceMenuItem from './WorkspaceMenuItem';
import ProgramMenuItem from './ProgramMenuItem';
import AsideMenuLink from './AsideMenuLink';
import UpgradeButton from '../../../components/OrgBilling/UpgradeButton';
import TeamsSettingsDropdownMenu from './TeamsSettings';
import useOrgPermissions from '../../../components/OrgBilling/useOrgPermissions';

export type TeamOption = {
  value: OrgModel['_id'];
  label: OrgModel['name'];
  imageLink?: string;
};

const Sidebar: FC = () => {
  const [selectedTeam, setSelectedTeam] = useState<RootState['user']['orgs'][0]>();

  const intl = useIntl();
  const dispatch = useDispatch();

  const history = useHistory();
  const { pathname } = useLocation();

  const { workspacePage, orgId, workspaceId } = useParamsFromPath<{
    workspacePage?: string;
    orgId?: string;
    workspaceId?: string;
  }>([constants.routs.workspaceRoot, constants.routs.teamsRoot]);

  const { programId } = useParamsFromPath<{
    programId?: string;
  }>([constants.routs.programRoot]);

  const { toggleSidebarState } = useLayoutDispatcher();

  const { sidebar } = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  const currentOrg = useSelector<RootState, RootState['currentOrg']>((state) => state.currentOrg, shallowEqual);

  const { workspaceUnreadNotificationCountsMap } = useSelector<RootState, RootState['notifications']>(
    ({ notifications }) => notifications
  );

  const getOrgNotificationsBadgeNumber = (workspaceIds: string[]) => {
    const totalNotifications = workspaceIds.reduce((acc, cur) => acc + workspaceUnreadNotificationCountsMap[cur], 0);
    if (totalNotifications > 99) {
      return '+99';
    }
    return totalNotifications;
  };

  const { canCreateWorkspaces, canCreatePrograms } = useOrgPermissions(currentOrg.subscription);
  const userOrgs = useSelector<RootState, RootState['user']['orgs']>(
    ({ user }) => orgService.filterOutChildOrgs(user.orgs),
    shallowEqual
  );

  const findProgram = (programId: OrgModel['_id'], orgs: OrgModel<WorkspaceType | string>[]) => {
    for (const org of orgs) {
      if (org._id === programId) return org;
      const program = org.childOrgs.find((o) => o._id === programId);
      if (program) return program;
    }
  };

  useEffect(() => {
    const team = userOrgs.find((o) => o._id === orgId);
    const program = programId && findProgram(programId, userOrgs);
    setSelectedTeam(team);
    if (program) {
      dispatch(setCurrentOrg({ currentOrg: { ...program, parentOrgId: team?._id } }));
    } else if (team) {
      dispatch(setCurrentOrg({ currentOrg: team }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, userOrgs, programId]);

  const privateWorkspaces = useMemo(
    () => selectedTeam?.workspaces?.filter((ws) => ws.sharedWithOrgs.length === 0),
    [selectedTeam?.workspaces]
  );
  const sharedWorkspaces = useMemo(
    () => selectedTeam?.workspaces?.filter((ws) => ws.sharedWithOrgs.length > 0),
    [selectedTeam?.workspaces]
  );

  const privateWorkspacesTotalNotifications = useMemo(
    () => (privateWorkspaces ? getOrgNotificationsBadgeNumber(privateWorkspaces.map((w) => w._id)) : ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspaceUnreadNotificationCountsMap, privateWorkspaces]
  );
  const sharedWorkspacesTotalNotifications = useMemo(
    () => (sharedWorkspaces ? getOrgNotificationsBadgeNumber(sharedWorkspaces.map((w) => w._id)) : ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspaceUnreadNotificationCountsMap, sharedWorkspaces]
  );
  const programsTotalNotifications = useMemo(
    () =>
      sharedWorkspaces
        ? getOrgNotificationsBadgeNumber(
            userOrgs
              .find((o) => o._id === orgId)
              ?.childOrgs.map((co) => co.workspaces)
              .flat() || []
          )
        : '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sharedWorkspaces, userOrgs, orgId, workspaceUnreadNotificationCountsMap]
  );

  const handleTeamSelect = (org: RootState['user']['orgs'][0]) => {
    setSelectedTeam(org);
    dispatch(setCurrentOrg({ currentOrg: org }));
    if (org.isNoAccessTeam) {
      history.push(`/teams/${org._id}/programs/${org.childOrgs[0]._id}/${constants.workspacePages.programActivities}`);
    } else {
      const firstFoundWorkspaceId = org.workspaces[0]._id;
      history.push(`/teams/${org._id}/workspaces/${firstFoundWorkspaceId}/taskboard`);
    }
  };

  const isOpenCommunityActive = pathname.endsWith('/community');
  const isSettingsOpen = pathname === `/teams/${selectedTeam?._id}/settings`;

  const shouldAskForPlanUpgrade =
    currentOrg.type === 'TEAM' &&
    (!currentOrg.subscription || !currentOrg.subscription.stripeSubscription || currentOrg.subscription.plan.isDefault);

  return (
    <>
      <div className={`${sidebar.isDrawer ? 'side-drawer' : 'sidebar'} d-flex flex-column`}>
        <div className="sidebar-header">
          <Logo
            onClick={() => {
              if (sidebar.isDrawer) toggleSidebarState();
            }}
            isSmall={!sidebar.isDrawer && !sidebar.isSidebarOpen}
          />

          <SidebarToggleButton isMenuOpen={sidebar.isSidebarOpen} className="sidebar-toggle-button" />
        </div>

        <div className="px-1">
          <TeamSelect orgs={userOrgs} selectedOrg={selectedTeam} onSelect={handleTeamSelect} />
        </div>

        <div className="sidebar-content px-1 my-1">
          {selectedTeam && (
            <>
              {selectedTeam.isNoAccessTeam ? (
                sidebar.isSidebarOpen && (
                  <div className="ms-3 mt-2 p-3 bg-light rounded w-250px">
                    <p className="fs-5 mb-0 smooth-hide text-muted">
                      *
                      {intl.formatMessage({
                        id: 'You are a Program Manager in this team, contact the Team owner to get access to the whole team',
                      })}
                    </p>
                  </div>
                )
              ) : (
                <AsideMenuItem
                  to={`/teams/${selectedTeam._id}/team-activities`}
                  title={intl.formatMessage({ id: 'Team Activities' })}
                  icon="/media/icons/custom/listCheckIcon.svg"
                />
              )}

              {privateWorkspaces && privateWorkspaces.length > 0 && (
                <AsideMenuItemWithSub
                  to={selectedTeam.name}
                  isActive={privateWorkspaces.some((ws) => ws._id === workspaceId)}
                  isOpen
                  title={intl.formatMessage({ id: 'My Workspaces' })}
                  icon="/media/icons/custom/WIcon.svg"
                  badge={privateWorkspacesTotalNotifications}
                >
                  {privateWorkspaces.map((workspace) => {
                    let page: string = constants.workspacePages.taskboard;

                    if (
                      workspacePage &&
                      workspacePage in constants.workspacePages &&
                      workspacePage !== constants.workspacePages.community &&
                      workspacePage !== constants.workspacePages.settings
                    ) {
                      page = workspacePage;
                    }
                    return (
                      <WorkspaceMenuItem
                        key={workspace._id}
                        teamId={selectedTeam._id}
                        workspace={workspace}
                        to={`/teams/${selectedTeam._id}/workspaces/${workspace._id}/${page}`}
                        isActive={workspaceId === workspace._id}
                        isSubItem
                      />
                    );
                  })}
                </AsideMenuItemWithSub>
              )}

              {sharedWorkspaces && sharedWorkspaces.length > 0 && (
                <AsideMenuItemWithSub
                  to={selectedTeam.name}
                  isActive={sharedWorkspaces?.some((ws) => ws._id === workspaceId)}
                  isOpen
                  title={intl.formatMessage({ id: 'My Programs' })}
                  icon="/media/icons/custom/PIcon.svg"
                  badge={sharedWorkspacesTotalNotifications}
                >
                  {sharedWorkspaces.map((workspace) => {
                    let page: string = constants.workspacePages.taskboard;

                    if (
                      workspacePage &&
                      workspacePage in constants.workspacePages &&
                      workspacePage !== constants.workspacePages.community
                    ) {
                      page = workspacePage;
                    }
                    return (
                      <WorkspaceMenuItem
                        key={workspace._id}
                        teamId={selectedTeam._id}
                        workspace={workspace}
                        to={`/teams/${selectedTeam._id}/workspaces/${workspace._id}/${page}`}
                        isActive={workspaceId === workspace._id}
                        isSubItem
                      />
                    );
                  })}
                </AsideMenuItemWithSub>
              )}

              {selectedTeam.childOrgs && selectedTeam.childOrgs.length > 0 && (
                <AsideMenuItemWithSub
                  to={selectedTeam.name}
                  isActive={selectedTeam.childOrgs?.some((o) => o._id === programId)}
                  title={intl.formatMessage({ id: 'Manage Programs' })}
                  icon="/media/icons/custom/LaptopIcon.svg"
                  badge={programsTotalNotifications}
                >
                  {selectedTeam.childOrgs.map((program) => (
                    <ProgramMenuItem
                      key={program._id}
                      parentTeamId={selectedTeam._id}
                      program={program}
                      to={`/teams/${selectedTeam._id}/programs/${program._id}/${constants.workspacePages.peopleAndTeams}`}
                      isActive={programId === program._id}
                      isSubItem
                    />
                  ))}
                </AsideMenuItemWithSub>
              )}
            </>
          )}
        </div>
        <hr className="mx-4 text-muted mb-2 mt-0" />
        <div className="sidebar-footer px-1">
          {orgId && shouldAskForPlanUpgrade && (
            <div className="d-flex align-items-center justify-content-center py-2">
              <UpgradeButton
                orgId={orgId}
                title={intl.formatMessage({
                  id: 'Upgrade your team',
                })}
                hideTitle={!sidebar.isSidebarOpen}
              />
            </div>
          )}
          <AsideMenuItem
            to={`/${constants.navTypes.COMMUNITY}#/${constants.OPEN_COMMUNITY_PATH}`}
            // to={`/teams/${selectedOrg?._id}/${constants.navTypes.COMMUNITY}#/${constants.OPEN_COMMUNITY_PATH}`}
            title={intl.formatMessage({ id: 'Open community' })}
            isActive={isOpenCommunityActive}
            icon="/media/icons/custom/global.svg"
          />
          {pathname !== '/hub' && (
            <AsideMenuItem to="/hub" title="Back to Hub" isActive={false} icon="/media/icons/custom/hub.svg" />
          )}
          {!selectedTeam?.isNoAccessTeam && (
            <>
              <hr className="text-muted mb-2 mx-4" />
              <div className="d-flex ">
                <AsideMenuItem
                  to={`/teams/${selectedTeam?._id}/settings`}
                  title={intl.formatMessage({ id: 'Team Settings' })}
                  isActive={isSettingsOpen}
                  icon="/media/icons/custom/settings.svg"
                />
                <TeamsSettingsDropdownMenu
                  orgId={currentOrg._id}
                  orgName={currentOrg.name}
                  isOrgOwner
                  canCreatePrograms={canCreatePrograms}
                  canCreateWorkspaces={canCreateWorkspaces}
                  className="mt-4 bg-transparent"
                />
              </div>
            </>
          )}
          <AsideMenuLink
            href={constants.HELP_LINK}
            title={intl.formatMessage({ id: 'Help' })}
            icon="/media/icons/custom/help.svg"
          />
        </div>
      </div>
      {sidebar.isDrawer && sidebar.isSidebarOpen && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="side-drawer-backdrop" onClick={toggleSidebarState} />
      )}
    </>
  );
};

export default Sidebar;
