import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles.scss';
// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { AxiosError } from 'axios';
import initializeLogging from './pw/initializers/logging';

import store, { persistor } from './setup/redux/Store';

// Apps
import App from './app/App';
import { BloomI18n } from './app/i18n/BloomI18n';
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 * */
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';

import 'react-loading-skeleton/dist/skeleton.css';
import 'react-image-crop/dist/ReactCrop.css';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

initializeLogging();

/**
 * http.service.ts module raises HttpError event on axios error
 * We are listening to this event to detect 401 and logout the user
 */
const UNAUTHED_API_ROUTES = ['/api/v2/users/login', '/api/v2/users/me'];
window.addEventListener('HttpError', (ev: any) => {
  const event = ev as CustomEvent & { detail: { error: AxiosError } };
  if (event?.detail?.error?.response?.status === 401) {
    const requestUrl = new URL(event.detail.error.request.responseURL);
    if (UNAUTHED_API_ROUTES.every((path) => requestUrl.pathname.indexOf(path) === -1)) {
      (window as any).location = '/logout';
    }
  }
});

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <BloomI18n>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <App basename={PUBLIC_URL} />
        </PersistGate>
      </Provider>
    </BloomI18n>
  );
}
