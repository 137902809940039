import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import BloomReactCropModal from './BloomReactCropModal';

type IProps = {
  onSubmitImg: (_a: string) => void;
  defaultImgPath: string;
  disabled: boolean;
  alt: string;
};

const BloomImgInput: React.FC<IProps> = ({ onSubmitImg, defaultImgPath, disabled, alt }) => {
  const intl = useIntl();

  const [preview, setPreview] = useState('');
  const [originalImageData, setOriginalImageData] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showCropModal, setShowCropModal] = useState(false);

  useEffect(() => {
    if (originalImageData) {
      setShowCropModal(true);
    } else {
      window.URL.revokeObjectURL(preview);
      inputRef!.current!.value = '';
      setPreview(defaultImgPath);
      onSubmitImg('');
    }

    return () => {
      window.URL.revokeObjectURL(preview);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalImageData]);

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    inputRef.current = e.target;
    if (!!e.target.files && e?.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => setOriginalImageData(reader?.result as string));

      return reader.readAsDataURL(file);
    }
    return setOriginalImageData('');
  };

  const handleCancelCropModal = () => {
    setOriginalImageData('');
    setShowCropModal(false);
  };

  const handleSubmitCrop = (imgObj: any) => {
    setPreview(imgObj?.previewURL);
    onSubmitImg(imgObj?.dataURL);
    setShowCropModal(false);
  };

  const handleClick = () => inputRef?.current?.click();

  return (
    <div>
      {preview && <img src={preview} alt={alt} className="d-block mw-sm-100 m-sm-auto m-lg-0" />}
      <input type="file" accept="image/*" onChange={onSelectFile} ref={inputRef} className="d-none" />
      <Button
        onClick={handleClick}
        className="form-control form-control-lg form-control-solid mb-3 mb-lg-2 mw-sm-100 mw-lg-150px mt-3"
        disabled={disabled}
      >
        {intl.formatMessage({ id: 'UPLOAD_PHOTO' })}
      </Button>
      <BloomReactCropModal
        show={showCropModal}
        src={originalImageData}
        title={intl.formatMessage({ id: 'CROP_MODAL.TITLE' })}
        submitText={intl.formatMessage({ id: 'CROP_MODAL.SAVE' })}
        discardText={intl.formatMessage({ id: 'CROP_MODAL.DISCARD' })}
        onCancel={handleCancelCropModal}
        onSubmit={handleSubmitCrop}
      />
    </div>
  );
};

export default BloomImgInput;
