import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string);
}

export default function initializeLogging() {
  if (process.env.ENABLE_SENTRY) {
    Sentry.init({
      dsn: 'https://3c4b6ee2a98a40819455b0499d8b18ae@o465615.ingest.sentry.io/5512680',
      environment: 'prod', // Value to be replace during deployment pipeline
      release: '', // Value to be replace during deployment pipeline
      beforeSend: (event) => {
        if (process.env.NODE_ENV !== 'development') {
          return event;
        }
        console.error(event); // eslint-disable-line no-console
        return null; // this drops the event and nothing will be send to sentry
      },
    });
  }
}
