import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { shallowEqual, useSelector } from 'react-redux';
import BloomAvatarGroup from '../../../../components/bloomAvatar/BloomAvatarGroup';
import { RootState } from '../../../../../setup';

const PageTitle: React.FC = () => {
  const layout = useSelector<RootState, RootState['layout']>((state) => state.layout, shallowEqual);

  if (layout.header.pageTitle.isLoading) {
    return (
      <div className="d-flex">
        <Skeleton count={1} height={40} width={40} className="m-2" />
        <Skeleton count={1} height={40} width={200} className="m-2" />
      </div>
    );
  }

  return (
    <div className="page-title d-flex align-items-center flex-wrap me-3">
      {layout.header.pageTitle.avatars && layout.header.pageTitle.avatars.length > 0 && (
        <div className="me-4 ms-3">
          <BloomAvatarGroup avatarList={layout.header.pageTitle.avatars} />
        </div>
      )}
      {layout.header.pageTitle.title && (
        <h1 className="d-flex align-items-center text-bloom-dark-blue fw-bolder ms-3 my-1 fs-3 col">
          {layout.header.pageTitle.title}
        </h1>
      )}
    </div>
  );
};

export default PageTitle;
