import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props extends ButtonProps {
  icon?: IconProp;
  iconClassName?: string;
  iconSize?: SizeProp;
  iconPosition?: 'left' | 'right';
  title: string;
}
const IconButton: React.FC<Props> = ({
  icon,
  iconClassName,
  iconSize = 'lg',
  iconPosition = 'left',
  className,
  title,
  children,
  ...btnProps
}) => {
  const iconMargin = iconPosition === 'right' ? 'ms-2' : 'me-2';

  const childrenArray = React.Children.toArray(children);
  const hasChildren = childrenArray.length > 0 && childrenArray.every((child) => Boolean(child));
  return (
    <OverlayTrigger delay={{ show: 400, hide: 400 }} overlay={(props) => <Tooltip {...props}>{title}</Tooltip>}>
      <Button aria-label={title} className={`icon-btn-style p-2 mx-1 ${className || ''}`} {...btnProps}>
        {iconPosition === 'right' && children}
        {icon && (
          <FontAwesomeIcon
            size={iconSize}
            icon={icon}
            className={`${hasChildren && iconMargin} ${iconClassName || ''}`}
          />
        )}
        {iconPosition === 'left' && children}
      </Button>
    </OverlayTrigger>
  );
};

export default IconButton;
