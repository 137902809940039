import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import KTSVG from '../KTSVG/KTSVG';

type Props = {
  svgIconPath: string;
  title: string;
  onClick: (_e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  svgClassName?: string;
  containerClassName?: string;
};

const SvgIconButton: React.FC<Props> = ({
  onClick,
  svgIconPath,
  title,
  disabled,
  containerClassName = '',
  svgClassName = '',
}) => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 200, hide: 200 }}
    overlay={(props) => (
      <Tooltip id="button-tooltip" {...props}>
        {title}
      </Tooltip>
    )}
  >
    <button
      type="button"
      onClick={onClick}
      className={`${containerClassName} bg-light border-0 p-0 m-0`}
      disabled={disabled}
    >
      <KTSVG path={svgIconPath} className={`svg-icon m-0 svg-icon-black ${svgClassName}`} />
    </button>
  </OverlayTrigger>
);

export default SvgIconButton;
